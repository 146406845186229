<template>
  <Modal
    sheetbelowsm
    :title="title"
    :primary-button="$t('save')"
    :primary-button-disabled="!isIntentNameValid || !isIntentFieldsValid"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    close-button
    @primary="onSaveClicked"
    @close="onCancelClicked"
    @secondary="onCancelClicked"
  >
    <div class="sm:w-700">
      <div class="flex">
        <div class="max-md:w-full w-1/2 flex flex-col justify-start mt-5 pl-10 sm:pr-2">
          <label class="block text-13 mb-1">{{ $t('data_types.name') }}</label>
          <Input v-model="name" class="w-full" :readonly="mode === 'Edit'" />
          <p v-if="nameError.length" class="text-red-600 text-14 text-left pt-2 pl-5 italic">{{ nameError }}</p>
        </div>
        <div class="max-md:w-full w-1/2 flex flex-col justify-start mt-5 pr-10 sm:pl-2">
          <label class="block text-13 mb-1">{{ $t('data_types.mapping') }}</label>
          <Input v-model="mapping" class="w-full" />
        </div>
      </div>
      <div class="w-full flex flex-col justify-start mt-5 px-10">
        <label class="block text-13 mb-1">{{ $t('data_types.color') }}</label>
        <div class="flex">
          <div
            v-for="availableColor in availableColors"
            :key="availableColor"
            :style="{ backgroundColor: availableColor }"
            class="w-6 h-6 rounded-full mr-2 flex items-center justify-center cursor-pointer"
            @click="color = availableColor"
          >
            <Icon v-if="color === availableColor" name="check" class="w-4 h-4 text-white" />
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col justify-start mt-5 px-10">
        <div class="flex w-full">
          <div class="max-md:w-full flex-grow sm:mr-2 mb-1">{{ $t('data_types.field') }}</div>
          <div class="sm:mr-2 mb-1 max-md:flex-grow max-md:w-full sm:w-[120px] sm:flex-shrink-0">{{ $t('data_type') }}</div>
          <div class="flex-grow sm:ml-2 mb-1">{{ $t('data_types.mapping') }}</div>
          <div class="ml-2 w-4 h-4"></div>
        </div>

        <div v-for="(field, index) in fields" :key="index" class="flex items-center my-1">
          <div class="flex items-center w-full flex-wrap sm:flex-nowrap mb-1 sm:mb-0 -mt-1">
            <Input v-model="field.key" class="max-md:w-full flex-grow sm:mr-2 mb-1" :placeholder="$t('data_types.field')" @update:modelValue="onFieldInput" />
            <FormSelect
              v-model="field.dataType"
              append-to-body
              :options="dataTypes"
              :reduce="(opt) => opt.value"
              class="sm:mr-2 mb-1 max-md:flex-grow max-md:w-full sm:w-[120px] sm:flex-shrink-0"
              :placeholder="$t('data_type')"
              @update:modelValue="onFieldInput"
            />
            <Input v-model="field.value" class="flex-grow sm:ml-2 mb-1" :placeholder="$t('data_types.field')" @update:modelValue="onFieldInput" />
          </div>
          <Icon :class="{ invisible: index + 1 === fields.length && field.key === '' }" name="clear" class="ml-2 w-4 h-4 cursor-pointer" @click="removeField(index)" />
        </div>

        <p v-if="fieldsError.length" class="text-red-600 text-13 text-left pt-2 pl-0">{{ fieldsError }}</p>
      </div>
      <div v-if="duplicates.length" class="px-10 py-3 text-red-500">
        <div class="font-700 text-13">{{ $t('validation.duplicated_items') }}:</div>
        <ul class="text-13 list-disc pl-4">
          <li v-for="(duplicate, index) in duplicates" :key="index + duplicate">{{ duplicate.value }} ({{ duplicate.key }})</li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { apiPostDataType, apiPutDataType } from '@/helpers/api';
import FormSelect from '@/components/ui/FormSelect.vue';

export default {
  name: 'AddEditEntityModal',
  components: { FormSelect },
  props: {
    intent: String,
    intentDetails: Object,
  },
  emits: ['close'],
  data() {
    return {
      mode: 'Add',
      originalName: '',
      initialColor: '',
      color: '',
      name: '',
      nameError: '',
      mapping: '',
      fields: [],
      duplicates: [],
      loading: false,
      colors: ['#008FFB', '#A5978B', '#ff657c', '#D7263D', '#4ECDC4', '#449DD1', '#238180', '#F86624', '#FF9800', '#3F51B5', '#6000b5', '#b200ea', '#4CAF50'],
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId']),
    ...mapGetters(['getActiveDatasourceDataTypes']),
    dataTypes() {
      return [
        { label: 'Date', value: 'Date' },
        { label: 'Number', value: 'Number' },
        { label: 'Text', value: 'Text' },
        { label: 'YesNo', value: 'YesNo' },
      ];
    },
    availableColors() {
      return this.colors.filter((color) => !Object.values(this.getActiveDatasourceDataTypes).some((entity) => entity.colour === color) || color === this.initialColor);
    },
    title() {
      return this.mode === 'Add' ? this.$t('data_types.add') : this.$t('data_types.edit');
    },
    fieldsError() {
      if (!this.isFieldKeysUnique) {
        return 'Every field key should be unique!';
      }
      return false;
    },
    isFieldKeysUnique() {
      // Check keys
      const keySet = new Set(this.fields.map((field) => field.key.toLowerCase()));
      if (this.fields.length === keySet.size) {
        return true;
      }
      return false;
    },
    isIntentFieldsValid() {
      if (this.fields.length > 0) {
        if (!this.isFieldKeysUnique) {
          return false;
        }
      }
      return true;
    },
    isIntentNameValid() {
      if (this.name === '') return false;
      if (this.mode === 'Edit' && this.name !== this.originalName) {
        if (this.intentDetails[this.name]) {
          return false;
        }
      }
      if (this.mode === 'Add') {
        if (this.intentDetails[this.name]) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchDataType', 'fetchDesignTimeData']),
    addField() {
      this.fields.push({ key: '', value: '', dataType: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    fieldsToArray() {
      const fields = {};
      this.fields.forEach((field) => {
        if (field.key.trim()) {
          fields[field.key] = {
            data_type: field.dataType,
            mapping: field.value?.trim() || '',
          };
        }
      });
      return fields;
    },
    onCancelClicked() {
      this.$emit('close');
      // this.$modal?.close();
    },
    async onSaveClicked() {
      if (this.isIntentNameValid && this.isIntentFieldsValid) {
        this.loading = true;
        if (this.mode === 'Add') {
          const response = await apiPostDataType({
            name: this.name,
            mapping: this.mapping,
            fields: this.fieldsToArray(),
            type: this.designTimeActiveDatasourceType,
            colour: this.color,
            model_id: this.designTimeActiveDatasourceModelId,
          });
          this.loading = false;
          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('data_types.added_successfully'), type: 'success' });
            this.$emit('saveintent', response.data);
            this.fetchDesignTimeData();
            this.$emit('close');
            return;
          }
          if (response.status === 400) {
            if (response.data && response.data.body) {
              if (response.data.body.error && response.data.body.duplicate_fields) {
                this.showToastMessage({ title: this.$t('data_types.duplicate_detected'), message: response.data.body.duplicate_fields.join(' | '), type: 'error', duration: 10000 });
                return;
              }
            }
          }
          this.showToastMessage({ title: this.$t('data_types.failed_to_add'), type: 'error' });
          return;
        }
        if (this.mode === 'Edit') {
          const response = await apiPutDataType({
            name: this.name,
            mapping: this.mapping,
            fields: this.fieldsToArray(this.fields),
            type: this.designTimeActiveDatasourceType,
            model_id: this.designTimeActiveDatasourceModelId,
            colour: this.color,
          });
          this.loading = false;
          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('data_types.updated_successfully'), type: 'success' });
            this.$emit('saveintent', response.data);
            this.fetchDesignTimeData();
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: this.$t('data_types.failed_to_update'), type: 'error' });
        }
      }
    },
    async getEntityDefinition() {
      const response = await this.fetchDataType({ name: this.intent, type: this.designTimeActiveDatasourceType });

      this.color = response.colour;
      this.mapping = response.mapping;
      this.initialColor = response.colour;
      const intentDetails = response.fields;
      if (typeof intentDetails === 'object' && !Array.isArray(intentDetails) && intentDetails !== null) {
        // this.fields = intentDetails.join('\r\n');
        const fieldKeys = Object.keys(intentDetails);
        // TODO remove this string check once migration is done
        fieldKeys.forEach((key) => {
          if (typeof intentDetails[key] === 'string') {
            this.fields.push({
              key,
              dataType: '',
              value: intentDetails[key],
            });
          } else {
            this.fields.push({
              key,
              dataType: intentDetails[key].data_type,
              value: intentDetails[key].mapping,
            });
          }
        });
      } else if (Array.isArray(intentDetails)) {
        intentDetails.forEach((key) => {
          this.fields.push({
            key,
            dataType: '',
            value: '',
          });
        });
      }
      this.checkFieldsForNew();
    },
    onFieldInput() {
      this.checkFieldsForNew();
    },
    checkFieldsForNew() {
      if (this.fields.length) {
        if (this.fields[this.fields.length - 1].key !== '') {
          this.addField();
        }
      } else {
        this.addField();
      }
    },
  },
  created() {
    if (this.intent && this.intent.length) {
      this.mode = 'Edit';
      this.originalName = this.intent;
      this.name = this.intent;
      this.getEntityDefinition();
    } else {
      this.mode = 'Add';
      this.originalName = '';
      this.name = '';
      this.mapping = '';
      this.fields = [{ key: '', synonyms: '' }];
    }
  },
  watch: {
    'fields.length': {
      handler() {
        this.checkFieldsForNew();
      },
    },
  },
};
</script>
