<template>
  <div class="px-3 pb-2">
    <div class="mt-2">
      <VariableSelect v-model="aiPayload.variable" :filterDataType="['Text']" label="Variable" @update:modelValue="emitPayload" />

      <div class="mb-2 mt-3">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.provider') }}</div>
        <v-select v-model="aiPayload.value.provider" :options="providers" :reduce="(option) => option.value" class="w-full" @update:modelValue="emitPayload" />
      </div>

      <div class="mb-2 mt-3">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.persona') }}</div>
        <v-select v-model="aiPayload.value.persona" :options="persona" :reduce="(option) => option.value" class="w-full" @update:modelValue="emitPayload" />
      </div>

      <div class="mb-2 mt-3">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.context') }}</div>
        <Textarea v-model="aiPayload.value.context" class="w-full min-h-20" @update:modelValue="emitPayload" />
      </div>

      <div class="mb-2 mt-3">
        <VariableSelect v-model="aiPayload.value.datatable" :filter-data-type="['DataTable']" :label="$t('evaluate.datatable')" @update:modelValue="emitPayload" />
      </div>

      <div class="mb-2 mt-3">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.max_length_token') }}</div>
        <Input v-model="aiPayload.value.max_length" type="number" class="w-full" @update:modelValue="emitPayload" />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from '@/helpers/debounce';

export default {
  name: 'EvaluateSetAi',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      aiPayload: {
        variable: '',
        value: {
          context: '',
          datatable: '',
          max_length: '100',
          provider: 'OPENAI_CHATGPT',
        },
      },
      providers: [
        // { label: 'Fidsy 7B Instruct AWQ', value: 'FIDSY_7B_INSTRUCT_AWQ' },
        { label: 'Google Gemini Pro', value: 'GOOGLE_GEMINI_PRO' },
        { label: 'Google Gemini 1.5 Pro', value: 'GOOGLE_GEMINI_15_PRO' },
        { label: 'Microsoft ChatGPT 3.5', value: 'MICROSOFT_CHATGPT' },
        { label: 'Microsoft ChatGPT 4o', value: 'MICROSOFT_CHATGPT4' },
        { label: 'OpenAI ChatGPT 3.5', value: 'OPENAI_CHATGPT' },
        { label: 'OpenAI ChatGPT 4', value: 'OPENAI_CHATGPT4' },
      ],
      persona: [
        { label: 'Accountant (Xero)', value: 'XERO' },
        { label: 'Investment Analyst (Mintus)', value: 'MINTUS_EQUITY_ANALYST' },
        { label: 'Property Fund Manager (Mintus)', value: 'MINTUS_FUND_MANAGER' },
        { label: 'Wealth Manager (Mintus)', value: 'MINTUS' },
        { label: 'Business Analyst (Google Analytics)', value: 'GOOGLE_ANALYTICS' },
        { label: 'Business Analyst (QLIK)', value: 'QLIK' },
        { label: 'Translator', value: 'TRANSLATE' },
        { label: 'Custom', value: 'CUSTOM' },
      ],
    };
  },
  computed: {
    payload() {
      return {
        id: this.item.id,
        type: this.item.type,
        payload: this.aiPayload,
      };
    },
  },
  created() {},
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.aiPayload.value.max_length = this.aiPayload.value.max_length.toString();
      this.$emit('update', this.payload);
    }, 300),
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.aiPayload = JSON.parse(JSON.stringify(this.item.payload));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
