<template>
  <Modal
    sheetbelowsm
    close-button
    :title="$t('flow.copy_evaluate_items')"
    :primary-button-loading="loading"
    :primary-button-disabled="!(question || (conversation && step) || (project && endpoint))"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    @primary="onSaveClick"
    @secondary="close"
    @close="close"
  >
    <div class="sm:w-600">
      <div v-if="variableSelectorType !== 'no-code'" class="w-full flex flex-col justify-start mt-5 px-10">
        <label class="block text-13 mb-1">{{ $t('flow.copy_to') }}</label>
        <v-select v-model="copyTo" :options="copyToItems" appendToBody :reduce="(item) => item.value" :clearable="false" />
      </div>
      <template v-if="copyTo === 'collection'">
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('flow.question_name') }}</label>
          <v-select v-model="question" :options="questions" appendToBody :reduce="(item) => item.value" :clearable="false" />
        </div>
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('flow.step') }}</label>
          <v-select v-model="step" :options="collectionSteps" appendToBody :reduce="(item) => item.value" :clearable="false">
            <template v-if="!!question" v-slot:list-footer>
              <div
                style="bottom: -1px"
                class="flex sticky justify-center w-full cursor-pointer py-3 bg-gray-100 text-indigo-600 hover:text-indigo-800 border-t border-solid border-gray-200"
                @click="createStep"
              >
                {{ $t('flow.add_step') }}
              </div>
            </template>
          </v-select>
        </div>
      </template>
      <template v-if="copyTo === 'conversation'">
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('flow.conversations') }}</label>
          <v-select v-model="conversation" :options="conversations" appendToBody :reduce="(item) => item.value" :clearable="false" @update:modelValue="step = null" />
        </div>
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('flow.step') }}</label>
          <v-select v-model="step" :options="conversationSteps" appendToBody :reduce="(item) => item.value" :clearable="false">
            <template v-if="!!conversation" v-slot:list-footer>
              <div
                style="bottom: -1px"
                class="flex sticky justify-center w-full cursor-pointer py-3 bg-gray-100 text-indigo-600 hover:text-indigo-800 border-t border-solid border-gray-200"
                @click="createStep"
              >
                {{ $t('flow.add_step') }}
              </div>
            </template>
          </v-select>
        </div>
      </template>

      <div v-if="copyTo === 'collection'" class="w-full flex flex-col justify-start mt-5 px-10">
        <label class="block text-13 mb-1 text-red-600 font-700">{{ $t('flow.will_not_be_copied') }}</label>
        <label class="block text-13 mb-1 text-red-600 font-700">{{ toBeRemovedItems }}</label>
      </div>

      <template v-if="variableSelectorType === 'no-code'">
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('flow.projects') }}</label>
          <v-select
            v-model="project"
            :options="projectList"
            appendToBody
            :reduce="(item) => item.value"
            :clearable="false"
            @update:modelValue="
              endpoint = null;
              step = null;
            "
          />
        </div>
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('flow.endpoints') }}</label>
          <v-select v-model="endpoint" :options="endpoints" appendToBody :reduce="(item) => item.value" :clearable="false" @update:modelValue="step = null" />
        </div>
        <div class="w-full flex flex-col justify-start mt-5 px-10">
          <label class="block text-13 mb-1">{{ $t('flow.step') }}</label>
          <v-select v-model="step" :options="endpointSteps" appendToBody :reduce="(item) => item.value" :clearable="false">
            <template v-if="endpoint" v-slot:list-footer>
              <div
                style="bottom: -1px"
                class="flex sticky justify-center w-full cursor-pointer py-3 bg-gray-100 text-indigo-600 hover:text-indigo-800 border-t border-solid border-gray-200"
                @click="createStep"
              >
                {{ $t('flow.add_step') }}
              </div>
            </template>
          </v-select>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { apiPutConversationDefinition, apiPutProjectEndpointDefinition } from '@/helpers/api';
import uuidv4 from '@/helpers/uuid';
import { COLLECTION_HIDDEN_EVALUATE_TYPES } from '@/constants';
import { addNewStep, removeItemsByType } from '@/helpers/flow';

export default {
  name: 'EvaluateCopyModal',
  emits: ['close'],
  props: {
    selectedItemIds: {
      type: Array,
      default: () => [],
    },
    evaluateData: {
      type: Array,
      default: () => [],
    },
    variableSelectorType: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      copyTo: null,
      conversation: null,
      question: null,
      step: null,
      project: null,
      endpoint: null,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'projects', 'projectData', 'user', 'selectedIntentDefinition', 'selectedIntentDefinitionStepId']),
    ...mapGetters(['getCollectionsQuestions', 'getActiveDatasourceConversations']),
    copyToItems() {
      return [
        { label: this.$t('conversation.conversation'), value: 'conversation' },
        { label: this.$t('collection'), value: 'collection' },
      ];
    },
    toBeRemovedItems() {
      return COLLECTION_HIDDEN_EVALUATE_TYPES.map((item) => this.$t(`evaluate_editor.${item}`)).join(', ');
    },
    questions() {
      return this.getCollectionsQuestions.map((q) => {
        return {
          label: q.name,
          value: q.name,
        };
      });
    },
    conversations() {
      return Object.keys(this.getActiveDatasourceConversations)
        .map((key) => {
          return {
            label: key,
            value: key,
          };
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    },
    collectionSteps() {
      const steps = this.getCollectionsQuestions.find((q) => q.name === this.question)?.steps || [];
      return steps.map((step) => {
        return {
          label: step.name,
          value: step.id,
        };
      });
    },
    conversationSteps() {
      const steps = this.getActiveDatasourceConversations?.[this.conversation]?.steps || [];
      return steps.map((step) => {
        return {
          label: step.name,
          value: step.id,
        };
      });
    },
    projectList() {
      return Object.keys(this.projects).map((key) => ({ label: this.projects[key].name, value: key })) || [];
    },
    endpoints() {
      if (!this.project) return [];
      const endpoints = this.projectData?.[this.project]?.endpoints || {};
      return Object.keys(endpoints).map((key) => ({ value: key, label: `${endpoints[key].path} [${endpoints[key].method}]` }));
    },
    endpointSteps() {
      if (!this.project) return [];
      const steps = this.projectData?.[this.project]?.endpoints?.[this.endpoint]?.steps || [];
      return steps.map((step) => ({ label: step.name, value: step.id }));
    },
  },
  created() {
    this.fetchDesignTimeData();
  },
  methods: {
    ...mapActions(['showToastMessage', 'setCollectionQuestion', 'fetchDesignTimeData']),
    ...mapMutations(['SET_SELECTED_INTENT_DEFINITION_STEP', 'ADD_CONVERSATION_STEP', 'ADD_SELECTED_INTENT_DEFINITION_STEP', 'ADD_COLLECTION_STEP', 'ADD_PROJECT_STEP']),
    async createStep() {
      if (this.copyTo === 'conversation') {
        const conversation = JSON.parse(JSON.stringify(this.getActiveDatasourceConversations?.[this.conversation]));
        const steps = conversation.steps || [];
        const stepItem = addNewStep(steps);

        try {
          this.loading = true;
          const response = await apiPutConversationDefinition({
            name: conversation.name,
            type: this.designTimeActiveDatasourceType,
            model_id: this.designTimeActiveDatasourceModelId,
            steps,
            transition: conversation.transition,
          });
          this.loading = false;
          if (response.status === 200) {
            this.ADD_CONVERSATION_STEP({ type: this.designTimeActiveDatasourceType, flow: conversation.name, stepItem });
            if (this.selectedIntentDefinition && this.selectedIntentDefinition.name === conversation.name && this.$route.name === 'admin-conversation') {
              this.ADD_SELECTED_INTENT_DEFINITION_STEP(stepItem);
            }
            return;
          }
          this.showToastMessage({ title: this.$t('flow.failed_to_add_step'), ype: 'error' });
        } catch (e) {
          this.loading = false;
          this.showToastMessage({ title: this.$t('flow.failed_to_add_step'), type: 'error' });
        }
      } else if (this.copyTo === 'collection') {
        const question = JSON.parse(JSON.stringify(this.getCollectionsQuestions.find((q) => q.name === this.question)));
        const steps = question.steps || [];
        const stepItem = addNewStep(steps);
        question.type = this.designTimeActiveDatasourceType;

        this.loading = true;
        const response = await this.setCollectionQuestion({
          name: this.question,
          steps,
          transition: question.transition,
        });
        this.loading = false;
        if (response) {
          if (this.selectedIntentDefinition && this.selectedIntentDefinition.name === question.name && this.$route.name === 'admin-collection') {
            this.ADD_SELECTED_INTENT_DEFINITION_STEP(stepItem);
          } else {
            this.ADD_COLLECTION_STEP({ type: this.designTimeActiveDatasourceType, question: question.name, stepItem });
          }
        }
      } else if (this.variableSelectorType === 'no-code') {
        const endpoint = JSON.parse(JSON.stringify(this.projectData?.[this.project]?.endpoints?.[this.endpoint]));
        const steps = endpoint.steps || [];
        const stepItem = addNewStep(steps);

        const { project_id, endpoint_id, method, path, group_name, transition = '', request_params = [] } = endpoint;
        try {
          this.loading = true;
          const response = await apiPutProjectEndpointDefinition({
            project_id,
            endpoint_id,
            method,
            path,
            group_name,
            steps,
            request_params,
            transition,
          });
          this.loading = false;
          if (response.status === 200) {
            this.ADD_PROJECT_STEP({ projectId: project_id, endpointId: endpoint_id, stepItem });
            if (this.selectedIntentDefinition && this.selectedIntentDefinition.endpoint_id === this.endpoint && this.$route.name === 'no-code-flow') {
              this.ADD_SELECTED_INTENT_DEFINITION_STEP(stepItem);
            }
            return;
          }
          this.showToastMessage({ title: this.$t('flow.failed_to_add_step'), type: 'error' });
        } catch (e) {
          this.loading = false;
          this.showToastMessage({ title: this.$t('flow.failed_to_add_step'), type: 'error' });
        }
      }
    },
    close() {
      this.$emit('close');
    },
    async onSaveClick() {
      let items = this.evaluateData.filter((e) => this.selectedItemIds.includes(e.id));
      items = items.map((item) => {
        return {
          ...item,
          id: uuidv4(),
        };
      });

      if (this.variableSelectorType === 'no-code') {
        if (this.selectedIntentDefinitionStepId === this.step && this.$route.name === 'no-code-flow') {
          this.$emit('addToCurrent', items);
          this.close();
          return;
        }

        const endpoint = JSON.parse(JSON.stringify(this.projectData?.[this.project]?.endpoints?.[this.endpoint]));
        const steps = endpoint.steps || [];
        const step = steps.find((s) => s.id === this.step);
        step.evaluate = [...step.evaluate, ...items];

        const { project_id, endpoint_id, method, path, group_name, transition = '', request_params = [] } = endpoint;
        try {
          this.loading = true;
          const response = await apiPutProjectEndpointDefinition({
            project_id,
            endpoint_id,
            method,
            path,
            group_name,
            steps,
            request_params,
            transition,
          });
          this.loading = false;
          if (response.status === 200) {
            this.SET_SELECTED_INTENT_DEFINITION_STEP(step);
            this.showToastMessage({ message: this.$t('flow.items_copied'), type: 'success' });
            this.close();
            return;
          }
          this.showToastMessage({ title: this.$t('flow.failed_to_copy_items'), type: 'error' });
        } catch (e) {
          this.loading = false;
          this.showToastMessage({ title: this.$t('flow.failed_to_copy_items'), type: 'error' });
        }
      } else if (this.copyTo === 'conversation') {
        if (this.selectedIntentDefinitionStepId === this.step && this.$route.name === 'admin-conversation') {
          this.$emit('addToCurrent', items);
          this.close();
          return;
        }
        const conversation = JSON.parse(JSON.stringify(this.getActiveDatasourceConversations?.[this.conversation]));
        const steps = conversation.steps || [];
        const step = steps.find((s) => s.id === this.step);
        step.evaluate = [...step.evaluate, ...items];

        try {
          this.loading = true;
          const response = await apiPutConversationDefinition({
            name: conversation.name,
            type: this.designTimeActiveDatasourceType,
            model_id: this.designTimeActiveDatasourceModelId,
            steps,
            transition: conversation.transition,
          });
          this.loading = false;
          if (response.status === 200) {
            if (this.conversation === this.selectedIntentDefinition.name) {
              this.SET_SELECTED_INTENT_DEFINITION_STEP(step);
            }

            this.showToastMessage({ message: this.$t('flow.items_copied'), type: 'success' });
            this.close();
            return;
          }
          this.showToastMessage({ title: this.$t('flow.failed_to_copy_items'), ype: 'error' });
        } catch (e) {
          this.loading = false;
          this.showToastMessage({ title: this.$t('flow.failed_to_copy_items'), type: 'error' });
        }
      } else if (this.copyTo === 'collection') {
        if (this.selectedIntentDefinitionStepId === this.step && this.$route.name === 'admin-collection') {
          this.$emit('addToCurrent', items);
          this.close();
          return;
        }
        const question = JSON.parse(JSON.stringify(this.getCollectionsQuestions.find((q) => q.name === this.question)));

        items = removeItemsByType(items, COLLECTION_HIDDEN_EVALUATE_TYPES);

        const steps = question.steps || [];
        const step = steps.find((s) => s.id === this.step);
        step.evaluate = [...step.evaluate, ...items];

        question.type = this.designTimeActiveDatasourceType;

        this.loading = true;
        const response = await this.setCollectionQuestion({
          name: this.question,
          steps,
          transition: question.transition,
        });
        this.loading = false;
        if (response) {
          if (this.question === this.selectedIntentDefinition.name) {
            this.SET_SELECTED_INTENT_DEFINITION_STEP(step);
          }
          this.showToastMessage({ message: this.$t('flow.items_copied') });
          this.close();
        }
      }
    },
  },
};
</script>
