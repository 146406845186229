<template>
  <div
    class="flex p-2 pl-2 mt-2 mr-0 mb-2 text-13 rounded-4 flow-step-list-item"
    :class="{ 'text-red-500 border border-red-500 border-solid border-box': invalid, 'text-gray-700': !invalid }"
    @click="$emit('click', $event)"
  >
    <Icon v-if="icon" :name="icon" class="mx-1 w-4 flex-shrink-0 mt-0.5" :class="[iconClass]" />
    <div :class="[contentClass]">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlowStepListItem',
  props: {
    icon: {
      type: String,
    },
    iconClass: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
};
</script>
<style lang="scss">
.flow-step-list-item {
  background-color: #f4f4f4;
}
[data-depth='1'] > .flow-step-item-section > .flow-step-list-item,
[data-depth='3'] > .flow-step-item-section > .flow-step-list-item {
  background-color: #f4f4f4;
}
[data-depth='2'] > .flow-step-item-section > .flow-step-list-item,
[data-depth='4'] > .flow-step-item-section > .flow-step-list-item {
  background-color: #ffffff;
}
</style>
