<template>
  <div class="px-3 pb-2">
    <div>
      <label class="block text-12 mb-1 mt-3">{{ $t('evaluate.select_variable') }}</label>
      <VariableSelect
        v-model="statementPayload.payload.variable"
        :filter="lhsVariableFilter"
        class="flex-grow"
        :placeholder="$t('evaluate.select_variable')"
        @update:modelValue="onVariableInput"
        @option:selected="onVariableOptionSelected"
      >
      </VariableSelect>
      {{ getInputType(statementPayload.payload.variable) }}
      <div
        class="flex"
        :class="{
          'flex-wrap':
            getInputType(statementPayload.payload.variable) === 'datatable' ||
            (statementPayload.payload.assignment_type === 'value' && getInputType(statementPayload.payload.variable) === 'string' && statementPayload.payload.dataType === 'Text'),
        }"
      >
        <div>
          <label class="block text-12 mb-1 mt-3">{{ $t('evaluate.assign') }}</label>
          <v-select
            v-model="statementPayload.payload.assignment_type"
            :reduce="(option) => option.value"
            class="w-150 mr-3"
            :searchable="false"
            :clearable="false"
            appendToBody
            :options="[
              { label: $t('function'), value: 'function' },
              { label: $t('variable'), value: 'variable' },
              { label: $t('value'), value: 'value' },
            ]"
            @option:selected="onValueTypeChanged"
          ></v-select>
        </div>

        <template v-if="statementPayload.payload.assignment_type === 'value' && statementPayload.payload.variable">
          <div class="w-[160px] mt-3 pr-3 flex-shrink-0">
            <label class="block text-12 mb-1">{{ $t('data_type') }}</label>
            <v-select
              v-model="statementPayload.payload.dataType"
              :options="getDataTypes"
              :reduce="(type) => type.value"
              :clearable="false"
              :searchable="false"
              appendToBody
              @option:selected="statementPayload.payload.value = null"
            />
          </div>
        </template>

        <template v-if="statementPayload.payload.assignment_type === 'value' && statementPayload.payload.variable && isUserDataType(statementPayload.payload.dataType)">
          <div class="w-full mt-3">
            <label class="block text-12 mb-1">{{ $t('value') }}</label>
            <v-select
              class="w-full"
              key="static"
              :clearable="false"
              :modelValue="getFieldNameFromDotNotation(statementPayload.payload.value)"
              :reduce="(option) => option.value"
              :options="getSelectableParameters(statementPayload.payload.dataType)"
              @option:selected="onStaticFieldsValueInput"
            ></v-select>
          </div>
        </template>
        <template v-else-if="statementPayload.payload.assignment_type === 'value' && statementPayload.payload.variable && isSystemType(statementPayload.payload.variable)">
          <div class="w-full mt-3 overflow-hidden">
            <label class="block text-12 mb-1">{{ $t('value') }}1</label>
            <v-select v-if="variableDataType === 'YesNo'" v-model="statementPayload.payload.value" :options="['Yes', 'No']" appendToBody class="w-full" @update:modelValue="emitPayload" />
            <div v-else-if="getInputType(statementPayload.payload.variable) === 'string'" class="h-400">
              <PhraseEditor multiline :items="variableList" v-model="statementPayload.payload.value" class="w-full" @update:modelValue="emitPayload" />
            </div>
            <Input v-else-if="getInputType(statementPayload.payload.variable) === 'number'" type="number" v-model="statementPayload.payload.value" class="w-full" @update:modelValue="emitPayload" />
            <div v-else-if="getInputType(statementPayload.payload.variable) === 'datatable'" class="h-400">
              <div class="flex py-2 flex-shrink-0">
                <div
                  class="w-100 select-none cursor-pointer h-10 flex items-center justify-center border-purple-200 border-b border-r"
                  :class="{ 'text-gray-900': tab === 'editor', 'text-gray-600': tab !== 'editor' }"
                  :style="{ 'border-bottom-color': tab === 'editor' ? 'transparent' : '' }"
                  @click="tab = 'editor'"
                >
                  {{ $t('editor') }}
                </div>
                <div
                  class="w-100 select-none cursor-pointer h-10 flex items-center justify-center border-purple-200 border-b"
                  :class="{ 'text-gray-900': tab === 'preview', 'text-gray-600': tab !== 'preview' }"
                  :style="{ 'border-bottom-color': tab === 'preview' ? 'transparent' : '' }"
                  @click="tab = 'preview'"
                >
                  {{ $t('preview') }}
                </div>
              </div>

              <div class="w-full h-full relative" v-show="tab === 'editor'">
                <MonacoEditor
                  width="100%"
                  height="100%"
                  class="flex-grow h-full"
                  v-model:value="statementPayload.payload.value"
                  language="json"
                  :options="resultEditorOptions"
                  @mount="monacoMounted"
                  @update:value="emitPayload"
                ></MonacoEditor>
                <div class="absolute bottom-0 right-6 bg-white text-gray-600 text-13 p-1 cursor-pointer" @click="formatEditor">{{ $t('format_code') }}</div>
              </div>

              <div v-show="tab === 'preview'">
                <DataTable v-if="Array.isArray(dataTableValue)" :value="dataTableValue" />
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="statementPayload.payload.assignment_type === 'value' && statementPayload.payload.variable && !isSystemType(statementPayload.payload.variable)">
          <div class="w-full mt-3">
            <label class="block text-12 mb-1">{{ $t('value') }}</label>
            <v-select class="w-full" v-model="statementPayload.payload.value" :options="getDataTypeFields(statementPayload.payload.variable)"> </v-select>
          </div>
        </template>

        <template v-if="statementPayload.payload.assignment_type === 'variable'">
          <div class="flex mt-3 w-full">
            <div class="w-200 flex-shrink-0 pr-3">
              <span class="text-13">{{ $t('data_type') }}</span>
              <v-select
                class="w-full"
                v-model="statementPayload.payload.dataType"
                :reduce="(option) => option.value"
                :options="getDataTypes"
                :clearable="false"
                @update:modelValue="onDataTypeChange"
              ></v-select>
            </div>
            <div class="flex-grow min-w-200 flex-shrink-0">
              <span class="text-13">{{ $t('value') }}</span>
              <VariableSelect v-model="statementPayload.payload.value" :filter="rhsVariableFilter" class="w-full" :placeholder="$t('evaluate.select_variable')" @update:modelValue="emitPayload">
              </VariableSelect>
            </div>
          </div>
        </template>

        <template v-if="statementPayload.payload.assignment_type === 'function'">
          <div class="mt-3 flex-grow">
            <label class="block text-12 mb-1">{{ $t('function') }}</label>
            <v-select
              v-if="functionList && functionList.length"
              :options="functionList"
              label="title"
              :modelValue="statementPayload.payload.value.function"
              appendToBody
              @update:modelValue="onFunctionSelected"
            >
              <template v-slot:option="{ title, description, returns }">
                <div class="flex items-center">
                  <div>
                    <div>{{ title }}</div>
                    <div class="text-11">{{ description }}</div>
                  </div>
                  <Badge class="ml-auto" :text="returns" info></Badge>
                </div>
              </template>
            </v-select>
            <div v-else class="flex items-center text-13 min-h-9">{{ $t('flow.no_match_functions', { dataType: variableDataType }) }}</div>
          </div>
        </template>
      </div>

      <div v-if="statementPayload.payload.assignment_type === 'function' && selectedFunction" class="mt-3">
        <label class="block text-12 mb-1 mt-3">{{ $t('collections.parameters') }}</label>
        <div v-for="(argument, index) in selectedFunction.arguments" :key="argument.name + index" class="mb-2">
          <label class="block text-12 mb-1 mt-3">
            {{ argument.name }} <span class="text-12 bg-gray-200 rounded-6 px-1">&lt;{{ argument.type }}&gt;</span>
            <Icon name="info" class="w-4 h-4 inline-block -mb-1 ml-1" :title="argument.description" />
          </label>
          <div class="flex">
            <div class="w-1/3 pr-3">
              <v-select
                v-model="statementPayload.payload.value.argumentsData[index].type"
                autoSelect
                :options="getArgumentAssignTypes(argument.type)"
                :reduce="(type) => type.value"
                :clearable="false"
                :searchable="false"
                appendToBody
                @option:selected="onSelectedArgumentAssignTypeChanged($event, index)"
              />
            </div>
            <div class="w-2/3 flex">
              <v-select
                placeholder="Select Data Type"
                class="w-full mr-3"
                autoSelect
                v-model="statementPayload.payload.value.argumentsData[index].dataType"
                :reduce="(option) => option.value"
                :options="getParameterDataTypes(index, argument)"
                :clearable="false"
                @option:selected="onParameterDataTypeInput(index)"
              ></v-select>

              <template v-if="statementPayload.payload.value.argumentsData[index].type === 'static'">
                <v-select
                  v-if="isUserDataType(statementPayload.payload.value.argumentsData[index].dataType)"
                  class="w-300"
                  key="static"
                  :clearable="false"
                  :modelValue="getFieldNameFromDotNotation(statementPayload.payload.value.arguments[index])"
                  :reduce="(option) => option.value"
                  :options="getSelectableParameters(statementPayload.payload.value.argumentsData[index].dataType)"
                  @option:selected="onFunctionParameterSelect($event, index)"
                ></v-select>

                <Input
                  v-if="statementPayload.payload.value.argumentsData[index].dataType === 'Text'"
                  v-model="statementPayload.payload.value.arguments[index]"
                  class="w-300"
                  @update:modelValue="updateStatementValue($event, index, argument)"
                />
                <Input
                  v-else-if="statementPayload.payload.value.argumentsData[index].dataType === 'Number'"
                  v-model="statementPayload.payload.value.arguments[index]"
                  class="w-300"
                  type="number"
                  @update:modelValue="updateStatementValue($event, index, argument)"
                />
              </template>
              <template v-else>
                <VariableSelect
                  class="w-300"
                  curly
                  :filter-data-type="[statementPayload.payload.value.argumentsData[index].dataType]"
                  v-model="statementPayload.payload.value.arguments[index]"
                  @update:modelValue="updateStatementValue($event, index, argument)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MonacoEditor from '@guolao/vue-monaco-editor';
import debounce from '@/helpers/debounce';
import PhraseEditor from '@/components/ui/PhraseEditor';
import uuidv4 from '@/helpers/uuid';
import { MONACO_EDITOR_DEFAULTS, VARIABLE_TYPE } from '@/constants';

export default {
  name: 'EvaluateSetStatement',
  components: { PhraseEditor, MonacoEditor },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      statementPayload: {
        type: 'statement',
        payload: {
          variable: null,
          assignment_type: 'function',
          value: '',
          dataType: null,
        },
      },
      selectedFunction: null,
      resultEditorOptions: MONACO_EDITOR_DEFAULTS,
      tab: 'editor',
      editor: null,
    };
  },
  inject: {
    variableSelectorType: { default: 'conversation' },
  },
  computed: {
    ...mapState(['functions']),
    ...mapGetters(['getNoCodeProjectVariables', 'getActiveDatasourceVariables', 'getNoCodeEntities', 'getActiveDatasourceDataTypes']),
    dataTableValue() {
      try {
        return JSON.parse(this.statementPayload.payload.value);
      } catch {
        return [];
      }
    },
    variableList() {
      return Object.keys(this.variables)
        .map((v) => {
          return {
            id: uuidv4(),
            value: v,
          };
        })
        .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
    },
    variables() {
      if (this.variableSelectorType === 'no-code') {
        return this.getNoCodeProjectVariables;
      }
      return this.getActiveDatasourceVariables;
    },
    entities() {
      if (this.variableSelectorType === 'no-code') {
        return this.getNoCodeEntities;
      }
      return this.getActiveDatasourceDataTypes;
    },
    functionList() {
      if (this.functions) {
        let functions = Object.entries(this.functions)
          .map((fn) => {
            const [key, value] = fn;
            return {
              title: key,
              ...value,
            };
          })
          .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

        if (this.statementPayload.payload.variable) {
          functions = functions.filter((fn) => {
            const returns = fn.returns.split('|');
            return returns.includes(this.variables[this.statementPayload.payload.variable]?.dataType);
          });
        }
        return functions;
      }
      return [];
    },
    valueType() {
      return this.statementPayload.payload.assignment_type;
    },
    variableDataType() {
      return this.variables?.[this.statementPayload.payload.variable]?.dataType;
    },
    showValueAssignmentType() {
      return this.variableDataType === 'YesNo' || ['string', 'number', 'datatable'].includes(this.getInputType(this.statementPayload.payload.variable));
    },
    getDataTypes() {
      let dataTypes = [];
      if (this.variables?.[this.statementPayload.payload.variable]?.dataType) {
        if (this.variables?.[this.statementPayload.payload.variable]?.dataType === 'Text') {
          dataTypes = Object.keys(this.entities)
            .filter((dataType) => this.entities[dataType].entityType !== VARIABLE_TYPE.SYSTEM)
            .filter((dataType) => !['DataTable', 'ChartConfig'].includes(dataType));

          if (
            this.statementPayload.payload.variable &&
            this.variables?.[this.statementPayload.payload.variable]?.dataType &&
            !dataTypes.includes(this.variables?.[this.statementPayload.payload.variable]?.dataType)
          ) {
            dataTypes.push(this.variables[this.statementPayload.payload.variable].dataType);
          }
        } else {
          dataTypes.push(this.variables[this.statementPayload.payload.variable].dataType);
        }
      }

      return dataTypes.map((dataType) => ({ label: dataType, value: dataType })).sort((a, b) => a.label - b.label);
    },
  },
  methods: {
    onVariableOptionSelected(e) {
      if (this.statementPayload.payload.assignment_type === 'value' || (this.statementPayload.payload.assignment_type === 'variable' && e?.variable?.dataType)) {
        if (this.statementPayload.payload.dataType !== e.variable.dataType) {
          this.statementPayload.payload.value = null;
        }
        this.statementPayload.payload.dataType = e.variable.dataType;
      }
      this.emitPayload();
    },
    onVariableInput(e) {
      if (e === null) {
        this.clearRHS();
      }
    },
    onStaticFieldsValueInput(field) {
      const dataType = this.statementPayload.payload?.dataType;
      this.statementPayload.payload.value = this.joinWithDotNotation(dataType, field.value);
      this.emitPayload();
    },
    onFunctionParameterSelect(field, index) {
      const dataType = this.statementPayload.payload.value.argumentsData[index]?.dataType;
      this.statementPayload.payload.value.arguments[index] = this.joinWithDotNotation(dataType, field.value);
      this.emitPayload();
    },
    joinWithDotNotation(dataType, fieldName) {
      return `${dataType}|.|${fieldName}`;
    },
    getFieldNameFromDotNotation(value) {
      if (!value) return value;
      const arr = value.split('|.|');
      if (arr.length) {
        return arr[arr.length - 1];
      }
      return '';
    },
    getTypeNameFromDotNotation(value) {
      if (!value) return value;
      const arr = value.split('|.|');
      return arr?.[0] || value;
    },
    isUserDataType(dataType) {
      return typeof this.entities[dataType]?.fields === 'object';
    },
    onParameterDataTypeInput(index) {
      this.statementPayload.payload.value.arguments[index] = null;
    },
    getParameterDataTypes(index) {
      let dataTypes = [];
      if (this.selectedFunction) {
        if (this.selectedFunction.arguments[index].type.split('|').includes('Text')) {
          dataTypes = Object.keys(this.entities)
            .filter((dataType) => this.entities[dataType].entityType !== VARIABLE_TYPE.SYSTEM)
            .filter((dataType) => !['DataTable', 'ChartConfig'].includes(dataType));
        }
        const argTypes = this.selectedFunction.arguments[index].type.split('|');
        if (this.statementPayload.payload.value.argumentsData[index].type === 'static') {
          argTypes.forEach((argType) => {
            if (!dataTypes.includes(argType)) dataTypes.push(argType);
          });
        } else {
          argTypes.forEach((argType) => {
            if (!dataTypes.includes(argType)) dataTypes.push(argType);
          });
        }
      }
      return dataTypes.map((dataType) => ({ label: dataType, value: dataType })).sort((a, b) => a.label - b.label);
    },
    onDataTypeChange() {
      this.statementPayload.payload.value = '';
    },
    getSelectableParameters(dataType) {
      if (dataType) {
        const fields = this.entities[dataType].fields || {};
        return Object.keys(fields).map((field) => ({ label: field, value: field }));
      }
      return [];
    },
    formatEditor() {
      this.editor.getAction('editor.action.formatDocument').run();
    },
    monacoMounted(editor, monaco) {
      this.editor = editor;
      // validation settings
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: false,
      });
      // compiler options
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES6,
        allowNonTsExtensions: true,
      });
    },
    isSystemType(variableName) {
      return this.entities?.[this.variables?.[variableName]?.dataType]?.entityType === VARIABLE_TYPE.SYSTEM;
    },
    getDataTypeFields(variableName) {
      const fields = this.entities?.[this.variables?.[variableName]?.dataType]?.fields || {};
      return Object.keys(fields).map((field) => ({ label: field, value: field }));
    },
    getInputType(variableName) {
      return this.entities?.[this.variables?.[variableName]?.dataType]?.dataType;
    },
    lhsVariableFilter(variable) {
      if (this.statementPayload.payload.value && this.statementPayload.payload.assignment_type === 'variable') {
        return this.variables[this.statementPayload.payload.value].dataType === variable.dataType && !variable.readonly;
      }
      if (this.statementPayload.payload.assignment_type === 'function' && this.selectedFunction) {
        const returns = this.selectedFunction.returns.split('|');
        return returns.includes(variable.dataType) && !variable.readonly;
      }
      return !variable.readonly;
    },
    rhsVariableFilter(variable) {
      if (this.statementPayload.payload.dataType) {
        return this.statementPayload.payload.dataType === variable.dataType;
      }
      return true;
    },
    onValueTypeChanged() {
      this.clearRHS();
      this.emitPayload();
    },
    clearRHS() {
      if (this.valueType === 'function') {
        this.statementPayload.payload.value = {
          function: null,
          arguments: [],
          argumentsData: [],
        };
        this.selectedFunction = null;
      } else {
        this.statementPayload.payload.value = '';
      }

      if (this.getDataTypes.length === 1) {
        this.statementPayload.payload.dataType = this.getDataTypes[0].value;
      } else {
        this.statementPayload.payload.dataType = null;
      }
    },
    onSelectedArgumentAssignTypeChanged(event, index) {
      this.statementPayload.payload.value.arguments[index] = null;
    },
    getArgumentAssignTypes(argType) {
      const argTypes = argType.split('|');
      const argumentAssignTypes = [
        { label: this.$t('variable'), value: 'variable' },
        { label: this.$t('static'), value: 'static' },
      ];
      if (argTypes.includes('Number') || argTypes.includes('Text')) {
        return argumentAssignTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
      if (this.entities?.[argTypes[0]] && 'fields' in this.entities?.[argTypes[0]]) {
        return argumentAssignTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
      return argumentAssignTypes.filter((item) => item.value !== 'static').sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    },
    updateStatementValue(value, index) {
      if (this.valueType === 'function') {
        this.statementPayload.payload.value.arguments[index] = value;
        this.emitPayload();
      }
    },
    onFunctionSelected(e) {
      this.selectedFunction = e;

      this.statementPayload.payload.value.argumentsData = [];
      if (this.selectedFunction?.arguments) {
        this.selectedFunction.arguments.forEach((args, index) => {
          this.statementPayload.payload.value.argumentsData[index] = { dataType: null, type: 'variable' };
        });

        // eslint-disable-next-line
        const args = Array.apply(null, Array(this.selectedFunction.arguments.length)).map(() => null);
        this.statementPayload.payload.value.arguments = args;
        this.statementPayload.payload.dataType = null;
      }

      this.statementPayload.payload.value.function = e?.title || null;
    },
    // eslint-disable-next-line
    debouncedPayload: debounce(function () {
      const statementPayload = JSON.parse(JSON.stringify(this.statementPayload));

      if (statementPayload.payload.assignment_type === 'function') {
        delete statementPayload.payload.value.argumentsData;
      }
      delete statementPayload.payload.dataType;

      this.$emit('update', statementPayload);
    }, 300),
    emitPayload(e) {
      if (e) {
        if (this.statementPayload.payload.value && this.statementPayload.payload.assignment_type === 'variable') {
          if (this.variables[this.statementPayload.payload.value].dataType !== this.variables[e].dataType) {
            this.clearRHS();
          }
        }
        if (this.statementPayload.payload.assignment_type === 'function') {
          if (!this.functionList.length) {
            this.statementPayload.payload.assignment_type = 'variable';
            this.clearRHS();
          } else if (this.selectedFunction) {
            const returns = this.selectedFunction.returns.split('|');
            if (!returns.includes(this.variables[e].dataType)) {
              this.clearRHS();
            }
          }
        }
      }

      this.validate();
      this.debouncedPayload();
    },
    validate() {
      let valid = true;
      if (this.valueType === 'value') {
        if (this.variableDataType === 'DataTable') {
          try {
            const payload = JSON.parse(this.statementPayload.payload.value);
            if (!Array.isArray(payload)) {
              valid = false;
            }
          } catch (e) {
            valid = false;
          }
        }
      }
      this.$emit('valid', valid);
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.statementPayload = JSON.parse(JSON.stringify(this.item));
          if (this.statementPayload.payload.assignment_type === 'function') {
            // Set function
            const fn = this.functionList.find((f) => f.title === this.statementPayload.payload.value.function);
            this.selectedFunction = fn;
            this.statementPayload.payload.value.function = fn?.title || null;

            this.statementPayload.payload.value.argumentsData = [];
            this.selectedFunction?.arguments?.forEach((args, index) => {
              this.statementPayload.payload.value.argumentsData[index] = { dataType: null, type: 'variable' };
              const value = this.statementPayload.payload.value.arguments[index];
              if (value) {
                if (value.toString().startsWith('{') && value.toString().endsWith('}')) {
                  const variableName = value.toString().substring(1).slice(0, -1);
                  this.statementPayload.payload.value.argumentsData[index].type = 'variable';
                  this.statementPayload.payload.value.argumentsData[index].dataType = this.variables?.[variableName]?.dataType || null;
                } else {
                  this.statementPayload.payload.value.argumentsData[index].type = 'static';

                  if (value.toString().includes('|.|')) {
                    this.statementPayload.payload.value.argumentsData[index].dataType = this.getTypeNameFromDotNotation(value.toString());
                  } else {
                    this.statementPayload.payload.value.argumentsData[index].dataType = args.type.split('|')[0];
                  }
                }
              }
            });
          } else if (this.statementPayload.payload.assignment_type === 'value') {
            if (this.statementPayload.payload.value.includes('|.|')) {
              this.statementPayload.payload.dataType = this.getTypeNameFromDotNotation(this.statementPayload.payload.value.toString());
            } else {
              this.statementPayload.payload.dataType = this.variables[this.statementPayload.payload.variable].dataType;
            }
          } else if (this.statementPayload.payload.assignment_type === 'variable') {
            if (this.statementPayload.payload.value) {
              this.statementPayload.payload.dataType = this.variables[this.statementPayload.payload.value].dataType;
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
