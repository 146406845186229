<template>
  <div class="shadow-analysisCard rounded-26 mt-3 border-solid border-2 border-white bg-off-white">
    <AnalysisCard :datasource-type="datasourceType" :result="result" @export="openExportModal" />
  </div>
</template>
<script>
import AnalysisCardExportModal from '@/components/conversation/AnalysisCardExportModal.vue';
import AnalysisCard from '@/components/conversation/AnalysisCard.vue';

export default {
  name: 'ConversationCard',
  components: { AnalysisCard },
  props: {
    result: Object,
    datasourceType: String,
  },
  methods: {
    openExportModal({ showCardText, selectedChartType, showInsightsPanel }) {
      this.$showModal(AnalysisCardExportModal, {
        datasourceType: this.datasourceType,
        showExportModal: true,
        result: this.result,
        showCardText,
        showInsightsPanel,
        exportChartType: selectedChartType.toLowerCase(),
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
