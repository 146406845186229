/* global d3 */

/**
 * SVG Path rounding function. Takes an input path string and outputs a path
 * string where all line-line corners have been rounded. Only supports absolute
 * commands at the moment.
 *
 * @param path The SVG input path
 * @param radius The amount to round the corners, either a value in the SVG
 *               coordinate space, or, if useFractionalRadius is true, a value
 *               from 0 to 1.
 * @param useFractionalRadius If true, the curve radius is expressed as a
 *               fraction of the distance between the point being curved and
 *               the previous and next points.
 * @returns A new SVG path string with the rounding
 */
export function roundPathCorners(path, radius, useFractionalRadius) {
  const pathString = path.replace(/[,]/g, ' ').replace(/L/g, ' L');

  function moveTowardsFractional(movingPoint, targetPoint, fraction) {
    return {
      x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
      y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction,
    };
  }

  function moveTowardsLength(movingPoint, targetPoint, amount) {
    const width = targetPoint.x - movingPoint.x;
    const height = targetPoint.y - movingPoint.y;

    const distance = Math.sqrt(width * width + height * height);

    return moveTowardsFractional(movingPoint, targetPoint, Math.min(1, amount / distance));
  }

  // Adjusts the ending position of a command
  function adjustCommand(cmd, newPoint) {
    if (cmd.length > 2) {
      cmd[cmd.length - 2] = newPoint.x;
      cmd[cmd.length - 1] = newPoint.y;
    }
  }

  // Gives an {x, y} object for a command's ending position
  function pointForCommand(cmd) {
    return {
      x: parseFloat(cmd[cmd.length - 2]),
      y: parseFloat(cmd[cmd.length - 1]),
    };
  }

  // Split apart the path, handing concatenated letters and numbers
  const pathParts = pathString.split(/[,\s]/).reduce((parts, part) => {
    const match = part.match('([a-zA-Z])(.+)');
    if (match) {
      parts.push(match[1]);
      parts.push(match[2]);
    } else {
      parts.push(part);
    }

    return parts;
  }, []);

  // Group the commands with their arguments for easier handling
  const commands = pathParts.reduce((cmds, part) => {
    // eslint-disable-next-line
    if (parseFloat(part) == part && cmds.length) {
      // eslint-disable-line
      cmds[cmds.length - 1].push(part);
    } else {
      cmds.push([part]);
    }

    return cmds;
  }, []);

  // The resulting commands, also grouped
  let resultCommands = [];

  if (commands.length > 1) {
    // We always use the first command (but it may be mutated)
    resultCommands.push(commands[0]);

    for (let cmdIndex = 1; cmdIndex < commands.length; cmdIndex++) {
      const prevCmd = resultCommands[resultCommands.length - 1];

      const curCmd = commands[cmdIndex];

      // Handle closing case
      const nextCmd = commands[cmdIndex + 1];

      // Nasty logic to decide if this path is a candidate.
      if (nextCmd && prevCmd && prevCmd.length > 2 && curCmd[0] === 'L' && nextCmd.length > 2 && nextCmd[0] === 'L') {
        // Calc the points we're dealing with
        const prevPoint = pointForCommand(prevCmd);
        const curPoint = pointForCommand(curCmd);
        const nextPoint = pointForCommand(nextCmd);

        // The start and end of the curve are just our point moved towards the previous and next
        // points, respectively
        let curveStart;
        let curveEnd;

        if (useFractionalRadius) {
          curveStart = moveTowardsFractional(curPoint, prevCmd.origPoint || prevPoint, radius);
          curveEnd = moveTowardsFractional(curPoint, nextCmd.origPoint || nextPoint, radius);
        } else {
          curveStart = moveTowardsLength(curPoint, prevPoint, radius);
          curveEnd = moveTowardsLength(curPoint, nextPoint, radius);
        }

        // Adjust the current command and add it
        adjustCommand(curCmd, curveStart);
        curCmd.origPoint = curPoint;
        resultCommands.push(curCmd);

        // The curve control points are halfway between the start/end of the curve and
        // the original point
        const startControl = moveTowardsFractional(curveStart, curPoint, 0.5);
        const endControl = moveTowardsFractional(curPoint, curveEnd, 0.5);

        // Create the curve
        const curveCmd = ['C', startControl.x, startControl.y, endControl.x, endControl.y, curveEnd.x, curveEnd.y];
        // Save the original point for fractional calculations
        curveCmd.origPoint = curPoint;
        resultCommands.push(curveCmd);
      } else {
        // Pass through commands that don't qualify
        resultCommands.push(curCmd);
      }
    }
  } else {
    resultCommands = commands;
  }

  return resultCommands.reduce((str, c) => {
    return `${str}${c.join(' ')} `;
  }, '');
}

export function drawCircle(id, x, y) {
  const u = d3
    .select(id)
    .selectAll('circle')
    .data([[x, y]]);

  u.enter()
    .append('circle')
    .attr('r', 4)
    .attr('fill', '#41a0fc')
    .merge(u)
    .attr('cx', (d) => d[0])
    .attr('cy', (d) => d[1]);

  u.exit().remove();
}

export function drawArrow(id, x, y) {
  const svg = d3.select(id);

  const triangles = [];
  triangles.push({
    /* prettier-ignore */ d:
      'M 6.499999523162842 2.931354522705078 C 6.328429698944092 2.931354522705078 5.901089668273926 2.979225158691406 5.639079570770264 3.422635078430176 L 2.643969535827637 8.491274833679199 C 2.3773193359375 8.942514419555664 2.549819946289062 9.343964576721191 2.636359214782715 9.495624542236328 C 2.722899436950684 9.647274971008301 2.980759620666504 9.999994277954102 3.504899978637695 9.999994277954102 L 9.495109558105469 9.999994277954102 C 10.0191593170166 9.999994277954102 10.27702903747559 9.647234916687012 10.36357975006104 9.495574951171875 C 10.45011901855469 9.343904495239258 10.62262916564941 8.942444801330566 10.35602951049805 8.491274833679199 L 7.36091947555542 3.422635078430176 C 7.098909378051758 2.979225158691406 6.671569347381592 2.931354522705078 6.499999523162842 2.931354522705078 M 6.499999523162842 1.93135929107666 C 7.167394638061523 1.93135929107666 7.834789752960205 2.258874893188477 8.22184944152832 2.913905143737793 L 11.21695899963379 7.982544898986816 C 12.00472164154053 9.315692901611328 11.04354667663574 10.9999942779541 9.495113372802734 10.9999942779541 C 9.49501895904541 10.9999942779541 9.495193481445312 10.9999942779541 9.495100021362305 10.9999942779541 L 3.504899978637695 10.9999942779541 C 1.956299781799316 11.00000476837158 0.9952297210693359 9.315774917602539 1.783039093017578 7.982544898986816 L 4.778149604797363 2.913905143737793 C 5.165209293365479 2.258874893188477 5.83260440826416 1.93135929107666 6.499999523162842 1.93135929107666 Z',
    x,
    y,
  });
  triangles.push({
    /* prettier-ignore */ d:
       'M 9.495109558105469 10.4999942779541 L 9.495099067687988 10.4999942779541 L 3.504899501800537 10.4999942779541 C 2.959449529647827 10.4999942779541 2.472419500350952 10.2171745300293 2.202089548110962 9.743434906005859 C 1.931749582290649 9.269684791564941 1.936019539833069 8.706504821777344 2.213499546051025 8.236905097961426 L 5.208609580993652 3.168264627456665 C 5.481269359588623 2.706844806671143 5.964029312133789 2.431364774703979 6.499999523162842 2.431364774703979 C 7.035969734191895 2.431364774703979 7.518729686737061 2.706844806671143 7.791389465332031 3.168264627456665 L 10.78649997711182 8.236905097961426 C 11.06394958496094 8.70644474029541 11.06818962097168 9.269615173339844 10.79784965515137 9.743374824523926 C 10.52749919891357 10.21714496612549 10.04049968719482 10.4999942779541 9.495109558105469 10.4999942779541 Z',
    x,
    y,
  });

  svg
    .selectAll('path')
    .data(triangles)
    .enter()
    .append('path')
    .attr('class', '1')
    .attr('d', (d) => d.d)
    .attr('fill', '#41a0fc')
    .attr('cx', (d) => d.x)
    .attr('cy', (d) => d.y)
    .attr('stroke', 'none')
    .attr('transform', (d) => `translate(${d.x + 2},${d.y - 6}) rotate(90)`);
}

export function drawLine(id, points) {
  const lineGenerator = d3.line();
  lineGenerator.curve(d3.curveLinear);
  const u = d3
    .select(id)
    .selectAll('path')
    .data([
      {
        name: 'curveStep',
        curve: d3.curveStep,
        active: true,
        lineString: lineGenerator(points.slice(0, points.length)),
        clear: true,
      },
    ]);

  u.enter()
    .append('path')
    .merge(u)
    .style('stroke-width', () => 2)
    .style('stroke', '#41a0fc')
    .attr('d', (d) => roundPathCorners(d.lineString, 4, false))
    .style('display', (d) => (d.active ? 'inline' : 'none'));

  return u;
}
