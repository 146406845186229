<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.iam.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('projects.iam.invite')" primary />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div v-for="user in users" :key="`${user.email}-${user.role}`">
          <ListCard class="flex-row mt-3">
            <div class="mr-4 truncate">{{ user.email }}</div>
            <Badge class="w-20" info :text="user.role"></Badge>
            <IconButton icon="edit" class="ml-auto text-primary" />
          </ListCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NoCodeIAM',
  computed: {
    ...mapState(['projects']),
    projectId() {
      return this.$route.params.id;
    },
    users() {
      const tenants = this.projects?.[this.projectId]?.tenants || {};
      return Object.values(tenants);
    },
  },
  methods: {
    ...mapActions(['fetchProjectData', 'showToastMessage']),
  },
  watch: {
    projectId: {
      handler() {
        this.fetchProjectData(this.$route.params.id);
      },
      immediate: true,
    },
  },
};
</script>
