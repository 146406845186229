<template>
  <div>
    <Input class="w-full" :modelValue="modelValue" @update:modelValue="$emit('update:modelValue', $event)" />
  </div>
</template>

<script>
export default {
  name: 'ExpressionEditor',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
    },
  },
};
</script>
