import Dexie from 'dexie';
import dayjs from 'dayjs';
import mitt from 'mitt';

const eventbus = mitt();

const conversationDBs = {};
const favouritesDBs = {};

export default {
  on(event, fn) {
    eventbus.on(event, fn);
  },
  off(event, fn) {
    eventbus.off(event, fn);
  },
  async createDatabases(org, types) {
    if (!(org in conversationDBs)) {
      conversationDBs[org] = new Dexie(`conversation_${org}`);
      favouritesDBs[org] = new Dexie(`favourites_${org}`);

      const schema = {};
      types.forEach((type) => {
        schema[type] = 'id';
      });
      const favourites = {};
      types.forEach((type) => {
        favourites[type] = 'phrase,count';
      });

      conversationDBs[org].version(3).stores(schema);
      favouritesDBs[org].version(3).stores(favourites);
      await conversationDBs[org].open();
      await favouritesDBs[org].open();
    }
  },
  async getById(org, type, id) {
    return conversationDBs[org][type].where('id').equals(id).toArray();
  },
  async getConversationHistory(org, type, start, total) {
    const dayZero = dayjs().startOf('date').valueOf();
    await conversationDBs[org][type].where('id').below(dayZero).delete();
    if (start) {
      return conversationDBs[org][type].where('id').belowOrEqual(start).limit(total).toArray();
    }
    return conversationDBs[org][type].orderBy('id').reverse().limit(total).toArray();
  },
  async removeFavouriteQuestion(org, type, phrase) {
    await favouritesDBs[org][type].delete(phrase);
    eventbus.emit('delete-question');
  },
  getFavouritesDb() {
    return favouritesDBs;
  },
  async addConversationHistory(org, type, query, queryData) {
    if (query.phrase) {
      const item = await favouritesDBs[org][type].get({ phrase: query.phrase });
      if (item) {
        favouritesDBs[org][type].update(query.phrase, { phrase: query.phrase, count: item.count + 1 });
      } else {
        favouritesDBs[org][type].add({ phrase: query.phrase, count: 1 });
      }
    }
    return conversationDBs[org][type].add({ id: query.created, query, queryData });
  },
  getFavouritesQuestions(org, type) {
    if (!favouritesDBs?.[org]?.[type]) return [];
    return favouritesDBs[org][type].orderBy('count').reverse().limit(4).toArray();
  },
  async deleteDatabase() {
    const r = await indexedDB.databases();
    /* eslint-disable no-await-in-loop,no-continue, no-restricted-syntax */
    for (const db of r) {
      if (db.name.startsWith('conversation')) {
        const [, org] = db.name.split(`conversation_`);
        if (!org) continue;
        await Dexie.delete(`conversation_${org}`);
      }
    }
  },
};
