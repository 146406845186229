<template>
  <transition name="slide-fade">
    <ul ref="dropdown" class="phrase-editor-dropdown-container absolute bg-white z-100 shadow-md-eq px-2 py-1 max-h-150 overflow-auto" :style="{ display: show ? 'inline' : 'none' }">
      <li
        class="px-3 py-1 rounded-4 cursor-pointer"
        :class="{ 'bg-gray-200': hoverIndex === index, 'bg-white': hoverIndex !== index }"
        v-for="(item, index) in filteredItems"
        :key="item.id"
        @mousedown.stop.prevent="onClick(index)"
        @hover="onHover(index)"
      >
        {{ item.value }}
      </li>
    </ul>
  </transition>
</template>

<script>
export default {
  name: 'PhraseEditorDropdown',
  props: {
    show: Boolean,
    value: String,
    items: Array,
  },
  data() {
    return {
      hoverIndex: null,
    };
  },
  computed: {
    filteredItems() {
      return this.items?.filter((item) => item.value.toLocaleLowerCase().includes(this.value?.toLocaleLowerCase())) || [];
    },
  },
  beforeUnmount() {
    const containers = document.getElementsByClassName('phrase-editor-dropdown-container');
    for (let i = 0; i < containers.length; i++) {
      containers[i].remove();
    }
  },
  methods: {
    onHover(id) {
      this.hoverIndex = id;
    },
    clearIndex() {
      this.hoverIndex = null;
    },
    decreaseIndex() {
      if (this.hoverIndex === null) {
        this.hoverIndex = 0;
        return;
      }
      this.hoverIndex = this.hoverIndex > 0 ? this.hoverIndex - 1 : 0;
      this.maybeAdjustScroll();
    },
    increaseIndex() {
      if (this.hoverIndex === null) {
        this.hoverIndex = 0;
        return;
      }
      this.hoverIndex = this.hoverIndex === this.items.length - 1 ? this.items.length - 1 : this.hoverIndex + 1;
      this.maybeAdjustScroll();
    },
    maybeAdjustScroll() {
      const pixelsToPointerTop = this.pixelsToPointerTop();
      const pixelsToPointerBottom = this.pixelsToPointerBottom();
      if (pixelsToPointerTop <= this.viewport().top) {
        this.scrollTo(pixelsToPointerTop);
      }
      if (pixelsToPointerBottom >= this.viewport().bottom) {
        this.scrollTo(this.viewport().top + this.pointerHeight());
      }
    },
    pixelsToPointerTop() {
      let pixelsToPointerTop = 0;
      if (this.$refs.dropdown) {
        for (let i = 0; i < this.hoverIndex; i++) {
          pixelsToPointerTop += this.$refs.dropdown.children[i].offsetHeight;
        }
      }
      return pixelsToPointerTop;
    },
    pixelsToPointerBottom() {
      return this.pixelsToPointerTop() + this.pointerHeight();
    },
    pointerHeight() {
      const element = this.$refs.dropdown ? this.$refs.dropdown.children[this.hoverIndex] : false;
      return element ? element.offsetHeight : 0;
    },
    viewport() {
      return {
        top: this.$refs.dropdown ? this.$refs.dropdown.scrollTop : 0,
        bottom: this.$refs.dropdown ? this.$refs.dropdown.offsetHeight + this.$refs.dropdown.scrollTop : 0,
      };
    },
    scrollTo(position) {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.scrollTop = position;
      }
    },
    onClick(index) {
      this.hoverIndex = index;
      this.$emit('click', this.filteredItems[this.hoverIndex]);
    },
    select() {
      if (this.hoverIndex !== null) {
        this.$emit('select', this.filteredItems[this.hoverIndex]);
      }
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
