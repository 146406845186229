<template>
  <div v-if="loaded" class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('conversations.ai_fine_tuning') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <VDropdown v-if="hasError" class="inline" placement="bottom" :triggers="['hover']">
            <Icon name="info" class="w-5 h-5 mr-4 mt-2 text-alert" />
            <template #popper>
              <div class="w-full max-w-300 p-2" v-html="errorDetailText"></div>
            </template>
          </VDropdown>

          <v-select
            v-model="filter"
            dense
            :reduce="(option) => option.value"
            :options="filterOptions"
            auto-select
            rounded
            elevated
            :clearable="false"
            :searchable="false"
            alternative-indicator
            class="mr-3 max-sm:w-[120px] w-200"
          />

          <v-select
            v-model="language"
            dense
            :options="selectLanguagesMobile"
            :reduce="(option) => option.value"
            auto-select
            rounded
            elevated
            :clearable="false"
            :searchable="false"
            alternative-indicator
            class="sm:hidden mr-3"
            @update:modelValue="onLanguageChange"
          />
          <v-select
            v-model="language"
            dense
            :options="selectLanguages"
            :reduce="(option) => option.value"
            auto-select
            rounded
            elevated
            :clearable="false"
            :searchable="false"
            alternative-indicator
            class="w-[120px] max-sm:hidden mr-3"
            @update:modelValue="onLanguageChange"
          />
          <PillButton :loading="saving" :disabled="saving || !dataFetched" :text="$t('save')" icon="save" text-class="max-sm:hidden" icon-class="max-sm:mr-0" primary @click="save" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10">
        <div class="h-full flex flex-col">
          <div class="flex flex-col relative overflow-hidden flex-grow">
            <div class="flex-grow overflow-auto border-b border-gray-300 border-solid pb-[300px]">
              <div class="w-full flex flex-wrap px-0.5">
                <FineTuneInput
                  v-for="(fineTuneItem, index) in fineTuneData"
                  :key="fineTuneItem.id"
                  ref="fine-tune-item"
                  v-model="fineTuneData[index]"
                  class="w-full xxl:w-1/2 px-2 mb-2 hidden items-start"
                  :show="getShowStatus(index)"
                  :class="{ '!flex': getShowStatus(index), 'bg-gray-100 outline outline-1 outline-gray-200': fineTuneItem.expand }"
                  @update:modelValue="addNewItem"
                  @newline="onNewLine($event, index)"
                  @remove="removeItem(index)"
                  @error="onErrorChange(fineTuneItem, index, $event)"
                  @focus="onInputFocus(index)"
                >
                </FineTuneInput>
              </div>
            </div>
            <div class="max-md:flex-col w-full flex justify-between px-2 sm:px-7 pt-3 items-center -mt-3 flex-shrink-0">
              <div class="flex flex-wrap items-center -mb-2 mt-3 max-md:justify-start pb-2">
                <span class="text-13 font-600 mb-2 mr-2">Legend:</span>
                <div v-for="legend in entityLegends" :key="legend.label" class="mr-2 rounded-4 px-1 py-0 text-12 text-white mb-2" :style="{ backgroundColor: legend.color }">{{ legend.label }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="loading">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('conversations.fetching_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import FineTuneInput from '@/components/conversation/FineTuneInput.vue';
import uuidv4 from '@/helpers/uuid';
import { apiGetFineTuneData, apiPostFineTuneData } from '@/helpers/api';
import debounce from '@/helpers/debounce';

export default {
  name: 'FineTune',
  components: { FineTuneInput },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      } else if (!vm.$store.state.modelsFetched) {
        await vm.$store.dispatch('fetchModels', { type: vm.$store.state.designTimeActiveDatasourceType });
      } else if (from.fullPath !== '/') {
        vm.fetchData();
      }
    });
  },
  data() {
    return {
      loading: false,
      fineTuneData: [{ p: '', d: [], l: null, t: 'tra', id: uuidv4() }],
      language: 'EN',
      filter: 'tra',
      filterOptions: [
        { label: 'Training', value: 'tra' },
        { label: 'Validation', value: 'val' },
        { label: 'Out of Scope Training', value: 'otra' },
        { label: 'Out of Scope Validation', value: 'oval' },
        { label: 'Excluded Subjects', value: 'exc' },
      ],
      loaded: false,
      saving: false,
      dataFetched: false,
      errors: {},
    };
  },
  created() {
    this.language = this.user?.language || 'EN';
    if (this.designTimeLanguage && this.languages.some((lang) => lang.value === this.designTimeLanguage)) {
      this.language = this.designTimeLanguage;
    } else if (!this.designTimeLanguage) {
      this.SET_DESIGN_TIME_LANGUAGE(this.language);
    }
    this.fetchData();
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'availableDataSources', 'user', 'bootstrapped', 'designTimeLanguage', 'org']),
    ...mapGetters(['getActiveDatasourceDataTypes']),
    languages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: lang }));
    },
    selectLanguages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: this.$t(`languages.${lang}`) }));
    },
    selectLanguagesMobile() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: lang }));
    },
    entityLegends() {
      return Object.keys(this.getActiveDatasourceDataTypes)
        .filter((entity) => !!this.getActiveDatasourceDataTypes[entity].colour)
        .map((entity) => {
          return {
            label: entity,
            color: this.getActiveDatasourceDataTypes[entity].colour,
          };
        });
    },
    hasError() {
      return !!Object.keys(this.errors).length;
    },
    errorDetailText() {
      const text = [];

      const errorItems = this.fineTuneData.filter((i) => Object.keys(this.errors).includes(i.id));
      const languages = errorItems.map((i) => i.l);

      const result = {};
      languages.forEach((lang) => {
        result[lang] = {};
        this.filterOptions.forEach((f) => {
          result[lang][f.value] = new Set();
        });
      });

      errorItems.forEach((errorItem) => {
        result[errorItem.l][errorItem.t].add(errorItem.p);
      });

      Object.keys(result).forEach((lang) => {
        const filter = result[lang];
        Object.keys(filter).forEach((f) => {
          if (filter[f].size) {
            const filterLabel = this.filterOptions.find((i) => i.value === f).label;
            text.push(`${result[lang][f].size} error(s) in ${filterLabel} set of ${lang}`);
          }
        });
      });
      return text.join('.<br />');
    },
    orgNameModelId() {
      return `${this.org}_${this.designTimeActiveDatasourceModelId}`;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchDesignTimeData']),
    ...mapMutations(['SET_DESIGN_TIME_LANGUAGE']),
    getShowStatus(index) {
      if (this.fineTuneData[index].l === this.language && this.fineTuneData[index].t === this.filter) {
        return true;
      }
      return false;
    },
    onLanguageChange(lang) {
      this.SET_DESIGN_TIME_LANGUAGE(lang);
    },
    onNewLine(phrases, index) {
      phrases.forEach((phrase, i) => {
        this.fineTuneData.splice(index + i + 1, 0, { p: phrase, d: [], l: this.language, t: this.filter, id: uuidv4() });
      });
    },
    addNewItem() {
      const items = this.fineTuneData.filter((i) => i.l === this.language && i.t === this.filter);
      const lastItem = items[items.length - 1];
      if (!items.length || !lastItem || lastItem?.p) {
        this.fineTuneData.push({ p: '', d: [], l: this.language, t: this.filter, id: uuidv4() });
      } else if (!lastItem?.p && lastItem.t !== this.filter) {
        lastItem.t = this.filter;
      }
    },
    removeItem(index) {
      this.fineTuneData.splice(index, 1);
      this.addNewItem();
    },
    onErrorChange(item, index, $event) {
      if ($event) {
        this.errors[item.id] = $event;
      } else {
        delete this.errors[item.id];
      }
    },
    onInputFocus(index) {
      this.$refs['fine-tune-item'].forEach((item) => {
        item.expand = false;
      });
      this.$refs['fine-tune-item'][index].expand = true;
    },
    async save() {
      this.saving = true;
      let data = JSON.parse(JSON.stringify(this.fineTuneData)).filter((d) => d.p);
      data = data.map((d) => {
        if (['exc', 'otra', 'oval'].includes(d.t)) {
          delete d.d;
        }
        delete d.id;
        return d;
      });

      try {
        const response = await apiPostFineTuneData({ data, type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('conversations.fine_tune_data_saved'), type: 'success' });
        } else {
          this.showToastMessage({ title: response.data.message || this.$t('conversations.failed_to_save_fine_tune_data'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: e.message || this.$t('conversations.failed_to_save_fine_tune_data'), type: 'error' });
      }
      this.saving = false;
    },
    // eslint-disable-next-line
    fetchData: debounce(async function () {
      this.loading = true;
      await this.fetchDesignTimeData();
      await this.fetchFineTuneData();
      this.loading = false;
    }, 300),
    async fetchFineTuneData() {
      try {
        this.loading = true;
        const response = await apiGetFineTuneData({ type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
        this.loading = false;
        this.dataFetched = true;

        if (response.status === 200) {
          this.fineTuneData = response.data;
          this.fineTuneData.map((item) => {
            if (!item.id) item.id = uuidv4();
            if (!item.l) item.l = null;
            if (!('t' in item)) item.t = true;
            return item;
          });
          this.addNewItem();
        } else {
          this.showToastMessage({ title: response.data.message || this.$t('conversations.failed_to_get_fine_tune_data'), type: 'error' });
        }
      } catch (e) {
        this.loading = false;
        this.showToastMessage({ title: e.message || this.$t('conversations.failed_to_get_fine_tune_data'), type: 'error' });
      }
    },
  },
  watch: {
    bootstrapped: {
      handler() {
        if (this.bootstrapped) {
          this.loaded = true;
        }
      },
      immediate: true,
    },
    orgNameModelId: {
      handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          if (this.languages.length && !this.languages.some((l) => l.value === this.language)) {
            this.language = this.languages[0].value;
          }
          this.fineTuneData = [];
          this.fetchData();
          this.errors = {};
        }
      },
      immediate: true,
    },
    language() {
      this.addNewItem();
    },
    filter() {
      this.addNewItem();
    },
  },
};
</script>
