<template>
  <div class="pb-2">
    <div class="max-h-full h-full">
      <div class="flex min-h-full h-full overflow-hidden">
        <div class="w-30 border-r border-solid border-gray-200 min-h-full flex-shrink-0">
          <div v-if="!hiddenTabs.includes('text')" class="text-primary font-700 px-3 py-2 cursor-pointer" :class="{ 'bg-primary bg-opacity-10': defaultTab === 'text' }" @click="defaultTab = 'text'">
            {{ $t('evaluate.text') }}
          </div>
          <div v-if="!hiddenTabs.includes('card')" class="text-primary font-700 px-3 py-2 cursor-pointer" :class="{ 'bg-primary bg-opacity-10': defaultTab === 'card' }" @click="defaultTab = 'card'">
            {{ $t('evaluate.card') }}
          </div>
          <div v-if="!hiddenTabs.includes('json')" class="text-primary font-700 px-3 py-2 cursor-pointer" :class="{ 'bg-primary bg-opacity-10': defaultTab === 'json' }" @click="defaultTab = 'json'">
            {{ $t('evaluate.json') }}
          </div>
        </div>
        <div class="flex-grow max-h-full overflow-auto py-3">
          <div v-if="defaultTab === 'text'" class="px-5">
            <div v-for="(textItem, index) in textItems" :key="textItem.id" class="flex items-center">
              <PhraseEditor v-model="textItem.text" :items="variables" class="mb-1 flex-grow" @update:modelValue="onTextPhraseChanged" />
              <IconButton class="ml-2 text-primary" :class="{ invisible: index + 1 === textItems.length && textItem.text === '' }" icon="delete" size="4" @click="onRemoveTextPhraseClicked(index)" />
            </div>
          </div>
          <div v-if="defaultTab === 'card'" class="px-5">
            <div class="mb-2">
              <PhraseEditor v-model="card.title" :items="variables" :label="$t('title')" class="mb-1 flex-grow" @update:modelValue="emitPayload" />
            </div>
            <div class="mb-2">
              <PhraseEditor v-model="card.text" :items="variables" :label="$t('evaluate.text')" class="mb-1 flex-grow" @update:modelValue="emitPayload" />
            </div>
            <div class="mb-2">
              <VariableSelect
                v-model="card.chart"
                multiple
                :label="$t('chart')"
                class="mb-1 flex-grow"
                selectBgColor="bg-white"
                :filter-data-type="['ChartConfig']"
                :showCreate="false"
                @update:modelValue="emitPayload"
              />
            </div>
            <div class="mb-2">
              <VariableSelect
                v-model="card.table"
                :label="$t('table')"
                class="mb-1 flex-grow"
                selectBgColor="bg-white"
                :filter-data-type="['DataTable']"
                :showCreate="false"
                @update:modelValue="emitPayload"
              />
            </div>
            <div class="mb-2">
              <VariableSelect v-model="card.insights" multiple :label="$t('insights')" class="mb-1 flex-grow" selectBgColor="bg-white" :showCreate="false" @update:modelValue="emitPayload" />
            </div>
            <div class="mb-2">
              <VariableSelect
                v-model="card.date"
                multiple
                :label="$t('dates')"
                :filter-data-type="['Date']"
                class="mb-1 flex-grow"
                :showCreate="false"
                selectBgColor="bg-white"
                @update:modelValue="emitPayload"
              />
            </div>
          </div>
          <div v-if="defaultTab === 'json'" class="px-5">
            <table class="w-full">
              <thead>
                <tr>
                  <th class="text-left border-b border-solid border-gray-200 pb-1">{{ $t('flow.property') }}</th>
                  <th class="text-left border-b border-solid border-gray-200 pb-1" width="160">{{ $t('flow.type') }}</th>
                  <th class="text-left border-b border-solid border-gray-200 pb-1" width="200">{{ $t('value') }}</th>
                  <th class="text-left border-b border-solid border-gray-200 pb-1" width="50"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(jsonItem, index) in jsonItems" :key="jsonItem.id">
                  <td>
                    <Input v-model="jsonItem.key" class="mb-1 mr-2 min-w-100 w-full" @blur="onJsonKeyChanged" />
                  </td>
                  <td>
                    <v-select
                      v-model="jsonItem.type"
                      :options="[
                        { value: 'Variable', label: 'Variable' },
                        { value: 'Text', label: 'Text' },
                      ]"
                      class="w-40 mb-1"
                      clearable
                      :searchable="false"
                      appendToBody
                      :reduce="(option) => option.value"
                      @option:selected="onJsonTypeSelected($event, jsonItem)"
                    />
                  </td>
                  <td>
                    <template v-if="jsonItem.type === 'Variable'">
                      <VariableSelect v-model="jsonItem.value" class="mb-1" @update:modelValue="onJsonVariableChanged" />
                    </template>
                    <template v-else-if="jsonItem.type === 'Text'">
                      <Input v-model="jsonItem.value" class="w-full" @update:modelValue="onJsonValueChanged" />
                    </template>
                  </td>
                  <td>
                    <IconButton
                      class="ml-2 text-primary"
                      :class="{ invisible: index + 1 === jsonItems.length && jsonItem.key === '' }"
                      icon="delete"
                      size="4"
                      @click="onRemoveJsonItemClicked(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from '@/helpers/debounce';
import uuidv4 from '@/helpers/uuid';
import PhraseEditor from '@/components/ui/PhraseEditor';

export default {
  name: 'EvaluateSetResponse',
  components: { PhraseEditor },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hiddenTabs: [],
      defaultTab: 'text',
      textItems: [],
      card: {
        title: '',
        text: '',
        image: {
          alt: '',
          url: '',
        },
        chart: [],
        table: '',
        insights: '',
        date: [],
      },
      jsonItems: [],
    };
  },
  created() {
    if (this.variableSelectorType === 'collection') {
      this.hiddenTabs = ['text', 'json'];
      this.defaultTab = 'card';
    }

    this.checkTextFieldsForNew();
    this.checkJsonFieldsForNew();
  },
  inject: {
    variableSelectorType: { default: 'conversation' },
  },
  computed: {
    ...mapState(['selectedIntentDefinition', 'projectData']),
    ...mapGetters(['getActiveDatasourceVariableList']),
    payload() {
      return {
        id: this.item.id,
        type: this.item.type,
        payload: this.responsePayload,
      };
    },
    responsePayload() {
      return {
        text: this.textItems.filter((s) => s.text).map((s) => s.text),
        card: this.card,
        json: this.jsonItems
          .filter((s) => s.key)
          .map((s) => {
            delete s.id;
            return s;
          }),
      };
    },
    projectId() {
      return this.$route.params.id;
    },
    variables() {
      if (this.variableSelectorType === 'no-code') {
        let variables = [];
        if (this.projectId && Object.keys(this.projectData).length) {
          variables = Object.keys(this.projectData?.[this.projectId]?.variables).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) || [];
        }
        variables = variables.map((variable) => ({
          id: uuidv4(),
          value: variable,
        }));
        return variables;
      }

      return this.getActiveDatasourceVariableList;
    },
  },
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.payload);
    }, 300),
    setInitialData(responseData) {
      this.textItems =
        responseData?.text?.map((i) => ({
          id: uuidv4(),
          text: i,
        })) || [];

      this.jsonItems =
        responseData?.json?.map((i) => ({
          id: uuidv4(),
          ...i,
        })) || [];

      if (responseData.card) {
        const { title, text, chart, table, insights, date } = responseData.card;

        this.card.title = title;
        this.card.text = text;
        this.card.chart = chart;
        this.card.table = table;
        this.card.insights = insights;
        this.card.date = date;
      }
    },
    selectReducer(option) {
      return option.value;
    },
    onTextPhraseChanged() {
      this.checkTextFieldsForNew();
      this.emitPayload();
    },
    onJsonKeyChanged() {
      this.checkJsonFieldsForNew();
      this.emitPayload();
    },
    onJsonVariableChanged() {
      this.checkJsonFieldsForNew();
      this.emitPayload();
    },
    onJsonValueChanged() {
      this.checkJsonFieldsForNew();
      this.emitPayload();
    },
    onRemoveTextPhraseClicked(index) {
      if (index >= 0) {
        this.textItems.splice(index, 1);
        this.emitPayload();
      }
    },
    onRemoveJsonItemClicked(index) {
      if (index >= 0) {
        this.jsonItems.splice(index, 1);
        this.emitPayload();
      }
    },
    checkTextFieldsForNew() {
      if (this.textItems?.length > 0) {
        if (this.textItems[this.textItems.length - 1].text !== '') {
          this.addResponseTextItem();
        }
      } else {
        this.addResponseTextItem();
      }
    },
    checkJsonFieldsForNew() {
      if (this.jsonItems?.length > 0) {
        if (this.jsonItems[this.jsonItems.length - 1].key !== '') {
          this.addJsonItem();
        }
      } else {
        this.addJsonItem();
      }
    },
    addResponseTextItem() {
      this.textItems.push({ id: uuidv4(), text: '' });
    },
    addJsonItem() {
      this.jsonItems.push({ id: uuidv4(), key: '', type: '', value: '' });
    },
    onJsonTypeSelected(e, jsonItem) {
      jsonItem.type = e.value;
      jsonItem.value = null;
      this.checkJsonFieldsForNew();
      this.emitPayload();
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.setInitialData(JSON.parse(JSON.stringify(this.item.payload)));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
