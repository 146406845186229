<template>
  <div id="flow-designer" class="grid w-full min-w-full min-h-full relative overflow-x-auto overflow-y-auto grabbable" @click="clearSelectedStepId()">
    <div id="flow-container" class="col-start-1 col-end-2 row-start-1 row-end-2 flow-container flex min-h-full flex-grow">
      <Container
        :data-dragging="dragging ? 'true' : 'false'"
        orientation="horizontal"
        @drop="onColumnDrop($event)"
        drag-handle-selector=".column-drag-handle"
        @drag-start="dragStart"
        @drag-end="dragEnd"
        :drop-placeholder="upperDropPlaceholderOptions"
      >
        <Draggable v-for="(column, index) in scene.children" :key="column.id" :class="{ 'pointer-events-none': !dragging }">
          <div class="flex flex-col px-4 py-4 border-dotted w-335 h-full" :class="{ 'pointer-events-none': !dragging }">
            <div v-if="false" class="column-drag-handle cursor-pointer invisible">
              <span class="text-gray-500 pointer-events-auto mr-2">&#x2630;</span>
              <span class="text-12 text-gray-500">Column {{ index + 1 }}</span>
            </div>
            <Container
              class="!min-h-300"
              group-name="col"
              @drop="(e) => onCardDrop(column.id, e)"
              @drag-start="dragStart"
              @drag-end="dragEnd"
              :get-child-payload="getCardPayload(column.id)"
              drag-class="card-ghost"
              drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions"
            >
              <component :is="getComponent(card)" v-for="card in column.children" :key="card.id" class="pointer-events-auto mt-6 !overflow-visible">
                <FlowStepItem
                  :id="`step-${card.step.id}`"
                  :step="card.step"
                  :steps="steps"
                  :selected="selectedIntentDefinitionStepId === card.step.id"
                  :style="{ order: card.step.order }"
                  @section="selectedSection = $event"
                  @click.stop="selectStep(card.step)"
                ></FlowStepItem>
              </component>
              <div
                class="bg-white shadow-lg-eq rounded-6 mt-6 mb-6 cursor-pointer select-none relative px-3 py-1 flex items-center justify-center opacity-75 h-15 pointer-events-auto"
                @click="addStepItem(index)"
              >
                <icon name="plus" class="w-5 h-5 opacity-50" />
                <div class="ml-1 text-13">{{ $t('flow.add_step') }}</div>
              </div>
            </Container>
          </div>
        </Draggable>
      </Container>
    </div>

    <FlowLines v-if="showLines" ref="flow-lines" class="col-start-1 col-end-2 row-start-1 row-end-2" :class="{ 'pointer-events-none': dragging }" :steps="steps" :stepsCols="stepsCols" />
  </div>
</template>

<script>
import { Container, Draggable } from 'vue3-smooth-dnd';
import { mapState, mapMutations } from 'vuex';
import FlowStepItem from '@/components/flow/FlowStepItem';
import FlowLines from '@/components/flow/FlowLines';

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

const $scene = {
  type: 'container',
  props: {
    orientation: 'horizontal',
  },
  children: [],
};

export default {
  name: 'FlowDesigner',
  components: { FlowLines, FlowStepItem, Container, Draggable },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      scene: $scene,
      selectedSection: null,
      showLines: true,
      dragging: false,
      upperDropPlaceholderOptions: {
        className: 'cards-drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinitionStepId']),
    colCount() {
      if (this.stepColumns?.length) {
        return Math.max(...this.stepColumns) + 1;
      }
      return 1;
    },
    stepColumns() {
      return this.steps?.map((o) => o.column) || [];
    },
    maxCol() {
      return this.colCount + 1;
    },
    stepsCols() {
      const cols = new Array(this.maxCol).fill(null);
      for (let i = 0; i < cols.length; i++) {
        cols[i] = this.steps.filter((f) => f.column === i) || [];
      }
      return cols;
    },
  },
  beforeUnmount() {
    this.clearSelectedStepId();
  },
  methods: {
    ...mapMutations(['SET_SELECTED_INTENT_DEFINITION_STEP_ID', 'SET_SELECTED_INTENT_DEFINITION_STEP_POSITION']),
    createScene() {
      const scene = {
        type: 'container',
        props: {
          orientation: 'horizontal',
        },
        children: [],
      };
      for (let i = 0; i <= this.colCount; i++) {
        let stepCols = this.steps.filter((f) => f.column === i) || [];
        stepCols = stepCols.sort((a, b) => a.order - b.order);

        scene.children.push({
          id: `column${i}`,
          type: 'container',
          props: {
            orientation: 'vertical',
            className: 'card-container',
          },
          children: stepCols.map((item) => ({ id: item.id, column: i, step: item })),
        });
      }
      this.scene = scene;
    },
    onColumnDrop(dropResult) {
      const scene = { ...this.scene };
      scene.children = applyDrag(scene.children, dropResult);
      this.scene = scene;
      this.updateStepData();
    },
    onCardDrop(columnId, dropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = { ...this.scene };
        const column = scene.children.filter((p) => p.id === columnId)[0];
        const columnIndex = scene.children.indexOf(column);

        const newColumn = { ...column };
        newColumn.children = applyDrag(newColumn.children, dropResult);
        scene.children.splice(columnIndex, 1, newColumn);

        this.scene = scene;
        this.updateStepData();
        this.$emit('dirty');
      }
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.scene.children.filter((p) => p.id === columnId)[0].children[index];
      };
    },
    getComponent(card) {
      return card.step.name === 'Start' ? 'div' : Draggable;
    },
    dragStart(e) {
      this.dragging = true;
      this.showLines = false;

      if (e?.payload?.id) {
        const element = document.getElementById(`step-${e.payload.id}`);
        element.parentElement.style.marginTop = '0px';
      }
    },
    dragEnd(e) {
      this.dragging = false;
      this.showLines = true;

      if (e?.payload?.id) {
        const element = document.getElementById(`step-${e.payload.id}`);
        element.parentElement.style.marginTop = '';
      }
    },
    addStepItem(index) {
      this.$emit('addStepItem', index);
    },
    updateFlowLines() {
      this.showLines = false;
      this.$nextTick().then(() => {
        this.showLines = true;
      });
    },
    selectStep(step) {
      if (step.name === 'Start') {
        this.$emit('startClick', step);
      } else {
        this.SET_SELECTED_INTENT_DEFINITION_STEP_ID(step.id);
      }
    },
    clearSelectedStepId() {
      this.SET_SELECTED_INTENT_DEFINITION_STEP_ID(null);
      this.selectedSection = null;
    },
    updateStepData() {
      this.scene.children.forEach((column, columnIndex) => {
        column.children.forEach((child, childIndex) => {
          this.SET_SELECTED_INTENT_DEFINITION_STEP_POSITION({ id: child.id, order: childIndex, column: columnIndex });
        });
      });

      this.updateFlowLines();
    },
  },
  watch: {
    steps: {
      handler() {
        this.createScene();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
<style>
.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
}

.smooth-dnd-container[data-dragging='false'] {
  pointer-events: none;
}
.smooth-dnd-drop-preview-constant-class {
  margin-top: 12px;
}

.smooth-dnd-ghost {
  margin-top: 0;
}
</style>
