<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.database.title') }}</div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div class="w-full flex flex-wrap">
          <div v-for="(database, index) in databases" :key="index" class="w-full lg:w-1/2" :class="{ 'lg:pr-2': index % 2 === 0, 'lg:pl-2': index % 2 === 1 }">
            <CollapseCard :value="true" :showIcon="false" :scroll="false" class="shadow-card mb-4">
              <template #header>
                <div class="flex justify-between items-center w-full flex-wrap -mt-3">
                  <div class="flex items-center mt-3">
                    <img :src="database.icon" class="w-8 h-8 mr-2" alt="datasource.name" />
                    <div class="flex items-center flex-wrap">
                      <span class="font-600 mr-2">{{ database.name }}</span>
                    </div>
                  </div>
                  <div class="mt-3">
                    <PillButton class="ml-3" outlined text="Connect" @click="onConnect(database)" />
                  </div>
                </div>
              </template>
              <template #content>
                <div>
                  <template v-if="database.connections">
                    <div v-for="(connection, connectionIndex) in database.connections" :key="connectionIndex" class="flex px-4 py-2 border-t border-solid border-gray-200 flex-grow">
                      <div class="flex-grow pt-1 flex items-center">{{ connection.name }}</div>
                      <IconButton icon="delete" class="text-primary" @click="onDeleteConnectionClick(connection)" />
                    </div>
                  </template>
                  <template v-else>
                    <div class="pt-3 px-3 pb-3">{{ $t('datastore.no_account_selected') }}</div>
                  </template>
                </div>
              </template>
            </CollapseCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mysql from '@/assets/png/mysql.png';
import mssql from '@/assets/png/mssql.png';
import sqlite from '@/assets/png/sqlite.png';
import postgresql from '@/assets/png/postgresql.svg';

export default {
  name: 'Database',
  data() {
    return {
      databases: [
        {
          name: 'MySQL',
          icon: mysql,
        },
        {
          name: 'Microsoft SQL Server',
          icon: mssql,
        },
        {
          name: 'SQLite',
          icon: sqlite,
        },
        {
          name: 'PostgreSQL',
          icon: postgresql,
        },
      ],
    };
  },
  methods: {
    onConnect() {},
    onDeleteConnectionClick() {},
  },
};
</script>
