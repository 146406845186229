<template>
  <Modal
    fullbelowsm
    :title="$t('datastore.select_account')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :secondary-button-disabled="saveAccountLoading"
    :primary-button-loading="saveAccountLoading"
    :primary-button-disabled="saveAccountLoading || id === null"
    @primary="onSaveClicked"
    @secondary="onCloseClicked"
    @close="onCloseClicked"
  >
    <div class="flex flex-col justify-between h-full max-sm:w-full sm:min-w-400">
      <div class="relative flex-1 sm:flex-none sm:h-60 overflow-hidden ml-3 mr-1 px-10 pb-7">
        <div class="absolute inset-0 w-full sm:h-60 flex flex-col items-start justify-start transition-all ease-in-out duration-1000 transform slide overflow-y-auto pr-1">
          <div class="flex flex-col font-700 items-center justify-center w-full h-full mb-5" v-if="loading">
            <div>{{ $t('datastore.fetching_accounts') }}</div>
            <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
          </div>

          <div v-if="!loading && !saveAccountLoading" class="w-full p-3">
            <ListCard v-for="item in accounts" :key="item.id" class="flex-row h-12 cursor-pointer justify-between flex-shrink-0 mb-3 rounded-6 w-full" @click="onAccountSelected(item)">
              <div class="flex flex-row items-center flex-nowrap">
                <div class="flex flex-col items-start justify-center w-full">
                  <div class="text-14 font-700">{{ item.name }}</div>
                </div>
              </div>
              <div class="flex flex-col xs:flex-row justify-end">
                <RadioButton v-model="id" :value="item.id" name="tenantid" />
              </div>
            </ListCard>
            <ListCard v-if="accounts.length === 0" class="flex-row h-12 cursor-default justify-center flex-shrink-0 mb-3 rounded-6 w-full">
              <div class="flex flex-row items-center flex-nowrap">
                <div class="flex flex-col items-start justify-center w-full">
                  <div class="text-14 sm:text-18 font-700 truncate">{{ $t('datastore.no_account_available') }}</div>
                </div>
              </div>
            </ListCard>
          </div>
          <div class="flex flex-col font-700 items-center justify-center my-5 w-full h-full mb-5" v-if="saveAccountLoading">
            <div>{{ $t('datastore.saving_account') }}</div>
            <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PropertySelector',
  props: {
    datasourceId: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  emits: ['close'],
  data() {
    return {
      accounts: [],
      loading: false,
      id: null,
      name: null,
      saveAccountLoading: null,
    };
  },
  created() {
    this.onDatasourceIdSelected();
  },
  methods: {
    ...mapActions(['addAccountToDatasource', 'fetchDatasourceAccounts']),
    async onDatasourceIdSelected() {
      this.loading = true;
      this.id = null;
      this.name = null;

      this.loading = true;
      try {
        this.accounts = (await this.fetchDatasourceAccounts({ type: this.type, datasource_id: this.datasourceId })) || [];
      } catch (e) {
        this.accounts = [];
      }
      this.loading = false;
    },
    onAccountSelected(item) {
      this.id = item.id;
      this.name = item.name;
    },
    onCloseClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      this.saveAccountLoading = true;
      try {
        const response = await this.addAccountToDatasource({
          type: this.type,
          datasource_id: this.datasourceId,
          account: {
            id: this.id,
            name: this.name,
            type: this.type,
            datasource_id: this.datasourceId,
          },
        });
        this.saveAccountLoading = false;
        if (response) {
          this.$emit('close');
        }
      } catch (e) {
        this.saveAccountLoading = false;
      }
    },
  },
  computed: {
    ...mapState(['data_sources']),
    userAccounts() {
      let accounts = [];
      Object.values(this.data_sources[this.type]).forEach((item) => {
        accounts = [...accounts, ...Object.values(item.accounts)];
      });
      return accounts;
    },
    datasource() {
      const datasource = this.data_sources[this.type];
      if (datasource && datasource.datasource_id && datasource.active) {
        if (datasource.datasource_id && datasource.active) {
          return datasource;
        }
      }
      return {};
    },
  },
};
</script>
