<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.secrets.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div v-if="!secrets.length" class="text-center text-gray-800">{{ $t('projects.secrets.no_secret') }}</div>
        <ListCard v-for="secretName in secrets" :key="secretName" class="flex-row mt-3">
          <div class="font-500 truncate">{{ secretName }}</div>
          <div class="italic truncate ml-auto pr-1 sm:mr-10">{{ $t('projects.secrets.value_encrypted') }}</div>

          <div class="flex flex-row justify-end items-center">
            <IconButton icon="edit" class="sm:mr-1 text-primary" @click="onEditClicked(secretName)" />
            <IconButton icon="delete" class="text-primary" @click="onDeleteClicked(secretName)" :text="$t('delete')" />
          </div>
        </ListCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { VARIABLE_TYPE } from '@/constants';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import CreateProjectSecret from '@/components/no-code/CreateProjectSecret';

export default {
  name: 'NoCodeSecrets',
  data() {
    return {
      VARIABLE_TYPE,
    };
  },
  computed: {
    ...mapState(['user', 'projectData', 'activeDatasourceType', 'data_sources']),
    secrets() {
      if (this.$route.params?.id && Object.keys(this.projectData).length) {
        return Object.keys(this.projectData?.[this.$route.params.id]?.secrets).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) || [];
      }
      return [];
    },
    projectId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.fetchProjectData(this.projectId);
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectData', 'deleteProjectSecret']),
    onCreateClicked() {
      const modal = this.$showModal(
        CreateProjectSecret,
        {
          projectId: this.projectId,
        },
        {
          save: () => this.onSecretSaved(modal),
        },
      );
    },
    onEditClicked(secretName) {
      const modal = this.$showModal(
        CreateProjectSecret,
        {
          projectId: this.projectId,
          secretName,
        },
        {
          save: () => this.onSecretSaved(modal),
        },
      );
    },
    onSecretSaved(modal) {
      this.fetchProjectData(this.projectId);
      modal.close();
    },
    onDeleteClicked(secretName) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.remove_secret', { secretName }),
        info: null,
        onConfirm: async () => {
          await this.deleteProjectSecret({ name: secretName, project_id: this.projectId });
          this.showToastMessage({ message: this.$t('projects.secrets.removed_successfully'), type: 'success' });
        },
      });
    },
  },
};
</script>
