<template>
  <ValidationObserver v-slot="{ errors, validate }">
    <Modal
      sheetbelowsm
      :title="$t('projects.endpoints.create')"
      :primary-button="$t('save')"
      :secondary-button="$t('cancel')"
      :primary-button-disabled="loading"
      :primary-button-loading="loading"
      close-button
      @primary="onSaveClick(validate)"
      @secondary="onCancelClick"
      @close="onCancelClick"
    >
      <div class="w-full sm:w-700 px-10">
        <div class="w-full flex flex-col justify-start mt-5">
          <FormSelect
            v-model="method"
            :options="methods"
            name="method"
            :error="errors.method"
            rules="required"
            :label="$t('projects.endpoints.method')"
            :reduce="(option) => option.value"
            :searchable="false"
            :clearable="false"
          ></FormSelect>
        </div>
        <div class="w-full flex flex-col justify-start py-2">
          <FormInput :readonly="loading" v-model="path" name="path" :error="errors.path" :rules="['required', { validate: pathValidation }]" :label="$t('projects.endpoints.path')"></FormInput>
        </div>
        <div class="w-full flex flex-col justify-start py-2">
          <FormInput :readonly="loading" v-model="groupName" name="groupName" :error="errors.groupName" rules="required" :label="$t('projects.endpoints.group_name')"></FormInput>
        </div>
      </div>
    </Modal>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import FormSelect from '@/components/ui/FormSelect.vue';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';
import FormInput from '@/components/ui/FormInput.vue';

export default {
  name: 'CreateProjectEndpointModal',
  components: { FormInput, ValidationObserver, FormSelect },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      name: '',
      nameError: '',
      method: '',
      methodError: '',
      methods: [
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'DELETE', value: 'DELETE' },
      ],
      path: '',
      groupName: '',
      groupNameError: '',
    };
  },
  methods: {
    ...mapActions(['createProjectEndpoint', 'showToastMessage']),
    async onSaveClick(validate) {
      if (validate) {
        const valid = await validate();
        if (!valid.valid) return;
      }
      this.loading = true;
      const res = await this.createProjectEndpoint({ project_id: this.projectId, method: this.method, path: this.path, group_name: this.groupName, steps: [] });
      if (res) {
        this.showToastMessage({ message: this.$t('projects.endpoints.added_successfully'), type: 'success' });
      } else {
        this.showToastMessage({ title: this.$t('projects.endpoints.failed_to_add'), type: 'error' });
      }
      this.loading = false;
      this.$emit('close');
    },
    onCancelClick() {
      this.$emit('close');
    },
    pathValidation() {
      return this.path.startsWith('/') || this.$t('projects.endpoints.path_starts_slashes');
    },
  },
};
</script>

<style scoped></style>
