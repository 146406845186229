<template>
  <Modal :title="$t('datastore.select_data_source')" fullbelowsm close-button @close="onClose">
    <div class="sm:w-600 flex py-7 px-10 flex-wrap">
      <div v-for="(ds, dataSource) in availableDataSources" :key="dataSource" class="w-full sm:w-1/2" @click="onDataSourceClick(dataSource)">
        <div
          class="shadow-card rounded-8 m-5 p-4 sm:p-5 flex sm:flex-col items-center justify-center"
          :class="{ 'cursor-pointer': !hasDataSource[dataSource], 'opacity-70': hasDataSource[dataSource] }"
        >
          <img v-if="availableDataSources[dataSource]" :src="availableDataSources[dataSource].logo" class="w-10 max-sm:mr-5" :alt="ds.name" />
          <div class="sm:text-center w-full">
            <div class="text-17 font-600 truncate h-full mt-3">{{ ds.name }}</div>
            <div :class="{ invisible: !hasDataSource[dataSource] }" class="text-10">{{ $t('datastore.already_connected') }}</div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import PropertySelector from '@/components/datasource/PropertySelector.vue';

export default {
  name: 'AddDataSourceModal',
  props: {
    type: String,
  },
  emits: ['close'],
  computed: {
    ...mapState(['availableDataSources', 'data_sources']),
    hasDataSource() {
      const ds = {};
      Object.keys(this.data_sources).forEach((type) => {
        ds[type] = Object.values(this.data_sources[type]).some((emailItem) => emailItem.active === true);
      });
      return ds;
    },
  },
  created() {
    if (this.type) {
      this.onDataSourceClick(this.type);
    }
  },
  methods: {
    ...mapActions(['oAuthRedirect']),
    async onDataSourceClick(type) {
      if (!this.hasDataSource[type]) {
        await this.oAuthRedirect({
          type,
          // onSuccess: () => {
          //   if (this.availableDataSources?.[type]?.accounts && this.data_sources?.[type]) {
          //     const datasource_id = Object.keys(this.data_sources[type])?.[0];

          //     if (datasource_id) {
          //       this.$showModal(PropertySelector, {
          //         datasourceId: datasource_id,
          //         type,
          //       });
          //     }
          //   }
          // },
        });
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
