<template>
  <Modal title="Edit User" close-button overflowContainerClass="-mb-5" :secondary-button="$t('close')" @close="close" @secondary="close">
    <div class="w-900 min-h-500 px-10 pt-6 relative overflow-auto">
      <div v-if="!tenant" class="flex flex-col font-700 items-center justify-center w-full h-full min-h-[450px] mb-5">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>

      <div v-else>
        <Tabs v-model="tab" dense :items="tabsArray" class="mb-5" />

        <ValidationObserver v-slot="{ validate, errors }" class="w-full">
          <div v-if="tab === TABS.PROFILE">
            <div class="w-full flex mb-3">
              <FormInput v-model="tenant.user.name" name="name" rules="required" :error="errors['name']" :label="$t('name')" class="w-full md:w-1/2 md:mr-2" />
              <FormInput v-model="tenant.user.email" name="email" rules="required|email" :error="errors['email']" :label="$t('email')" class="w-full md:w-1/2 md:ml-2" />
            </div>
            <div v-if="false" class="w-full flex mb-3">
              <FormInput v-model="tenant.user.organisation" :label="$t('login.organisation')" class="w-full md:w-1/2 md:mr-2" />
              <FormInput v-model="tenant.user.position" :label="$t('login.position')" class="w-full md:w-1/2 md:ml-2" />
            </div>
            <div class="w-full flex mb-3">
              <FormSelect
                :label="$t('login.country')"
                name="country"
                rules="required"
                :error="errors['country']"
                v-model="tenant.user.country"
                class="w-full md:w-1/2 md:mr-2"
                :options="countries"
                :clearable="false"
                :searchable="true"
                :reduce="(option) => option.value"
                appendToBody
              ></FormSelect>
              <FormSelect
                :label="$t('timezone')"
                name="timezone"
                rules="required"
                :error="errors['timezone']"
                v-model="tenant.timezone"
                class="w-full md:w-1/2 md:ml-2"
                :options="TIMEZONES"
                searchable
                :clearable="false"
                appendToBody
              ></FormSelect>
            </div>
            <div class="w-full mb-3">
              <FormSelect
                :label="$t('language')"
                name="language"
                rules="required"
                :error="errors['language']"
                v-model="tenant.user.locale"
                :options="languages"
                :clearable="false"
                :reduce="(option) => option.value"
                appendToBody
              ></FormSelect>
            </div>
            <div class="w-full mb-3">
              <FormSelect
                v-model="tenant.organisations"
                :options="organisationList"
                multiple
                append-to-body
                name="organisations"
                :rules="['required']"
                :error="errors && errors.organisations"
                class="w-full"
                :label="$t('organisation.organisation')"
              />
            </div>
            <div class="w-full mb-3 pt-2 flex flex-col items-start">
              <Checkbox v-model="tenant.flags.rc" :label="$t('flags.rc')" class="mb-3 !text-13"></Checkbox>
              <Checkbox v-model="tenant.flags.oc" :label="$t('flags.oc')" class="mb-3 !text-13"></Checkbox>
            </div>
            <div class="w-full flex justify-end pt-1">
              <PillButton :text="$t('update_profile')" :loading="profileUpdating" :disabled="profileUpdating" outlined @click="updateProfile(validate)" />
            </div>
          </div>
        </ValidationObserver>

        <div v-if="tab === TABS.DATA_SOURCES">
          <CollapseCard v-for="(dataSource, index) in dataSources" :key="index" :value="true" :showIcon="false" :scroll="false" class="shadow-card mb-4">
            <template #header>
              <div class="flex justify-between w-full flex-wrap -mt-3">
                <div class="flex items-center mt-3">
                  <img :src="dataSource.logo" class="w-8 h-8 mr-2" :alt="dataSource.name" />
                  <div class="flex items-center flex-wrap">
                    <span class="font-600 mr-2">{{ dataSource.name }}</span>
                  </div>
                </div>

                <div class="flex items-center justify-between flex-grow mt-3">
                  <div v-if="dataSource.type in tenantDataSources" class="flex">
                    <Badge v-if="getTenantDatasourceActive(dataSource.type)" :text="$t('datastore.active')" success class="ml-2" />
                    <Badge v-else :text="$t('datastore.inactive')" warn class="ml-2" />
                  </div>
                  <div class="ml-auto">
                    <PillButton
                      v-if="!(dataSource.type in tenantDataSources)"
                      outlined
                      :text="selectedDataSourceType ? $t('cancel') : $t('datastore.connect')"
                      class="ml-2"
                      @click.stop="onAddAccountClick(dataSource.type)"
                    />
                    <PillButton
                      v-if="dataSource.type in tenantDataSources && getTenantDatasourceActive(dataSource.type)"
                      error
                      :text="$t('datastore.deactivate')"
                      class="ml-2"
                      @click.stop="onDeactivateClick(dataSource)"
                    />
                    <PillButton
                      v-if="dataSource.type in tenantDataSources && !getTenantDatasourceActive(dataSource.type)"
                      outlined
                      :text="$t('datastore.activate')"
                      class="ml-2"
                      @click.stop="onActivateClick(dataSource)"
                    />
                    <PillButton
                      v-if="dataSource.type in tenantDataSources && !getTenantDatasourceActive(dataSource.type)"
                      error
                      :text="$t('delete')"
                      class="ml-2"
                      @click.stop="onDeleteClick(dataSource)"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #content>
              <div class="px-4 pb-4">
                <template v-if="dataSource.type in tenantDataSources">
                  <div v-for="(account, accountIndex) in getDataSourceAccounts(dataSource.type)" :key="accountIndex" class="flex px-4 py-2 border-b border-solid border-gray-200 flex-grow">
                    <div class="pt-1">{{ account.name }}</div>
                    <div class="pt-1 font-600 mx-3">({{ account.org }})</div>
                    <IconButton icon="edit" class="ml-auto text-primary" @click="onEditAccountClick(account, dataSource.type)" />
                    <IconButton icon="delete" class="text-primary" @click="onDeleteAccountClick(account, dataSource.type)" />
                  </div>
                </template>
                <template v-else>
                  <div class="pt-3 px-3">{{ $t('datastore.no_account_selected') }}</div>
                </template>

                <div v-if="dataSource.type in tenantDataSources" class="flex px-4 py-4 pb-0 flex-grow">
                  <div class="flex-grow pt-1"></div>
                  <PillButton class="ml-3" outlined :text="$t('datastore.add_account')" @click="onAddAccountClick(dataSource.type)" />
                </div>
              </div>
            </template>
          </CollapseCard>

          <div
            v-if="selectedDataSourceType"
            class="absolute right-0 top-[61px] -bottom-5 w-360 overflow-auto px-4 bg-white border-t border-l border-solid border-gray-200"
            style="height: calc(100% - 64px)"
          >
            <div v-if="accountDetailsFetching">
              <div class="flex-grow flex items-center justify-center h-200">
                <Spinner class="w-8" />
              </div>
            </div>
            <template v-else>
              <div class="font-600 mt-4 mb-4">{{ $t('datastore.add_account') }} ({{ selectedOrg }})</div>
              <div class="font-600 text-12 pt-2 mb-1">{{ $t('account') }}</div>
              <FormInput :label="$t('datastore.name')" v-model="dataSourceName" :error="dataSourceNameError" type="text" class="mb-3" @update:modelValue="dataSourceNameError = ''" />

              <div class="font-600 text-12 pt-2 mb-1">{{ $t('datastore.connection_properties') }}</div>
              <template v-for="(value, property) in properties">
                <FormSelect
                  v-if="property === 'MODEL_ID'"
                  :label="property"
                  :key="property"
                  v-model="properties[property]"
                  :options="getModels"
                  :reduce="(opt) => opt.value"
                  type="text"
                  class="mb-3"
                />
                <FormInput
                  v-else-if="availableDataSources[selectedDataSourceType].properties[property].type === 'string'"
                  :label="property"
                  :key="`i-${property}`"
                  v-model="properties[property]"
                  type="text"
                  class="mb-3"
                />
              </template>
              <div class="flex justify-between pb-3">
                <PillButton :text="$t('cancel')" outlined @click="onCancelClick"></PillButton>
                <PillButton
                  v-if="!(selectedDataSourceType in tenantDataSources)"
                  :loading="dataSourceUpdating"
                  :text="$t('datastore.connect')"
                  primary
                  @click="onConnectClick(selectedDataSourceType)"
                ></PillButton>
                <PillButton
                  v-if="selectedDataSourceType in tenantDataSources"
                  :loading="dataSourceUpdating"
                  :text="selectedAccountId ? $t('update') : $t('add')"
                  primary
                  @click="onUpdateClick(selectedDataSourceType)"
                ></PillButton>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Tabs from '@/components/ui/Tabs.vue';
import { apiDelUserDataSource, apiDelUserDataSourceAccount, apiGetUser, apiPostUserDataSource, apiPutUser, apiPutUserDataSource, apiPutUserDataSourceAccount } from '@/helpers/api';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';
import { languages } from '@/plugins/i18n';
import { COUNTRIES, TIMEZONES } from '@/constants';
import { mapActions, mapState } from 'vuex';
import DeleteModal from '@/components/shared-modals/DeleteModal.vue';

export default {
  name: 'EditUserModal',
  components: { ValidationObserver, Tabs },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      tenant: null,
      TIMEZONES,
      countries: COUNTRIES.map((c) => ({ label: c.name, value: c.name })),
      profileUpdating: false,
      expandedDataSource: null,
      showDataSourcePropertyForm: false,
      dataSourceUpdating: false,
      dataSourceName: '',
      dataSourceNameError: '',
      selectedDataSourceType: null,
      accountDetailsFetching: false,
      selectedAccountId: null,
      selectedOrg: null,
      organisations: [],
      properties: {},
    };
  },
  computed: {
    ...mapState(['availableDataSources', 'designTimeActiveDatasourceType', 'models', 'org']),
    organisationList() {
      return this.organisations.filter((item) => !this.tenant.organisations.some((i) => i.value === item.value));
    },
    getModels() {
      return this.models.map((model) => {
        return { label: model.name, value: model.model_id };
      });
    },
    TABS() {
      return {
        PROFILE: this.$t('projects.iam.profile'),
        DATA_SOURCES: this.$t('projects.iam.data_sources'),
      };
    },
    languages() {
      return languages.map((lang) => ({ label: this.$t(`languages_alt.${lang}`), value: lang }));
    },
    tabsArray() {
      return [this.TABS.PROFILE, this.TABS.DATA_SOURCES];
    },
    tenantDataSources() {
      return this.tenant.data_sources;
    },
    dataSources() {
      const dataSources = [];
      Object.keys(this.availableDataSources).forEach((type) => {
        if (this.availableDataSources[type].accounts === false && Object.keys(this.availableDataSources[type].properties).length) {
          dataSources.push(this.availableDataSources[type]);
        }
      });
      return dataSources;
    },
  },
  async created() {
    this.tab = this.tabsArray[0];
    // TODO organisation list should come from user organisations. This will be changed once SUPERADMIN role is impelemented.
    this.organisations = await this.fetchOrganisations();
    this.getUserData();
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchModels', 'fetchModels', 'fetchOrganisations']),
    getDataSourceAccounts(type) {
      const dataSourceObject = this.tenantDataSources[type];
      return (Object.values(dataSourceObject)?.[0] || {})?.accounts || [];
    },
    async onEditAccountClick(account, type) {
      this.accountDetailsFetching = true;
      this.selectedDataSourceType = type;
      this.selectedAccountId = account.account_id;
      const tenantProperties = account.properties;
      this.dataSourceName = account.name;
      this.selectedOrg = account.org;

      await this.fetchModels({ type });

      Object.keys(this.availableDataSources[type].properties).forEach((property) => {
        if (tenantProperties && property in tenantProperties) {
          this.properties[property] = tenantProperties[property];
        } else {
          this.properties[property] = null;
        }
      });
      this.accountDetailsFetching = false;
    },
    async onDeleteAccountClick(account, dataSource) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('datastore.confirm_remove_account_message'),
        onConfirm: () => this.onDeleteAccountConfirm(account, dataSource),
      });
    },
    async onDeleteAccountConfirm(account, dataSource) {
      try {
        const res = await apiDelUserDataSourceAccount({ tenant_id: this.user.tenant_id, account_id: account.account_id, datasource_id: account.datasource_id, type: dataSource });
        if (res.status === 200) {
          this.tenant = res.data;
        } else {
          this.showToastMessage({ title: res?.data?.message || this.$t('datastore.failed_to_remove_account'), type: 'error' });
          this.$emit('close');
        }
      } catch {
        this.showToastMessage({ title: this.$t('datastore.failed_to_remove_account'), type: 'error' });
      }
    },
    onAddAccountClick(type) {
      this.showDataSourcePropertyForm = true;
      this.selectedDataSourceType = type;
      this.selectedAccountId = null;
      this.setTenantProperties(type);
      this.dataSourceName = null;
      this.selectedOrg = this.org;
      this.fetchModels({ type });
    },
    async getUserData() {
      try {
        const res = await apiGetUser({ tenant_id: this.user.tenant_id });
        if (res.status === 200) {
          res.data.organisations = res.data.organisations.map((org) => {
            const item = this.organisations.find((o) => o.value === org);
            return {
              label: item.label,
              value: item.value,
            };
          });
          this.tenant = res.data;
        } else {
          this.showToastMessage({ title: res?.data?.message || this.$t('projects.iam.failed_to_get_user'), type: 'error' });
          this.$emit('close');
        }
      } catch {
        this.showToastMessage({ title: this.$t('projects.iam.failed_to_get_user'), type: 'error' });
      }
    },
    setTenantProperties(type) {
      if (type) {
        const tenantProperties = Object.values(this.tenant.data_sources?.[type] || {})?.[0]?.properties;
        const tenantAccounts = Object.values(this.tenant.data_sources?.[type] || {})?.[0]?.accounts || {};
        const tenantAccountName = Object.values(tenantAccounts)?.[0]?.name;
        this.dataSourceName = tenantAccountName;

        Object.keys(this.availableDataSources[type].properties).forEach((property) => {
          if (tenantProperties && property in tenantProperties) {
            this.properties[property] = tenantProperties[property];
          } else {
            this.properties[property] = null;
          }
        });
      } else {
        this.dataSourceName = '';
        this.properties = {};
      }
    },
    onCancelClick() {
      this.selectedAccountId = null;
      this.selectedDataSourceType = null;
      this.dataSourceNameError = null;
      this.setTenantProperties(null);
    },
    async onConnectClick(dataSource) {
      this.dataSourceNameError = '';
      if (!this.dataSourceName.trim()) {
        this.dataSourceNameError = this.$t('validation.required');
        return;
      }
      this.dataSourceUpdating = true;

      try {
        const response = await apiPostUserDataSource({ tenant_id: this.user.tenant_id, type: dataSource, active: true, properties: this.properties, name: this.dataSourceName });
        if (response.status === 200) {
          this.tenant = response.data;
          this.showToastMessage({ message: this.$t('datastore.account_connected_successfully'), type: 'success' });

          this.selectedAccountId = null;
          this.selectedDataSourceType = null;
          this.dataSourceNameError = null;
          this.setTenantProperties(null);
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('datastore.failed_to_connect_account'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('datastore.failed_to_connect_account'), type: 'error' });
      }

      this.dataSourceUpdating = false;
    },
    async onUpdateClick(dataSource) {
      this.dataSourceNameError = '';
      if (!this.dataSourceName?.trim()) {
        this.dataSourceNameError = this.$t('validation.required');
        return;
      }
      this.dataSourceUpdating = true;
      try {
        const dataSourceId = Object.keys(this.tenant.data_sources?.[dataSource] || [])?.[0];

        const response = await apiPutUserDataSourceAccount({
          tenant_id: this.user.tenant_id,
          type: dataSource,
          properties: this.properties,
          name: this.dataSourceName,
          account_id: this.selectedAccountId,
          datasource_id: dataSourceId,
        });

        if (response.status === 200) {
          this.tenant = response.data;
          this.showToastMessage({ message: this.$t('datastore.saved_successfully'), type: 'success' });
          this.selectedDataSourceType = null;
          this.selectedAccountId = null;
          this.setTenantProperties();
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('datastore.failed_to_update_account'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('datastore.failed_to_update_account'), type: 'error' });
      }
      this.dataSourceUpdating = false;
    },
    getTenantDatasourceActive(type) {
      if (type in this.tenantDataSources) {
        const [dataSource] = Object.values(this.tenantDataSources[type]);
        return dataSource.active;
      }
      return false;
    },
    async onDeactivateClick(dataSource) {
      try {
        const datasourceId = Object.keys(this.tenantDataSources[dataSource.type])[0];
        const response = await apiPutUserDataSource({ tenant_id: this.user.tenant_id, type: dataSource.type, datasource_id: datasourceId, active: false });

        if (response.status === 200) {
          this.tenant = response.data;
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('datastore.failed_to_deactivate_account'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('datastore.failed_to_deactivate_account'), type: 'error' });
      }
    },
    async onActivateClick(dataSource) {
      try {
        const datasourceId = Object.keys(this.tenantDataSources[dataSource.type])[0];
        const response = await apiPutUserDataSource({ tenant_id: this.user.tenant_id, type: dataSource.type, datasource_id: datasourceId, active: true });

        if (response.status === 200) {
          this.tenant = response.data;
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('datastore.failed_to_deactivate_account'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('datastore.failed_to_deactivate_account'), type: 'error' });
      }
    },
    async onDeleteClick(dataSource) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('datastore.confirm_remove_account_message'),
        onConfirm: () => this.onDeleteConfirm(dataSource),
      });
    },
    async onDeleteConfirm(dataSource) {
      const datasourceId = Object.keys(this.tenantDataSources[dataSource.type])[0];

      try {
        const response = await apiDelUserDataSource({ tenant_id: this.user.tenant_id, type: dataSource.type, datasource_id: datasourceId });

        if (response.status === 200) {
          this.showDataSourcePropertyForm = false;
          this.expandedDataSource = null;
          this.tenant = response.data;
          this.showToastMessage({ message: this.$t('datastore.removed_successfully'), type: 'success' });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('datastore.failed_to_remove_account'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('datastore.failed_to_remove_account'), type: 'error' });
      }
    },
    async updateProfile(validate) {
      const valid = await validate();

      if (!valid.valid) {
        return;
      }
      this.profileUpdating = true;

      try {
        const response = await apiPutUser({
          tenant_id: this.user.tenant_id,
          name: this.tenant.user.name,
          timezone: this.tenant.timezone,
          country: this.tenant.user.country,
          organisation: this.tenant.user.organisation,
          position: this.tenant.user.position,
          language: this.tenant.user.locale,
          flags: this.tenant.flags,
          organisations: this.tenant.organisations.map((o) => o.value),
        });

        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('projects.iam.updated_successfully'), type: 'success' });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.iam.failed_to_update_user'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('projects.iam.failed_to_update_user'), type: 'error' });
      }
      this.profileUpdating = false;
    },
    close() {
      this.$emit('close');
    },
  },
  watch: {
    tab() {
      this.expandedDataSource = null;
      this.showDataSourcePropertyForm = false;
    },
  },
};
</script>
