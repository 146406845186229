<template>
  <div class="px-3 pb-2">
    This item has no configuration. <br />
    You can drag & drop items into parallel item to execute items as parallel.
  </div>
</template>

<script>
export default {
  name: 'EvaluateSetEmail',
};
</script>
