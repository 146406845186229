<template>
  <ValidationObserver v-slot="{ errors, validate }">
    <Modal
      fullbelowsm
      :title="$t('settings.title')"
      close-button
      container-class="w-full sm:min-w-500 max-w-900"
      :primary-button="$t('save')"
      :secondary-button="$t('cancel')"
      @primary="onConfirm(validate)"
      @secondary="onCancel"
      @close="onCancel"
    >
      <div class="px-10 py-5">
        <div class="mb-2">
          <div class="text-15 font-600 mb-1">{{ $t('projects.endpoints.endpoint') }}</div>
          <FormSelect
            v-model="method"
            :options="methods"
            name="method"
            :error="errors.method"
            rules="required"
            :label="$t('projects.endpoints.method')"
            :reduce="(option) => option.value"
            :searchable="false"
            :clearable="false"
          ></FormSelect>
        </div>
        <div class="mb-2">
          <FormInput :readonly="loading" v-model="path" name="path" :error="errors.path" :rules="['required', { validate: pathValidation }]" :label="$t('projects.endpoints.path')"></FormInput>
        </div>
        <div class="mb-2">
          <FormInput :readonly="loading" v-model="groupName" name="groupName" :error="errors.groupName" rules="required" :label="$t('projects.endpoints.group_name')"></FormInput>
        </div>
        <div class="py-2">
          <div class="text-15 font-600 mb-1">{{ $t('conversations.transition') }}</div>
          <FormSelect
            :label="$t('conversations.switch_to_step')"
            class="mt-2"
            v-model="transitionValue"
            :options="steps"
            appendToBody
            :searchable="false"
            :clearable="false"
            :reduce="(option) => option.value"
          ></FormSelect>
        </div>
        <div class="py-4 w-full">
          <div class="text-15 font-600 mb-3">{{ $t('flow.request_parameters') }}</div>
          <table class="w-full max-sm:block">
            <thead class="max-sm:block">
              <tr class="max-sm:hidden">
                <th class="border-b font-500" width="40%">{{ $t('flow.parameter') }}</th>
                <th class="border-b font-500" width="40%">{{ $t('flow.variable') }}</th>
                <th class="border-b font-500" width="100">{{ $t('flow.required') }}</th>
                <th class="border-b font-500" width="50">{{ $t('flow.action') }}</th>
              </tr>
            </thead>
            <tbody class="max-sm:block">
              <tr v-for="(requestParameter, index) in requestParameters" :key="requestParameter.id" class="max-sm:flex max-sm:flex-col max-sm:w-full max-sm:pb-3">
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4 sm:pr-5">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.parameter') }}</label>
                  <Input v-model="requestParameter.key" class="w-full" />
                </td>
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.variable') }}</label>
                  <VariableSelect v-model="requestParameter.variable" />
                </td>
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.required') }}</label>
                  <div class="max-sm:ml-auto max-sm:mr-1 flex justify-center items-center">
                    <Checkbox v-model="requestParameter.required" />
                  </div>
                </td>
                <td class="max-sm:flex max-sm:items-center max-sm:mb-1 sm:h-10 sm:pt-4">
                  <label class="sm:hidden font-500 mr-3">{{ $t('flow.action') }}</label>
                  <div class="max-sm:ml-auto flex justify-center items-center"><IconButton icon="delete" class="text-primary" @click="removeRequestParameter(index)" /></div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-end">
            <PillButton primary class="mt-4" :text="$t('flow.add_parameter')" @click="onAddRequestParameterClick"></PillButton>
          </div>
        </div>
      </div>
    </Modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import uuidv4 from '@/helpers/uuid';
import FormSelect from '@/components/ui/FormSelect.vue';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';
import FormInput from '@/components/ui/FormInput.vue';

export default {
  name: 'FlowConfigModal',
  components: { FormInput, ValidationObserver, FormSelect },
  props: {},
  data() {
    return {
      transitionValue: null,
      requestParameters: [],
      method: null,
      methods: [
        { label: 'GET', value: 'GET' },
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'DELETE', value: 'DELETE' },
      ],
      path: null,
      groupName: null,
      loading: false,
    };
  },
  provide: {
    variableSelectorType: 'no-code',
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    steps() {
      return this.selectedIntentDefinition.steps.map((s) => ({
        value: s.id,
        label: s.name,
      }));
    },
  },
  created() {
    const requestParameters = this.selectedIntentDefinition.request_params || [];
    this.requestParameters = JSON.parse(JSON.stringify(requestParameters.map((item) => ({ id: uuidv4(), ...item }))));
    this.transitionValue = this.selectedIntentDefinition?.transition || '';

    this.method = this.selectedIntentDefinition.method;
    this.groupName = this.selectedIntentDefinition.group_name;
    this.path = this.selectedIntentDefinition.path;
  },
  methods: {
    pathValidation() {
      return this.path.startsWith('/') || this.$t('projects.endpoints.path_starts_slashes');
    },
    onAddRequestParameterClick() {
      this.requestParameters.push({
        id: uuidv4(),
        key: '',
        variable: '',
        required: false,
      });
    },
    removeRequestParameter(index) {
      this.requestParameters.splice(index, 1);
    },
    onConfirm() {
      const requestParameters = this.requestParameters
        .filter((item) => item.key.trim() && item.variable.trim())
        .map((item) => ({
          key: item.key.trim(),
          variable: item.variable,
          required: item.required,
        }));
      this.$emit('definitionSave', { requestParameters, transition: this.transitionValue, method: this.method, groupName: this.groupName, path: this.path });
      this.$emit('close');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
