<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.data_types') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton icon="cable" :text="$t('data_types.relations')" outlined class="mr-3" @click="onRelationClicked" />
          <PillButton icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <ListCard v-for="entityName in dataTypes" :key="entityName" class="flex-row mt-3" :class="{ hidden: ['ChartLibrary', 'SystemEvent'].includes(entityName) }">
          <div class="text-14 font-500 truncate">{{ entityName }}</div>
          <div class="flex items-center flex-1">
            <span v-if="dataTypeDetails[entityName] && dataTypeDetails[entityName].entityType === 'system'" class="ml-2 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <span v-if="dataTypeDetails[entityName] && dataTypeDetails[entityName].entityType === 'user'" class="ml-2 bg-green-200 rounded-6 px-1 text-12">user</span>
            <div v-if="dataTypeDetails[entityName] && dataTypeDetails[entityName].entityType !== 'system'" class="flex flex-row justify-end items-center ml-auto">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(entityName)" />
              <IconButton icon="delete" class="text-primary" @click="onDeleteClicked(entityName)" />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
    <Modal v-if="loadingIntentsModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
    <Modal v-if="showDeletingModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('deleting') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiDeleteEntity } from '@/helpers/api';
import AddEditDataType from '@/components/intent-editor/AddEditDataType';
import AddEditDatatypeRelation from '@/components/intent-editor/AddEditDatatypeRelation';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import debounce from '@/helpers/debounce';

export default {
  name: 'DataTypes',
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      } else if (!vm.$store.state.modelsFetched) {
        await vm.$store.dispatch('fetchModels', { type: vm.$store.state.designTimeActiveDatasourceType });
      } else if (from.fullPath !== '/') {
        vm.fetchData();
      }
    });
  },
  data() {
    return {
      dataTypes: [],
      dataTypeDetails: {},
      showDeletingModal: false,
      loadingIntentsModal: false,
    };
  },
  computed: {
    ...mapState(['user', 'designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'availableDataSources', 'org']),
    orgNameModelId() {
      return `${this.org}_${this.designTimeActiveDatasourceModelId}`;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchDesignTimeData']),
    onRelationClicked() {
      this.$showModal(AddEditDatatypeRelation, {
        intentDetails: this.dataTypeDetails,
      });
    },
    onCreateClicked() {
      this.$showModal(
        AddEditDataType,
        {
          intentDetails: this.dataTypeDetails,
        },
        {
          saveintent: this.onEntitySaved,
        },
      );
    },
    onEditClicked(intent) {
      this.$showModal(
        AddEditDataType,
        {
          intent,
          intentDetails: this.dataTypeDetails,
        },
        {
          saveintent: this.onEntitySaved,
        },
      );
    },
    onEntitySaved(response) {
      if (response) {
        if (!this.dataTypes.includes(response.name)) {
          this.dataTypes.push(response.name);
          this.dataTypes.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        }
        this.dataTypeDetails[response.name] = response;
      }
    },
    onDeleteClicked(intent) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('projects.confirm_remove_data_type_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.onDeleteIntentConfirmClicked(intent);
        },
      });
    },
    async onDeleteIntentConfirmClicked(intent) {
      this.showDeletingModal = true;
      const response = await apiDeleteEntity({ name: intent, type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
      if (response.status === 200) {
        this.showToastMessage({ message: this.$t('projects.data_type_removed'), type: 'success' });
        this.dataTypes = this.dataTypes.filter((i) => i !== intent);
        delete this.dataTypeDetails[intent];
        this.getIntentList();
      } else {
        this.showToastMessage({ title: this.$t('projects.failed_to_remove_data_type'), type: 'error' });
      }
      this.showDeletingModal = false;
    },
    async getIntentList() {
      this.loadingIntentsModal = true;

      const response = await this.fetchDesignTimeData();
      if (response) {
        this.dataTypes = Object.keys(response.entities).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        this.dataTypeDetails = response.entities;
        this.loadingIntentsModal = false;
        return;
      }

      this.dataTypes = [];
      this.dataTypeDetails = {};
      this.loadingIntentsModal = false;
    },
    // eslint-disable-next-line
    fetchData: debounce(async function () {
      this.getIntentList();
    }, 300),
  },
  watch: {
    orgNameModelId: {
      handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
};
</script>
