<template>
  <div class="sticky bottom-0 w-full flex border border-dashed bg-gray-200 border-gray-300 rounded-4 my-1 px-2 items-center min-h-10">
    <div class="font-700 leading-24 text-gray-700 pl-3">{{ $t('add') }}:</div>
    <div class="w-full flex justify-around flex-wrap">
      <PillButton v-if="!hiddenItems.includes('statement')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.statement`)" small @click.stop="onClick('statement')" />
      <PillButton v-if="!hiddenItems.includes('condition')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.condition`)" small @click.stop="onClick('condition')" />
      <PillButton v-if="!hiddenItems.includes('transition')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.transition`)" small @click.stop="onClick('transition')" />
      <PillButton v-if="!hiddenItems.includes('api')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.api`)" small @click.stop="onClick('api')" />
      <PillButton v-if="!hiddenItems.includes('response')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.response`)" small @click.stop="onClick('response')" />
      <PillButton v-if="!hiddenItems.includes('prompt')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.prompt`)" small @click.stop="onClick('prompt')" />
      <PillButton v-if="!hiddenItems.includes('ai')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.ai`)" small @click.stop="onClick('ai')" />
      <PillButton v-if="!hiddenItems.includes('email')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.email`)" small @click.stop="onClick('email')" />
      <PillButton v-if="!hiddenItems.includes('parallel')" class="my-1 mx-0.5" :text="$t(`evaluate_editor.parallel`)" small @click.stop="onClick('parallel')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvaluateAdd',
  props: {
    hiddenItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onClick(type) {
      this.$emit('add', type);
    },
  },
};
</script>
