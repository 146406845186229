<template>
  <div class="w-full h-full flex flex-col overflow-hidden select-none">
    <div class="flex-shrink-0 w-full flex justify-between px-5" :class="{ 'h-10 items-end': compactMode, 'h-15 items-center': !compactMode }">
      <div class="text-17 flex justify-center items-center font-600 text-primary">
        <IconButton size="8" icon="chevron_left" class="mr-2 !py-0" @click="onBackClick" />
        <span class="text-18 sm:text-24">{{ collectionName }}</span>
      </div>
      <div class="flex items-center">
        <IconButton icon="view_module" :class="{ 'text-primary': compactMode, 'text-gray-600': !compactMode }" title="Compact Mode" class="mr-2" @click="compactMode = true" />
        <IconButton icon="view_cozy" :class="{ 'text-primary': !compactMode, 'text-gray-600': compactMode }" title="Cozy Mode" class="mr-4" @click="compactMode = false" />
        <PillButton v-if="layoutChanged" :text="$t('collections.save_layout')" icon="save" secondary text-class="max-sm:hidden" icon-class="max-sm:mr-0" class="mr-2" @click="onChangeLayoutClick" />
        <PillButton icon="plus" :text="$t('collections.add_question')" class="max-sm:hidden" primary @click="openCreateQuestionModal" />
        <IconButton icon="plus" primary class="sm:hidden" @click="openCreateQuestionModal" />
      </div>
    </div>
    <div class="flex-grow overflow-auto px-1">
      <div id="grid-container" class="w-full" ref="grid-container">
        <div v-if="!collectionCards.length" class="flex flex-col items-center h-full justify-center pb-40 mt-5 sm:mt-30">
          <Icon name="collections" class="w-20 h-20 mb-5 opacity-50" />
          <div class="my-2 text-22 text-gray-700">{{ $t('collections.you_have_no_card') }}</div>
          <div class="my-2 text-gray-600">{{ $t('collections.create_first_card') }}</div>
          <PillButton class="my-3" :text="$t('collections.add_question')" primary @click="openCreateQuestionModal" />
        </div>
        <template v-else>
          <grid-layout
            v-model:layout="layout"
            :cols="{ lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 }"
            :row-height="120"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="compactMode ? [8, 8] : [20, 20]"
            :responsive="true"
            :use-css-transforms="true"
            @layout-created="changed('layout-created')"
            @layout-before-mount="changed('layout-before')"
            @layout-mounted="changed('layout-mounted')"
            @layout-ready="changed('layout-ready')"
            @layout-updated="changed('layout-updated')"
            @breakpoint-changed="changed('breakpoint-changed')"
          >
            <grid-item
              v-for="item in layout"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :key="item.i"
              :maxW="5"
              :minW="2"
              :minH="2"
              :maxH="4"
              dragIgnoreFrom=".swiper"
              @moved="onGridItemMove"
              @resized="onGridItemResize"
            >
              <div style="height: 100%">
                <CompactAnalysisCard
                  v-if="layoutMounted"
                  class="shadow-mini-card bg-white rounded-10"
                  :grid="item"
                  :collectionId="collectionId"
                  :collectionCard="getCollectionCardById(item.i)"
                  :key="getCollectionCardById(item.i).question + getCollectionCardById(item.i).type + item.i"
                  :result="getResult(collectionCardResults[getCollectionCardById(item.i).id])"
                  :type="getCollectionCardById(item.i).type"
                  :error="collectionCardResults[item.i] && collectionCardResults[item.i].error"
                  :errorMessage="collectionCardResults[item.i] && collectionCardResults[item.i].errorMessage"
                  :selectAccount="collectionCardResults[item.i] && collectionCardResults[item.i].select_account"
                  :questionPhrase="getQuestionText(collectionCardResults[item.i])"
                  :loading="collectionCardResults[item.i] && collectionCardResults[item.i].loading"
                  @chart-type-changed="onChartTypeChanged($event, item.i)"
                  @detail="selectQuestion(item.i)"
                  @reload="fetchCollectionIntentResultById(item.i)"
                  @del="checkDeletedCards"
                />
              </div>
            </grid-item>
          </grid-layout>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { GridItem, GridLayout } from 'vue3-grid-layout-next';
import { apiGetCollection, apiPostCollectionsQuery } from '@/helpers/api';
import Subscribe from '@/components/subscription/Subscribe.vue';

export default {
  name: 'CollectionCards',
  components: { GridLayout, GridItem },
  data() {
    return {
      collectionCardResults: {},
      interval: null,
      positionInterval: null,
      layoutChanged: false,
      layoutMounted: false,
      layout: [],
      compactMode: false,
      addQuestion: false,
      saveEnabled: false,
    };
  },
  computed: {
    ...mapState(['collections', 'activeDatasourceType', 'bootstrapped', 'user']),
    ...mapGetters(['getConversationList']),
    selectedQuestionId() {
      return this.$route?.params?.questionId || null;
    },
    collection() {
      return this.collections?.find((collection) => collection.id === this.collectionId);
    },
    collectionCards() {
      return this.collection?.questions || [];
    },
    collectionName() {
      return this.collection?.name;
    },
    collectionDigest() {
      return this.collection?.digest;
    },
    collectionLayout() {
      return this.collection?.layout || [];
    },
    collectionId() {
      return this.$route.params.id;
    },
  },
  beforeUnmount() {
    clearInterval(this.interval);
    clearInterval(this.positionInterval);
  },
  methods: {
    ...mapActions(['updateCollection', 'showToastMessage']),
    ...mapMutations(['SET_SELECTED_COLLECTION_QUESTION']),
    async onQuestionSave(args) {
      const { displayType, id } = args;
      const wrongCards = this.getWrongPositions();
      wrongCards.forEach((wrongId) => {
        if (!(wrongId in this.collectionCardResults)) {
          this.fetchCollectionIntentResultById(wrongId);
        }
      });

      if (!wrongCards.includes(id)) {
        this.fetchCollectionIntentResultById(id);
      }
      await this.checkCollectionCardPositions();
      if (id) {
        this.onChartTypeChanged(displayType, id);
      }
    },
    onBackClick() {
      if (this.addQuestion) {
        this.addQuestion = false;
      } else if (this.selectedQuestionId) {
        this.addQuestion = false;
        this.selectedQuestionId = null;
      } else {
        this.$router.push({ name: 'collections' });
      }
    },
    async onChangeLayoutClick() {
      await this.updateCollection({ collection_id: this.collectionId, name: this.collectionName, digest: this.collectionDigest, layout: this.layout });
      this.layoutChanged = false;
    },
    changed(event) {
      if (event === 'layout-created') {
        this.layoutMounted = false;
      } else if (event === 'layout-mounted') {
        this.$nextTick().then(() => {
          this.layoutMounted = true;
        });
      }
    },
    onGridItemMove() {
      this.layoutChanged = true;
    },
    onGridItemResize() {
      this.layoutChanged = true;
    },
    getCollectionCardById(id) {
      const card = this.collectionCards.find((item) => item.id === id) || {};
      const layoutItem = this.layout.find((item) => item.i === id);
      return {
        ...card,
        selectedChartType: layoutItem.c,
      };
    },
    onChartTypeChanged(chartType, id) {
      const card = this.getCollectionCardById(id);
      const layoutItem = this.layout.find((item) => item.i === id);
      layoutItem.c = chartType;
      if (card.selectedChartType !== chartType) {
        this.layoutChanged = true;
      }
    },
    selectQuestion(id) {
      this.SET_SELECTED_COLLECTION_QUESTION({
        id,
        question: this.collectionCardResults[id].question,
        card: this.collectionCardResults[id].result,
      });
      this.$router.push({
        name: 'collection',
        params: {
          id: this.collectionId,
          questionId: id,
        },
      });
    },
    getResult(collectionQuestion) {
      if (collectionQuestion?.result) {
        return collectionQuestion.result;
      }
      return null;
    },
    getQuestionText(collectionQuestion) {
      if (collectionQuestion?.question) {
        return collectionQuestion.question;
      }
      return null;
    },
    getWrongPositions() {
      return this.collectionCards.filter((collectionCard) => !this.layout.some((item) => item.i === collectionCard.id)).map((item) => item.id);
    },
    checkCollectionCardPositions() {
      return new Promise((resolve) => {
        const wrongPositionCards = this.getWrongPositions();
        if (!wrongPositionCards.length) {
          resolve(false);
          return;
        }

        this.positionInterval = setInterval(() => {
          const id = wrongPositionCards.pop();
          this.setCollectionCardPosition(id);

          if (!wrongPositionCards.length) {
            clearInterval(this.positionInterval);
            resolve(true);
          }
        }, 10);
      });
    },
    hitTest(x1, y1, w1, h1, x2, y2, w2, h2) {
      return ((x1 <= x2 && x1 + w1 > x2) || (x2 <= x1 && x2 + w2 > x1)) && ((y1 <= y2 && y1 + h1 > y2) || (y2 <= y1 && y2 + h2 > y1));
    },
    setCollectionCardPosition(id) {
      if (this.layout && Array.isArray(this.layout)) {
        if (this.layout.length) {
          const maxY = this.layout.reduce((prev, current) => (prev.y + prev.h > current.y + current.h ? prev : current));
          let found = null;
          for (let y = 0; y < maxY.y + maxY.h + 4; y++) {
            for (let x = 0; x < 9; x++) {
              if (!found) {
                if (!this.layout.some((item) => this.hitTest(item.x, item.y, item.w, item.h, x, y, 2, 2))) {
                  found = { x, y };
                }
              }
            }
          }
          this.layout.push({ x: found.x, y: found.y, w: 2, h: 2, i: id });
        } else {
          this.layout.push({ x: 0, y: 0, w: 2, h: 2, i: id });
        }
      }
    },
    openCreateQuestionModal() {
      this.$router.push({
        name: 'collection',
        params: {
          id: this.collectionId,
          questionId: 'new',
        },
      });
    },
    fetchCollectionIntentResultById(id) {
      const index = this.collectionCards.findIndex((question) => question.id === id);
      this.fetchCollectionIntentResult(index);
    },
    async fetchCollectionIntentResult(index) {
      if (index === this.collectionCards.length) {
        return;
      }
      const question = this.collectionCards[index];
      this.collectionCardResults[question.id] = {
        loading: true,
        result: null,
        error: false,
        errorMessage: null,
        question: null,
        queryTimestamp: Date.now(),
      };

      try {
        const result = await apiPostCollectionsQuery({
          type: question.type,
          datasource_id: question.datasource_id,
          account_id: question.account_id,
          collection_id: this.collectionId,
          question_id: question.question_id,
          id: question.id,
        });

        if (result.status === 200) {
          const [response] = result.data.responses;
          this.collectionCardResults[question.id].result = response.card;
          this.collectionCardResults[question.id].question = result.data.question;
        } else {
          this.collectionCardResults[question.id].error = true;
          this.collectionCardResults[question.id].question = result.data.question;
          this.collectionCardResults[question.id].errorMessage = result.data.message;
          this.collectionCardResults[question.id].select_account = result.data.select_account;
        }
      } catch (e) {
        this.collectionCardResults[question.id].error = true;
      }

      this.collectionCardResults[question.id].loading = false;
    },
    async fetchCollection() {
      this.collectionCards.forEach((question) => {
        this.collectionCardResults[question.id] = {
          loading: true,
          result: null,
          error: false,
          select_account: false,
          errorMessage: null,
          question: null,
          queryTimestamp: Date.now(),
        };
      });
      try {
        const response = await apiGetCollection(this.collectionId);
        if (response.status === 200) {
          if (response.data.error) {
            this.$router.push('/collections');
            this.$showModal(Subscribe);
            return;
          }
          const questions = response?.data?.responses || [];
          questions.forEach((question) => {
            if (question.id) {
              this.collectionCardResults[question.id].result = question?.card || null;
              this.collectionCardResults[question.id].loading = false;
              this.collectionCardResults[question.id].question = question?.question || '';
              this.collectionCardResults[question.id].error = question?.error || undefined;
              this.collectionCardResults[question.id].errorMessage = question?.message || undefined;
              this.collectionCardResults[question.id].select_account = question?.select_account || false;
            }
          });
        } else {
          this.collectionCards.forEach((question) => {
            this.collectionCardResults[question.id].loading = false;
            this.collectionCardResults[question.id].error = true;
          });
          this.showToastMessage({ title: response.data.message || this.$t('collections.failed_to_get_questions'), type: 'error' });
        }
      } catch (e) {
        this.collectionCards.forEach((question) => {
          this.collectionCardResults[question.id].loading = false;
          this.collectionCardResults[question.id].error = true;
        });
        this.showToastMessage({ title: e.message || this.$t('collections.failed_to_get_questions'), type: 'error' });
      }
    },
    async checkDeletedCards() {
      const toBeDeleted = [];
      this.layout.forEach((layoutItem) => {
        if (!this.collectionCards.some((collectionCard) => collectionCard.id === layoutItem.i)) {
          toBeDeleted.push(layoutItem.i);
        }
      });
      this.layout = this.layout.filter((layoutItem) => !toBeDeleted.includes(layoutItem.i));
      if (toBeDeleted.length) {
        await this.updateCollection({ collection_id: this.collectionId, name: this.collectionName, digest: this.collectionDigest, layout: this.layout });
      }
    },
  },
  watch: {
    bootstrapped: {
      handler() {
        if (this.bootstrapped) {
          this.layout = this.collectionLayout;

          this.checkDeletedCards().then(async () => {
            await this.checkCollectionCardPositions();
            const response = this.fetchCollection({ collectionId: this.collectionId });
            if (response && response?.data?.error) {
              if (response.data.error) {
                this.$router.push('/collections');
                this.$showModal(Subscribe);
              }
            }
          });
        }
      },
      immediate: true,
    },
    compactMode() {
      this.$emit('compactMode', this.compactMode);
    },
  },
};
</script>

<style lang="scss">
.vue-grid-item.vue-grid-placeholder {
  background: #f7f0ff;
  opacity: 1;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: solid 2px rgba(83, 55, 232, 0.2);
  border-radius: 10px;
}
.vue-grid-item.vue-draggable-dragging {
  border: solid 2px #5337e8;
  border-radius: 10px;
}
.vue-grid-item .vue-resizable-handle {
  padding: 0 4px 4px 0;
}

.default-layout[data-compact='true'] {
  .sidebar {
    display: none;
  }
  .main-content {
    padding: 0;
  }
}
</style>
