<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.variables') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <ListCard v-for="variableName in variables" :key="variableName" class="flex-row mt-3">
          <div class="text-14 font-500 truncate">{{ variableName }}</div>
          <div class="flex items-center flex-1">
            <span v-if="variableDetails[variableName] && variableDetails[variableName].dataType" class="ml-2 bg-gray-200 rounded-6 px-1 text-12">{{ variableDetails[variableName].dataType }}</span>
            <span v-if="variableDetails[variableName] && variableDetails[variableName].variableType === VARIABLE_TYPE.SYSTEM" class="ml-2 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <span v-if="variableDetails[variableName] && variableDetails[variableName].variableType === VARIABLE_TYPE.AI" class="ml-2 bg-green-200 rounded-6 px-1 text-12">ai</span>
            <span v-if="variableDetails[variableName] && variableDetails[variableName].readonly" class="ml-2 bg-red-200 rounded-6 px-1 text-12">readonly</span>

            <div
              v-if="variableDetails[variableName] && variableDetails[variableName].variableType !== VARIABLE_TYPE.SYSTEM && variableDetails[variableName].variableType !== VARIABLE_TYPE.AI"
              class="flex flex-row justify-end items-center ml-auto"
            >
              <IconButton icon="edit" class="mx-1 text-primary" @click="onEditClicked(variableName)" />
              <IconButton icon="delete" class="text-primary" @click="onDeleteClicked(variableName)" :text="$t('delete')" />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
    <Modal v-if="loadingIntentsModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
    <Modal v-if="showDeletingModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('deleting') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiDeleteVariableDefinition } from '@/helpers/api';
import { VARIABLE_TYPE } from '@/constants';
import AddEditVariable from '@/components/intent-editor/AddEditVariable';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import debounce from '@/helpers/debounce';

export default {
  name: 'Variables',
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      } else if (!vm.$store.state.modelsFetched) {
        await vm.$store.dispatch('fetchModels', { type: vm.$store.state.designTimeActiveDatasourceType });
      } else if (from.fullPath !== '/') {
        vm.fetchData();
      }
    });
  },
  data() {
    return {
      VARIABLE_TYPE,
      variables: [],
      variableDetails: {},
      showDeletingModal: false,
      loadingIntentsModal: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'org']),
    orgNameModelId() {
      return `${this.org}_${this.designTimeActiveDatasourceModelId}`;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchDesignTimeData']),
    onCreateClicked() {
      this.$showModal(AddEditVariable, null, { saveintent: this.onVariableSaved });
    },
    onEditClicked(variable) {
      this.$showModal(
        AddEditVariable,
        {
          variableName: variable,
        },
        {
          saveintent: this.onVariableSaved,
        },
      );
    },
    onVariableSaved(response) {
      if (response) {
        if (!this.variables.includes(response.name)) {
          this.variables.push(response.name);
          this.variables.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        }
        this.variableDetails[response.name] = response;
      }
    },
    onDeleteClicked(intent) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('projects.confirm_remove_variable_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: () => this.onDeleteIntentConfirmClicked(intent),
      });
    },
    async onDeleteIntentConfirmClicked(intent) {
      this.showDeletingModal = true;
      const response = await apiDeleteVariableDefinition({ name: intent, type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
      if (response.status === 200) {
        this.showToastMessage({ message: this.$t('projects.variable_removed_successfully'), type: 'success' });
        this.variables = this.variables.filter((i) => i !== intent);
        this.variables.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        delete this.variableDetails[intent];
        this.getIntentList();
      } else {
        this.showToastMessage({ title: this.$t('projects.failed_to_remove_variable'), type: 'error' });
      }
      this.showDeletingModal = false;
    },
    async getIntentList() {
      this.loadingIntentsModal = true;

      const response = await this.fetchDesignTimeData();

      if (response) {
        this.variables = Object.keys(response.variables).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        this.variableDetails = response.variables;
        this.loadingIntentsModal = false;
        return;
      }

      this.variables = [];
      this.variableDetails = {};
      this.loadingIntentsModal = false;
    },
    // eslint-disable-next-line
    fetchData: debounce(async function () {
      this.getIntentList();
    }, 300),
  },
  watch: {
    orgNameModelId: {
      handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
};
</script>
