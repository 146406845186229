<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.settings') }}</div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2 max-w-1000">
        <ValidationObserver v-slot="{ validate, errors }">
          <FormInput v-model="name" rules="required" :label="$t('projects.name')" name="projectName" :error="errors.projectName" />

          <div class="mt-7 flex items-center justify-between">
            <PillButton :text="$t('projects.update_project')" primary @click="onSaveClick(validate)" :disabled="loading" :loading="loading"></PillButton>
          </div>

          <div class="mt-10">
            <div class="text-20 font-500 mb-2">{{ $t('projects.danger_zone') }}</div>
            <div class="flex justify-between items-center border border-solid border-alert border-opacity-50 p-4 rounded-8">
              <div>
                <div class="font-600">{{ $t('projects.delete_this') }}</div>
                <div>{{ $t('projects.delete_warning') }}</div>
              </div>
              <PillButton :text="$t('projects.delete_this')" error @click="openDeleteProjectModal"></PillButton>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import FormInput from '@/components/ui/FormInput.vue';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';
import { mapActions, mapState } from 'vuex';
import DeleteModal from '@/components/shared-modals/DeleteModal.vue';

export default {
  name: 'Settings',
  components: { ValidationObserver, FormInput },
  data() {
    return {
      name: '',
      loading: false,
    };
  },
  computed: {
    ...mapState(['projects']),
    project() {
      return this.projects[this.$route.params.id];
    },
  },
  created() {
    this.name = this.project.name;
  },
  methods: {
    ...mapActions(['updateProject', 'deleteProject', 'showToastMessage']),
    async onSaveClick(validate) {
      this.loading = true;
      if (validate) {
        await validate();
      }
      const { project_id, tenants, authentication, cors } = this.project;
      await this.updateProject({ name: this.name.trim(), project_id, tenants, authentication, cors });
      this.loading = false;
    },
    openDeleteProjectModal(project) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.remove_project', { projectName: project.name }),
        info: null,
        onConfirm: () => this.onDeleteClicked(project),
      });
    },
    async onDeleteClicked() {
      this.loading = true;
      const res = await this.deleteProject(this.project.project_id);
      if (res) {
        this.showToastMessage({ message: this.$t('projects.project_removed'), type: 'success' });
        this.$router.push('/no-code');
      }
      this.loading = false;
    },
  },
};
</script>
