<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.conversations') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <ListCard v-for="intent in conversations" :key="intent" class="flex-row mt-3">
          <div class="text-14 font-500 truncate">{{ intent }}</div>
          <div class="flex items-center flex-1">
            <span v-if="conversationDetails[intent] && conversationDetails[intent].conversationType === 'system'" class="ml-2 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <div class="flex flex-row justify-end items-center ml-auto">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(intent)" />
              <IconButton
                v-if="conversationDetails[intent] && (!conversationDetails[intent].conversationType || conversationDetails[intent].conversationType !== 'system')"
                class="text-primary"
                icon="delete"
                @click="onDeleteClicked(intent)"
              />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
    <Modal v-if="loadingIntentsModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
    <Modal v-if="showDeletingModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('deleting') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiDeleteConversationDefinition } from '@/helpers/api';
import AddEditConversationModal from '@/components/intent-editor/AddEditConversation';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import debounce from '@/helpers/debounce';

export default {
  name: 'Conversations',
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      } else if (!vm.$store.state.modelsFetched) {
        await vm.$store.dispatch('fetchModels', { type: vm.$store.state.designTimeActiveDatasourceType });
      } else if (from.fullPath !== '/') {
        vm.fetchData();
      }
    });
  },
  data() {
    return {
      conversations: [],
      conversationDetails: {},
      showDeletingModal: false,
      loadingIntentsModal: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId']),
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchDesignTimeData']),
    onCreateClicked() {
      this.$showModal(
        AddEditConversationModal,
        {
          intentDetails: this.conversationDetails,
          variables: this.variables,
        },
        {
          saveintent: this.onIntentSaved,
        },
      );
    },
    onEditClicked(intent) {
      this.$router.push({ name: 'conversation', params: { intent } });
    },
    onIntentSaved(response) {
      if (response) {
        if (!this.conversations.includes(response.name)) {
          this.conversations.push(response.name);
          this.conversations.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        }
        this.conversationDetails[response.name] = response;
      }
    },
    onDeleteClicked(intent) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('conversations.confirm_remove_conversation_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.onDeleteIntentConfirmClicked(intent);
        },
      });
    },
    async onDeleteIntentConfirmClicked(intent) {
      this.showDeletingModal = true;
      const response = await apiDeleteConversationDefinition({ name: intent, type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
      if (response.status === 200) {
        this.showToastMessage({ message: this.$t('conversations.removed_successfully'), type: 'success' });
        this.conversations = this.conversations.filter((i) => i !== intent);
        delete this.conversationDetails[intent];
        this.getIntentList();
      } else {
        this.showToastMessage({ title: this.$t('conversations.failed_to_remove'), type: 'error' });
      }
      this.showDeletingModal = false;
    },
    async getIntentList() {
      this.loadingIntentsModal = true;

      const response = await this.fetchDesignTimeData();

      if (response) {
        this.conversations = Object.keys(response.conversations).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        this.conversationDetails = response.conversations;
        this.loadingIntentsModal = false;
        return;
      }

      this.conversations = [];
      this.conversationDetails = {};
      this.loadingIntentsModal = false;
    },
    // eslint-disable-next-line
    fetchData: debounce(async function () {
      this.getIntentList();
    }, 300),
  },
  watch: {
    designTimeActiveDatasourceModelId: {
      handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
};
</script>
