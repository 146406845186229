<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('conversations.suggestions') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <v-select
            v-model="language"
            dense
            :options="selectLanguages"
            :reduce="(option) => option.value"
            auto-select
            rounded
            elevated
            :clearable="false"
            :searchable="false"
            alternative-indicator
            class="mr-3"
            @update:modelValue="onLanguageChange"
          />

          <PillButton :text="$t('save')" primary @click="save" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 mb-5">
        <div class="h-full flex flex-col">
          <div v-for="(suggestion, index) in suggestions[language]" :key="suggestion.id" class="flex items-center">
            <Input :modelValue="suggestion.text" class="mb-1 flex-grow" @update:modelValue="onSuggestionInput($event, index)" @paste="onInputPaste($event, index)" />
            <IconButton :class="{ invisible: index + 1 === suggestions.length && suggestion.text === '' }" class="text-primary" icon="delete" size="4" @click="onRemoveSuggestionClicked(index)" />
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="loading">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('conversations.fetching_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import uuidv4 from '@/helpers/uuid';
import { apiGetConversationSuggestions, apiPostConversationSuggestions } from '@/helpers/api';

export default {
  name: 'FineTune',
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      } else if (!vm.$store.state.modelsFetched) {
        await vm.$store.dispatch('fetchModels', { type: vm.$store.state.designTimeActiveDatasourceType });
      }
    });
  },
  data() {
    return {
      loading: false,
      language: 'TR',
      suggestions: {},
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'availableDataSources', 'user', 'designTimeLanguage', 'org']),
    ...mapGetters(['getActiveDatasourceDataTypes']),
    payload() {
      const suggestions = JSON.parse(JSON.stringify(this.suggestions));

      Object.keys(suggestions).forEach((lang) => {
        suggestions[lang] = suggestions[lang].map((phrase) => phrase.text.trim()).filter((item) => item);
      });
      return suggestions;
    },
    languages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: lang }));
    },
    selectLanguages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: this.$t(`languages.${lang}`) }));
    },
    orgNameModelId() {
      return `${this.org}_${this.designTimeActiveDatasourceModelId}`;
    },
  },
  created() {
    this.language = this.user?.language || 'EN';
    if (this.languages.some((lang) => lang.value === this.designTimeLanguage)) {
      this.language = this.designTimeLanguage;
    }
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchDesignTimeData']),
    ...mapMutations(['SET_DESIGN_TIME_LANGUAGE']),
    onLanguageChange(lang) {
      this.SET_DESIGN_TIME_LANGUAGE(lang);
    },
    onInputPaste(e, index) {
      const text = e.clipboardData.getData('Text');
      const phrases = text
        .split(/(?:\r\n|\r|\n)/g)
        // .map((i) => this.formatPhrase(i))
        .filter((i) => i);

      phrases.forEach((phrase, i) => {
        this.onSuggestionInput(phrase, index + i);
      });
      e.preventDefault();
    },
    formatPhrase(phrase) {
      phrase = phrase.replace(/\s+/g, ' ');
      phrase = phrase.replace(/\.+/g, '');
      phrase = phrase.replace(/[^\p{L}0-9'\-\s]/gu, '');
      phrase = phrase.toLocaleLowerCase();
      return phrase;
    },
    onSuggestionInput(value, key) {
      this.suggestions[this.language][key].text = value;
      this.checkSuggestionsForNew();
    },
    onAddSuggestionClicked() {
      this.suggestions[this.language].push({ id: uuidv4(), text: '' });
    },
    checkSuggestionsForNew() {
      if (this.suggestions[this.language].length) {
        if (this.suggestions[this.language][this.suggestions[this.language].length - 1].text !== '') {
          this.onAddSuggestionClicked();
        }
      } else {
        this.onAddSuggestionClicked();
      }
    },
    onRemoveSuggestionClicked(index) {
      if (index >= 0) {
        this.suggestions[this.language].splice(index, 1);
      }
    },
    async save() {
      try {
        const response = await apiPostConversationSuggestions({ suggestions: this.payload, type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('conversations.suggestions_saved'), type: 'success' });
        } else {
          this.showToastMessage({ title: response.data.message || this.$t('conversations.failed_to_save_suggestions'), type: 'error' });
        }
      } catch (e) {
        this.showToastMessage({ title: e.message || this.$t('conversations.failed_to_save_suggestions'), type: 'error' });
      }
    },
    async fetchData() {
      try {
        this.loading = true;
        const response = await apiGetConversationSuggestions(this.designTimeActiveDatasourceType, this.designTimeActiveDatasourceModelId);
        this.loading = false;

        if (response.status === 200) {
          let { suggestions = {} } = response.data;
          if (!suggestions) suggestions = {};

          this.selectLanguages.forEach((item) => {
            const lang = item.value;
            let value = [];
            if (lang in suggestions) {
              value = suggestions[lang].map((text) => ({ id: uuidv4(), text }));
            }
            this.suggestions[lang] = value;
          });

          this.checkSuggestionsForNew();
        } else {
          this.showToastMessage({ title: response.data.message || this.$t('conversations.failed_to_get_suggestions'), type: 'error' });
        }
      } catch (e) {
        this.loading = false;
        this.showToastMessage({ title: e.message || this.$t('conversations.failed_to_get_suggestions'), type: 'error' });
      }
    },
  },
  watch: {
    orgNameModelId: {
      handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          if (this.languages.length && !this.languages.some((l) => l.value === this.language)) {
            this.language = this.languages[0].value;
          }

          this.fetchData();
        }
      },
      immediate: true,
    },
    language() {
      if (!this.loading) {
        this.checkSuggestionsForNew();
      }
    },
  },
};
</script>
