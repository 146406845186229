<template>
  <div class="flex h-full overflow-hidden relative" style="background-color: #f4f4f4">
    <EvaluateEditor
      :hiddenEvaluateTypes="hiddenEvaluateTypes"
      ref="evaluateEditor"
      class="w-full"
      :data="evaluateData"
      :selectedItemIds="selectedItemIds"
      @item-clicked="rightPanelOpened = false"
      @evaluate-update="$emit('update', $event)"
      @select="onEvaluateItemSelect"
      @unselect="onEvaluateItemUnSelect"
      @selectAll="onEvaluateSelectAll"
    />

    <div
      style="background-color: rgba(250, 250, 250, 1)"
      class="absolute top-0 bottom-0 w-1/2 transition-all duration-300 ease-in-out z-10"
      :class="{ 'left-1/2': rightPanelOpened, 'left-full': !rightPanelOpened }"
    >
      <div v-if="tryItResponses" class="absolute -left-3 h-full bg-white w-3 flex items-center justify-center cursor-pointer" @click="rightPanelOpened = !rightPanelOpened">
        <div class="w-3 h-15 bg-blue-400 flex items-center justify-center text-10 text-white border border-indigo-300 rounded-l-8">{{ !rightPanelOpened ? '&lt;' : '&gt;' }}</div>
      </div>
      <div class="absolute inset-0 p-3">
        <div v-if="tryItLoading" class="w-full h-full flex items-center justify-center">
          <spinner class="w-10 h-10"></spinner>
        </div>
        <div class="h-full flex flex-col">
          <CollapseCard
            :title="$t('flow.response_card')"
            class="shadow-card mt-3 flex-shrink-0"
            :style="{ 'max-height': variableCollapseCardOpened ? '50%' : 'calc(100% - 60px)' }"
            scroll
            @change="responseCollapseCardOpened = $event"
          >
            <template #content>
              <div class="p-3">
                <div v-for="(response, index) in tryItResponses" :key="index">
                  <ConversationMessage v-if="response.text" key="1" incoming :text="response.text" />
                  <ConversationMessage v-if="response.loading" key="2" incoming>
                    <div class="flex flex-row items-center" v-if="response.loading">
                      <Icon name="loading_dots" class="w-10 text-primary" />
                    </div>
                  </ConversationMessage>
                  <ConversationPrompt v-if="response.prompt" :disabled="true" :prompt="response.prompt" />
                  <div v-if="response.card" class="shadow-md bg-white rounded-10">
                    <AnalysisCard v-if="response" :datasource-type="designTimeActiveDatasourceType" :result="response.card" :showExportButton="false" />
                  </div>
                </div>
              </div>
            </template>
          </CollapseCard>
          <CollapseCard :title="$t('flow.variables')" class="shadow-card mt-3" scroll @change="variableCollapseCardOpened = $event">
            <template #content>
              <div class="px-3">
                <div v-for="(variable, variableName) in tryItVariables" :key="variableName" class="flex py-2 items-center border-b border-solid border-gray-200">
                  <span>{{ variable.variableName }}</span>
                  <span v-if="variable.dataType" class="ml-4 bg-gray-200 rounded-6 px-1 text-12">{{ variable.dataType }}</span>
                  <div class="ml-auto flex items-center">
                    <div v-if="variable.value && typeof variable.value === 'string'" class="max-w-300 overflow-ellipsis overflow-hidden" style="white-space: nowrap">{{ variable.value }}</div>
                    <div v-else-if="variable.value !== undefined && typeof variable.value === 'number'" class="max-w-300 overflow-ellipsis overflow-hidden">
                      {{ removePipes(variable.value) }}
                    </div>
                    <div v-else-if="variable.value && variable.dataType === 'Date'">{{ getTimeText(variable.value) }}</div>
                    <IconButton
                      v-if="variable.value && (['ChartConfig', 'DataTable', 'Date'].includes(variable.dataType) || Array.isArray(variable.value) || typeof variable.value === 'string')"
                      class="ml-2"
                      icon="visibility"
                      :text="$t('evaluate.show_value')"
                      @click="showVariableModal(variable, variableName)"
                    ></IconButton>
                  </div>
                </div>
              </div>
            </template>
          </CollapseCard>
        </div>
      </div>
    </div>
    <Modal
      v-if="selectedVariable"
      sheetbelowsm
      close-button
      :title="$t('variable', { variable: selectedVariable.name })"
      :secondary-button="$t('close')"
      @secondary="selectedVariable = null"
      @close="selectedVariable = null"
    >
      <div class="sm:w-700 h-full p-10">
        <div v-if="selectedVariable.value">
          <template v-if="typeof selectedVariable.value === 'string'">
            {{ removePipes(selectedVariable.value) }}
          </template>
          <template v-else-if="selectedVariable.dataType === 'ChartConfig'">
            <ChartSelector :report="{ chart: [selectedVariable.value] }" :selected-chart-type="selectedVariable.value.chart.type" />
          </template>
          <div v-else-if="selectedVariable.dataType === 'DataTable'">
            <DataTable class="w-full" :value="selectedVariable.value" />
          </div>
          <template v-else-if="selectedVariable.dataType === 'Date'">
            {{ getTimeText(selectedVariable.value) }}
          </template>
          <template v-else-if="Array.isArray(selectedVariable.value)">
            <DataTable class="w-full" :value="selectedVariable.value" />
          </template>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EvaluateEditor from '@/components/flow/evaluate/EvaluateEditor';
import { apiPostEvaluate } from '@/helpers/api';
import { VARIABLE_TYPE } from '@/constants';
import ConversationMessage from '@/components/conversation/ConversationMessage';
import ConversationPrompt from '@/components/conversation/ConversationPrompt';
import AnalysisCard from '@/components/conversation/AnalysisCard';
import ChartSelector from '@/components/charts/ChartSelector';
import dayjs from 'dayjs';

export default {
  name: 'StepEvaluate',
  components: { ChartSelector, EvaluateEditor, ConversationMessage, ConversationPrompt, AnalysisCard },
  props: {
    evaluateData: {
      type: Array,
      required: true,
    },
    hiddenEvaluateTypes: {
      type: Array,
      default: () => [],
    },
  },
  inject: {
    variableSelectorType: { default: 'conversation' },
  },
  data() {
    return {
      rightPanelOpened: false,
      tryItLoading: false,
      VARIABLE_TYPE,
      selectedVariable: null,
      tryItVariables: null,
      tryItResponses: null,
      selectedItemId: null,
      selectedItemIds: [],
      responseCollapseCardOpened: true,
      variableCollapseCardOpened: true,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceDatasourceId', 'designTimeActiveDatasourceAccountId', 'designTimeActiveDatasourceModelId']),
    filteredEvaluateData() {
      if (this.selectedItemIds.length) {
        return this.evaluateData.filter((evaluateItem) => this.selectedItemIds.includes(evaluateItem.id));
      }
      return this.evaluateData;
    },
  },
  methods: {
    ...mapActions(['fetchDesignTimeData', 'showToastMessage']),
    removePipes(str) {
      if (typeof str !== 'string') return str;
      return str.replaceAll('|.|', '.');
    },
    getTimeText(value) {
      const from = dayjs(value.from).format('DD-MM-YYYY');
      const to = dayjs(value.to).format('DD-MM-YYYY');
      return `${from} - ${to} (${value.count} ${value.grain})`;
    },
    showVariableModal(variable, variableName) {
      this.selectedVariable = { name: variableName, ...variable };
    },
    onEvaluateItemSelect(id) {
      if (this.selectedItemIds.includes(id)) {
        this.selectedItemIds = this.selectedItemIds.filter((item) => item !== id);
      } else {
        this.selectedItemIds.push(id);
      }

      if (this.selectedItemIds.length !== this.evaluateData.length) {
        this.$refs.evaluateEditor.selectAll = false;
      } else if (this.selectedItemIds.length === this.evaluateData.length) {
        this.$refs.evaluateEditor.selectAll = true;
      }
    },
    onEvaluateItemUnSelect(id) {
      if (this.selectedItemIds.includes(id)) {
        this.selectedItemIds = this.selectedItemIds.filter((item) => item !== id);
      }
    },
    onEvaluateSelectAll(selectAll) {
      if (selectAll) {
        this.selectedItemIds = this.evaluateData.map((item) => item.id);
      } else {
        this.selectedItemIds = [];
      }
    },
    async tryIt() {
      this.rightPanelOpened = true;
      this.tryItLoading = true;
      this.tryItResponses = [];
      const response = await apiPostEvaluate({
        type: this.designTimeActiveDatasourceType,
        datasource_id: this.designTimeActiveDatasourceDatasourceId,
        account_id: this.designTimeActiveDatasourceAccountId,
        evaluate: this.filteredEvaluateData,
        model_id: this.designTimeActiveDatasourceModelId,
      });

      const { data } = response;
      if (data?.error && data?.message) {
        this.showToastMessage({
          title: response.data.message,
          type: 'error',
        });
      }

      this.tryItResponses = response.data?.responses || [];

      const { variables = {} } = response.data;

      this.tryItVariables = Object.keys(variables)
        .map((variable) => ({ variableName: variable, ...variables[variable] }))
        .sort((a, b) => a.variableName.toLowerCase().localeCompare(b.variableName.toLowerCase()));
      this.tryItLoading = false;
    },
  },
};
</script>
