<template>
  <DefaultLayout class="default-layout" :data-compact="compactMode">
    <CollectionGrid ref="grid" v-show="!selectedQuestionId" @compactMode="compactMode = $event"></CollectionGrid>
    <CollectionCard v-if="selectedQuestionId" @save="onCardAdd"></CollectionCard>
  </DefaultLayout>
</template>

<script>
import CollectionGrid from '@/components/collection/CollectionGrid.vue';
import CollectionCard from '@/components/collection/CollectionCard.vue';

export default {
  name: 'Collection',
  components: { CollectionCard, CollectionGrid },
  data() {
    return {
      compactMode: false,
    };
  },
  computed: {
    selectedQuestionId() {
      return this.$route?.params?.questionId || null;
    },
  },
  methods: {
    onCardAdd(event) {
      this.$refs.grid.onQuestionSave(event);
    },
  },
};
</script>

<style lang="scss">
.vue-grid-item.vue-grid-placeholder {
  background: #f7f0ff;
  opacity: 1;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: solid 2px rgba(83, 55, 232, 0.2);
  border-radius: 10px;
}
.vue-grid-item.vue-draggable-dragging {
  border: solid 2px #5337e8;
  border-radius: 10px;
}
.vue-grid-item .vue-resizable-handle {
  padding: 0 4px 4px 0;
}

.default-layout[data-compact='true'] {
  .sidebar {
    display: none;
  }
  .main-content {
    padding: 0;
  }
}
</style>
