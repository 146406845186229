<template>
  <div class="px-3 pb-2">
    <div class="h-full">
      <div class="pt-3 w-full">
        <label class="block text-12 mb-1">{{ $t('evaluate.store_api_response') }}</label>
        <VariableSelect v-model="variable" class="w-full" system :filter-data-type="['DataTable']" @update:modelValue="emitPayload" />
      </div>
      <div class="pt-3 w-full">
        <label class="block text-12 mb-1">{{ $t('data_source') }}</label>
        <v-select v-model="datasource" :options="datasources" :reduce="(option) => option.value" class="w-full" @update:modelValue="onDatasourceChange" />
      </div>
      <div class="flex flex-row items-center justify-start w-full mt-3">
        <div class="w-full flex">
          <div class="w-25 mr-3">
            <label class="block text-12 mb-1">{{ $t('flow.type') }}</label>
            <v-select
              v-model="api.dateRange.type"
              :reduce="(option) => option.value"
              class="w-full"
              :searchable="false"
              :clearable="false"
              appendToBody
              auto-select
              :options="dateRangeOptions"
              @option:selected="onDateRangeTypeSelected"
            ></v-select>
          </div>

          <div class="w-250" :key="item.id">
            <label class="block text-12 mb-1">{{ $t('date_range') }}</label>
            <v-select v-if="api.dateRange.type === 'static'" v-model="api.dateRange.value" :options="getDateRanges" :reduce="selectReducer"></v-select>
            <VariableSelect v-else v-model="api.dateRange.value" class="mr-2" :show-create="false" :filter-data-type="['Date']" @update:modelValue="emitPayload" />
          </div>
        </div>
      </div>
      <div v-if="datasource === designTimeActiveDatasourceType" class="pt-3 w-full">
        <label class="block text-12 mb-1">{{ $t('evaluate.ai_auto_connect') }}</label>
        <Toggle v-model="api.aiConnect" @update:modelValue="onAIConnectChange" />
      </div>
      <div v-if="!api.aiConnect">
        <div class="flex flex-row items-center justify-start w-full">
          <div class="w-full">
            <div class="w-full flex mt-5 font-600">
              <div class="flex items-center border-b border-solid border-gray-200 pb-1 flex-shrink-0 w-28">{{ $t('flow.type') }}</div>
              <div class="flex items-center border-b border-solid border-gray-200 pb-1 flex-shrink-0 w-200">{{ $t('data_type') }}</div>
              <div class="flex items-center border-b border-solid border-gray-200 pb-1 flex-shrink-0 w-300">{{ $t('flow.parameter') }}</div>
              <div class="flex items-center border-b border-solid border-gray-200 pb-1 flex-shrink-0 w-14">{{ $t('flow.filter') }}</div>
              <div class="flex items-center border-b border-solid border-gray-200 pb-1 flex-shrink-0 w-28 pl-3">{{ $t('order_by') }}</div>
              <div class="flex items-center border-b border-solid border-gray-200 pb-1 flex-shrink-0 w-14 justify-center">
                <IconButton icon="plus" class="text-primary font-700" @click="checkNewParameter" />
              </div>
            </div>
            <div v-if="api && api.parameters && api.parameters.length" class="w-full mt-2">
              <div v-for="parameter in api.parameters" :key="parameter.value" class="mb-3">
                <div class="w-full flex">
                  <div class="w-28 flex-shrink-0 pb-1 pr-3">
                    <v-select
                      v-model="parameter.type"
                      :reduce="(option) => option.value"
                      class="w-full"
                      :searchable="false"
                      :clearable="false"
                      appendToBody
                      :options="[
                        { label: $t('static'), value: 'static' },
                        { label: $t('variable'), value: 'variable' },
                      ]"
                      @update:modelValue="onParameterTypeChange(parameter)"
                    ></v-select>
                  </div>
                  <div class="w-200 flex-shrink-0 pb-1 pr-3 flex">
                    <v-select
                      class="w-full"
                      key="static-data-type"
                      v-model="parameter.dataType"
                      :clearable="false"
                      :reduce="(option) => option.value"
                      :options="getDataTypes"
                      @update:modelValue="emitPayload"
                    ></v-select>
                  </div>
                  <div class="w-300 flex-shrink-0 pb-1 pr-3 flex">
                    <template v-if="parameter.type === 'static'">
                      <v-select
                        class="w-full"
                        key="static"
                        :clearable="false"
                        v-model="parameter.value"
                        :reduce="(option) => option.value"
                        :options="getSelectableParameters(parameter.dataType)"
                        @update:modelValue="emitPayload"
                      ></v-select>
                    </template>

                    <VariableSelect
                      v-else
                      key="variable"
                      :clearable="false"
                      :excludes="getVariableExcludes(parameter.value)"
                      :filter-data-type="[parameter.dataType]"
                      class="w-full"
                      v-model="parameter.value"
                      @update:modelValue="emitPayload"
                    ></VariableSelect>
                  </div>
                  <div class="w-14 flex-shrink-0 pb-1 flex justify-start items-center pl-1" :class="{ 'bg-purple-200 rounded-t-8': parameter.filter }">
                    <Toggle :modelValue="isFilterEnabled(parameter)" :disabled="parameter.type === 'variable'" @update:modelValue="onParameterFilterChange(parameter)" />
                  </div>
                  <div class="w-28 flex-shrink-0 pb-1 pl-3 flex justify-center items-center">
                    <v-select
                      :options="[
                        { value: 'ASCENDING', label: 'ASC' },
                        { value: 'DESCENDING', label: 'DESC' },
                      ]"
                      v-model="parameter.order"
                      class="w-full"
                      :clearable="true"
                      :searchable="false"
                      appendToBody
                      :reduce="selectReducer"
                      @update:modelValue="emitPayload"
                    />
                  </div>
                  <div class="w-14 flex-shrink-0 pb-1 flex justify-center items-center">
                    <IconButton icon="delete" class="text-primary" @click="removeParameter(parameter)" />
                  </div>
                </div>
                <div class="flex pt-2 w-[668px] bg-purple-200 pb-2 rounded-l-8 rounded rounded-br-8 pl-3" v-if="parameter.filter">
                  <div class="w-50 pr-3">
                    <span class="text-13">{{ $t('flow.operator') }}</span>
                    <v-select
                      v-model="parameter.filter.operator"
                      :options="operators"
                      class="w-full"
                      :clearable="false"
                      :searchable="false"
                      appendToBody
                      :reduce="selectReducer"
                      @update:modelValue="emitPayload"
                    />
                  </div>
                  <div class="w-28 flex-shrink-0 pr-3">
                    <span class="text-13">{{ $t('flow.type') }}</span>
                    <v-select
                      v-model="parameter.filter.type"
                      :reduce="(option) => option.value"
                      class="w-full"
                      :searchable="false"
                      :clearable="false"
                      appendToBody
                      :options="[
                        { label: $t('static'), value: 'static' },
                        { label: $t('variable'), value: 'variable' },
                      ]"
                      @option:selected="parameter.filter.value = null"
                    ></v-select>
                  </div>
                  <div class="w-200 flex-shrink-0 pb-1 pr-3">
                    <span class="text-13">{{ $t('data_type') }}</span>
                    <v-select
                      class="w-full"
                      v-model="parameter.filter.dataType"
                      :reduce="(option) => option.value"
                      :options="getFilterDataTypes"
                      :clearable="false"
                      @update:modelValue="onFilterDataTypeChange(parameter)"
                    ></v-select>
                  </div>
                  <div class="w-300 pr-3 flex flex-col pt-0.5">
                    <span class="text-13">{{ $t('value') }}</span>
                    <template v-if="getParameterFilterType(parameter) === 'variable'">
                      <VariableSelect
                        v-model="parameter.filter.value"
                        class="w-full"
                        :clearable="false"
                        :filter-data-type="[parameter.filter.dataType]"
                        :showCreate="false"
                        @update:modelValue="emitPayload"
                      />
                    </template>
                    <template v-else-if="getParameterFilterType(parameter) === 'static'">
                      <v-select
                        v-if="!isSystemDataType(parameter.filter.dataType)"
                        class="w-full"
                        key="static"
                        v-model="parameter.filter.value"
                        :reduce="(option) => option.value"
                        :clearable="false"
                        :options="getSelectableParameters(parameter.filter.dataType)"
                        @update:modelValue="emitPayload"
                      ></v-select>
                      <Input v-else v-model="parameter.filter.value" class="w-full" @update:modelValue="emitPayload" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showError">
      <Divider />
      <div class="flex flex-row my-3 w-full justify-between">
        <div v-if="showError" class="text-red-600">{{ $t('validation.fill_all_required') }}</div>
        <div class="flex-grow"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from '@/helpers/debounce';
import { EVALUATE_API_PAYLOAD, OPERATORS, VARIABLE_TYPE } from '@/constants';
import i18n from '@/plugins/i18n';

export default {
  name: 'EvaluateSetApi',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      editorData: null,
      dataFetchError: null,
      dataFetching: false,
      showError: false,
      api: EVALUATE_API_PAYLOAD.payload.value,
      variable: null,
      datasource: null,
      datasourceDataFetching: null,
      operators: OPERATORS,
    };
  },
  inject: {
    variableSelectorType: { default: 'conversation' },
  },
  computed: {
    ...mapState(['selectedIntentDefinition', 'availableDataSources', 'designTimeActiveDatasourceType', 'activeDatasourceDatasourceId', 'activeDatasourceAccountId']),
    ...mapGetters(['getActiveDatasourceVariableList', 'getNoCodeEntities', 'getActiveDatasourceDataTypes']),
    dateRangeOptions() {
      return this.api.aiConnect
        ? [{ label: this.$t('variable'), value: 'variable' }]
        : [
            { label: this.$t('static'), value: 'static' },
            { label: this.$t('variable'), value: 'variable' },
          ];
    },
    getDateRanges() {
      const dateRanges = i18n.global.tm('date_ranges');
      return Object.keys(dateRanges).map((key) => ({ label: dateRanges[key], value: key }));
    },
    entities() {
      if (this.variableSelectorType === 'no-code') {
        return this.getNoCodeEntities;
      }
      return this.getActiveDatasourceDataTypes;
    },
    getDataTypes() {
      return Object.keys(this.entities)
        .filter((dataType) => this.entities[dataType].entityType !== VARIABLE_TYPE.SYSTEM)
        .filter((dataType) => !['DataTable', 'ChartConfig'].includes(dataType))
        .map((dataType) => ({ label: dataType, value: dataType }));
    },
    getFilterDataTypes() {
      return Object.keys(this.entities)
        .filter((dataType) => !['DataTable', 'ChartConfig'].includes(dataType))
        .map((dataType) => ({ label: dataType, value: dataType }));
    },
    payload() {
      const value = JSON.parse(JSON.stringify(this.api));

      // Remove empty parameters
      value.parameters = value.parameters.filter((p) => p.value);

      if (value.aiConnect) {
        delete value.parameters;
      }

      return {
        id: this.item.id,
        type: this.item.type,
        payload: { variable: this.variable, value, type: this.datasource },
      };
    },
    datasources() {
      return Object.keys(this.availableDataSources).map((ds) => ({
        label: this.availableDataSources[ds].name,
        value: ds,
      }));
    },
    steps() {
      return this.selectedIntentDefinition.steps.map((s) => ({
        value: s.id,
        label: s.name,
      }));
    },
  },
  created() {
    this.itemChangeHandler();
  },
  methods: {
    ...mapActions(['showToastMessage']),
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.payload);
    }, 300),
    onAIConnectChange(value) {
      if (value) {
        this.api.dateRange.type = this.dateRangeOptions[0].value;
        this.api.dateRange.value = null;
      }
      this.emitPayload();
    },
    onFilterDataTypeChange(parameter) {
      parameter.filter.value = null;
      this.emitPayload();
    },
    async onDatasourceChange() {
      if (this.datasource) {
        this.api.aiConnect = false;
      }
    },
    isSystemDataType(dataType) {
      return this.entities?.[dataType]?.entityType === VARIABLE_TYPE.SYSTEM;
    },
    checkNewParameter() {
      const emptyParameter = {
        type: 'static',
        dataType: null,
        value: null,
        order: null,
      };
      this.api.parameters.push(emptyParameter);
    },
    isFilterEnabled(parameter) {
      return 'filter' in parameter && typeof parameter.filter === 'object';
    },
    onParameterFilterChange(parameter) {
      parameter.filter = {
        dataType: null,
        type: null,
        value: null,
        operator: null,
      };
      this.emitPayload();
    },
    onParameterTypeChange(parameter) {
      parameter.value = null;
      delete parameter.filter;
      this.emitPayload();
    },
    removeParameter(parameter) {
      const index = this.api.parameters.findIndex((p) => p.value === parameter.value);
      if (index >= 0) {
        this.api.parameters.splice(index, 1);
      }
      this.emitPayload();
    },
    getParameterFilterType(parameter) {
      return parameter?.filter?.type;
    },
    onDateRangeTypeSelected() {
      this.api.dateRange.value = null;
    },
    onSaveClicked() {
      this.showError = false;

      if (!this.api.path) {
        this.showError = true;
      } else if (!this.api.method) {
        this.showError = true;
      } else if (!this.api.dateRangeVariable) {
        this.showError = true;
      } else if (this.api.parameters.some((p) => p.operator && (!p.operator.value || !p.operator.comparisonValue.type || !p.operator.comparisonValue.value))) {
        this.showError = true;
      }
    },
    selectReducer(option) {
      return option.value;
    },
    getSelectableParameters(dataType) {
      if (dataType) {
        const fields = this.entities[dataType].fields || {};
        return Object.keys(fields).map((field) => ({ label: field, value: field }));
      }
      return [];
    },
    getVariableExcludes(variable) {
      return this.api.parameters.map((p) => p.value).filter((v) => v !== variable);
    },
    itemChangeHandler() {
      if (this.item) {
        const apiData = JSON.parse(JSON.stringify(this.item.payload.value));
        this.variable = this.item.payload.variable;
        this.datasource = this.item.payload.type || this.designTimeActiveDatasourceType;

        this.api = { ...this.api, ...apiData };
        this.api.parameters.map((parameter) => {
          if (!parameter.type) {
            parameter.type = 'static';
          }
          return parameter;
        });
      }
    },
  },
  watch: {
    item: {
      handler() {
        this.itemChangeHandler();
      },
      deep: true,
    },
  },
};
</script>
