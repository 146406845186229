<template>
  <div class="w-full flex flex-col justify-end px-1 sm:px-2 pb-5 max-w-1000 mx-auto pt-11 lg:pt-0">
    <IntersectionObserver @intersect="intersected" />
    <div class="conversation-item-container w-full flex flex-col justify-center">
      <div v-for="(item, index) in conversationIds" :key="item" class="pt-2" :id="`conversation-item-${item}`">
        <div v-if="checkDateSeparator(index).show" class="flex justify-center items-center">
          <div class="bg-gray-200 flex-grow h-px"></div>
          <div class="px-2 text-gray-400 text-12">{{ checkDateSeparator(index).time }}</div>
          <div class="bg-gray-200 flex-grow h-px"></div>
        </div>
        <ConversationItem
          :showFeedbackButtons="index === conversationIds.length - 1"
          :disablePrompt="index !== conversationIds.length - 1"
          :item="getConversationList[item]"
          :index="item"
          @prompt-item-click="onPromptItemClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import ConversationItem from '@/components/conversation/ConversationItem';
import IntersectionObserver from '@/components/ui/IntersectionObserver';

export default {
  name: 'Conversation',
  components: { IntersectionObserver, ConversationItem },
  computed: {
    ...mapState(['org', 'activeDatasourceType', 'activeDatasourceModelId']),
    ...mapGetters(['getConversationList']),
    conversationIds() {
      return Object.keys(this.getConversationList).sort();
    },
    lastConversationId() {
      return [...this.conversationIds].pop();
    },
    orgDataSourceModel() {
      return `${this.org}-${this.activeDatasourceType}-${this.activeDatasourceModelId}`;
    },
  },
  data() {
    return {
      today: dayjs(),
    };
  },
  mounted() {
    setTimeout(() => {
      const scrollContainer = document.getElementById('conversation-container');
      scrollContainer?.scrollBy({ left: 0, top: scrollContainer.scrollHeight });
    }, 500);
  },
  methods: {
    ...mapActions(['queryIntent', 'fetchConversationHistory', 'fetchLaunchRequest']),
    ...mapMutations(['ADD_QUERY', 'SET_QUERY', 'SET_ACTIVE_DATASOURCE_ACCOUNT_ID', 'SET_ACTIVE_DATASOURCE_DATASOURCE_ID', 'SET_ACTIVE_DATASOURCE']),
    onPromptItemClicked(item) {
      if (item?.title) {
        this.queryIntent({ phrase: item.title, speak: true });
      }
    },
    dayjs,
    async intersected() {
      const lastId = [...Object.keys(this.getConversationList).sort((a, b) => (a > b ? -1 : 1))].pop();
      await this.fetchConversationHistory({ start: lastId, total: 4 });
      this.$forceUpdate();
      this.$nextTick().then(() => {
        if (lastId) {
          document.getElementById(`conversation-item-${lastId}`)?.scrollIntoView();
        } else {
          const scrollContainer = document.getElementById('conversation-container');
          scrollContainer?.scrollBy({ left: 0, top: scrollContainer.scrollHeight, behavior: 'smooth' });
        }
      });
    },
    checkDateSeparator(index) {
      const item1 = this.getConversationList[this.conversationIds[index]];
      const item2 = this.conversationIds.length > index ? this.getConversationList[this.conversationIds[index + 1]] : null;
      const date1 = dayjs(item1.query.created);

      if (item2) {
        const date2 = dayjs(item2.query.created);
        const diffDays = date2.diff(date1, 'day');

        if (diffDays > 0) {
          const time = this.getFormattedTime(date1);
          return {
            show: true,
            time,
          };
        }
        return {
          show: false,
        };
      }
      return {
        show: this.today.diff(date1, 'day') !== 0,
        time: this.getFormattedTime(date1),
      };
    },
    getFormattedTime(date1) {
      let time = '';
      if (this.today.diff(date1, 'day') === 0) {
        time = this.$t('today');
      } else if (this.today.diff(date1, 'day') === 1) {
        time = this.$t('date_ranges.yesterday');
      } else if (this.today.diff(date1, 'day') < 7) {
        time = date1.format('dddd');
      } else {
        time = date1.format('DD MMM');
      }
      return time;
    },
  },
  watch: {
    lastConversationId() {
      setTimeout(() => {
        this.$nextTick().then(() => {
          const scrollContainer = document.getElementById('conversation-container');
          scrollContainer?.scrollBy({ left: 0, top: scrollContainer.scrollHeight, behavior: 'smooth' });
        });
      }, 100);
    },
    orgDataSourceModel: {
      async handler() {
        await this.fetchConversationHistory({ clearHistory: true });
        await this.fetchLaunchRequest();
        if (this.$route.query.q) {
          this.queryIntent({ phrase: this.$route.query.q, speak: true });
          this.$router.replace({ hash: '', query: '' });
        }
      },
      immediate: true,
    },
  },
};
</script>
