<template>
  <ValidationObserver v-slot="{ validate, errors, dirty }">
    <Modal
      sheetbelowsm
      :title="$t('conversations.add')"
      :primary-button="$t('save')"
      :primary-button-disabled="!isIntentNameValid()"
      :primary-button-loading="loading"
      :secondary-button="$t('cancel')"
      close-button
      @primary="onSaveClicked(validate)"
      @secondary="onCancelClicked"
      @close="onCancelClicked"
    >
      <div class="py-5 w-full sm:w-600 px-10">
        <div class="w-full flex mb-3">
          <FormInput v-model="name" name="name" class="w-full" :label="$t('name')" :rules="['required', { validate: isIntentNameValid }]" :error="errors.name"></FormInput>
        </div>
        <div class="w-full flex flex-col justify-start mt-5">
          <FormSelect
            v-model="flowType"
            :options="flowTypes"
            :reduce="(option) => option.value"
            name="flowType"
            :label="$t('flow.type')"
            :error="errors.flowType"
            append-to-body
            @option:selected="onFlowTypeSelected(validate, dirty)"
          ></FormSelect>
        </div>
      </div>
    </Modal>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiPostConversationDefinition } from '@/helpers/api';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';

export default {
  name: 'AddEditConversationModal',
  components: { ValidationObserver },
  data() {
    return {
      originalName: '',
      name: '',
      flowType: '',
      loading: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'designTimeData']),
    flowTypes() {
      return [
        { label: this.$t('conversation.conversation'), value: 'conversation' },
        { label: this.$t('collection'), value: 'collection' },
      ];
    },
    conversationNames() {
      const data = this.designTimeData[this.designTimeActiveDatasourceType].conversations;
      return Object.keys(data);
    },
    collectionNames() {
      const data = this.designTimeData[this.designTimeActiveDatasourceType].questions;
      return data.map((d) => d.name);
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'addCollectionQuestion']),
    async onFlowTypeSelected(validate, dirty) {
      if (dirty) {
        validate();
      }
    },
    isIntentNameValid() {
      if (this.flowType === 'collection') {
        if (this.collectionNames.includes(this.name)) {
          return this.$t('validation.name_unique');
        }
      }
      if (this.flowType === 'conversation') {
        if (this.conversationNames.includes(this.name)) {
          return this.$t('validation.name_unique');
        }
      }
      return true;
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked(validate) {
      const valid = await validate();
      if (valid.valid) {
        this.loading = true;

        if (this.flowType === 'conversation') {
          const response = await apiPostConversationDefinition({
            name: this.name,
            type: this.designTimeActiveDatasourceType,
            model_id: this.designTimeActiveDatasourceModelId,
            steps: [],
          });
          this.loading = false;

          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('conversations.added_successfully'), type: 'success' });
            this.$emit('saveintent', { ...response.data, flowType: this.flowType });
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: response?.data?.message || this.$t('conversations.failed_to_add'), type: 'error' });
        }
        if (this.flowType === 'collection') {
          const response = await this.addCollectionQuestion({ name: this.name });
          this.loading = false;

          if (response) {
            this.$emit('saveintent', { ...response, flowType: this.flowType });
            this.$emit('close');
          }
        }
      }
    },
  },
};
</script>
