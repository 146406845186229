<template>
  <div class="w-full flex text-13">
    <div class="flex-grow">
      <input
        v-model="phrase"
        :id="id"
        ref="textarea"
        wrap="off"
        rows="1"
        class="w-full p-2 m-0 h-9 resize-none outline-0 border"
        :class="{ 'border-indigo-500': focus, 'border-alert': checkAlert }"
        @focus="onPhraseFocus"
        @blur="onPhraseBlur"
        @input="onPhraseInput"
        @paste="onInputPaste"
      />
      <div
        v-show="showPanel"
        class="pointer-events-none p-2 h-full whitespace-nowrap flex flex-wrap items-center"
        :class="{ 'bg-gray-100 border border-gray-200': expand }"
        style="color: rgba(0, 0, 0, 0.5)"
      >
        <VDropdown
          v-for="(item, index) in selectedList"
          :key="item.id"
          :ref="`popover-${index}`"
          class="inline"
          :auto-hide="false"
          :triggers="[]"
          :shown="item.showPopover"
          @apply-hide="onPopoverApplyHide(item)"
          @apply-show="onPopoverApplyShow(item)"
        >
          <span
            v-if="item.type && item.name"
            class="pointer-events-auto cursor-pointer text-white p-0.5 rounded-4 mr-1"
            :class="{ '!text-alert': !(item.type in getActiveDatasourceDataTypes), '!text-primary outline-1 outline outline-primary': !getBgColor(item.type) }"
            :style="{ backgroundColor: getBgColor(item.type) }"
            @click="item.showPopover = true"
            >{{ getFieldName(item) }}</span
          >
          <span v-else class="bg-gray-200 pointer-events-auto cursor-pointer p-0.5 rounded-4 mr-1" @click="item.showPopover = true">{{ item.name || 'Select Field' }}</span>

          <template #popper>
            <div class="w-full">
              <div class="p-2">
                <table class="text-14">
                  <tr>
                    <td class="py-1 font-500 pr-2">{{ $t('data_type') }}</td>
                    <td class="py-1">
                      <VSelect
                        appendToBody
                        dense
                        :clearable="false"
                        :options="getDataTypes()"
                        :modelValue="getSelectedItem(item).type"
                        class="w-300 mr-2 z-10"
                        @option:selected="onDataTypeSelected($event, item)"
                      >
                      </VSelect>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 font-500 pr-2">{{ $t('data_types.field') }}</td>
                    <td class="py-1">
                      <v-select
                        dense
                        :clearable="false"
                        :modelValue="getSelectedItem(item).name"
                        class="w-300"
                        :options="dataTypeFilter(getDataTypeFields(item.type))"
                        :reduce="(option) => option.value"
                        appendToBody
                        @option:selected="onPropertySelected($event, item)"
                      ></v-select>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 font-500 pr-2">{{ $t('flow.filter') }}</td>
                    <td class="py-1"><Toggle dense :modelValue="getSelectedItem(item).filter" class="mr-2" @update:modelValue="onFilterInput($event, item)" /></td>
                  </tr>
                  <tr v-if="getSelectedItem(item).filter">
                    <td class="py-1 font-500 pr-2">{{ $t('flow.operator') }}</td>
                    <td class="py-1">
                      <v-select
                        dense
                        :modelValue="getSelectedItem(item).operator"
                        class="w-300 mr-2"
                        :options="operators"
                        :reduce="(option) => option.value"
                        appendToBody
                        @update:modelValue="updateParam($event, 'operator', item)"
                      ></v-select>
                    </td>
                  </tr>
                  <tr v-if="getSelectedItem(item).filter">
                    <td class="py-1 font-500 pr-2">{{ $t('flow.filter_value') }}</td>
                    <td class="py-1">
                      <v-select
                        v-if="hasDataType(getSelectedItem(item))"
                        :modelValue="getSelectedItem(item).value"
                        :options="filterDataTypeFields(getSelectedItem(item))"
                        :reduce="(option) => option.value"
                        appendToBody
                        @option:selected="updateParam($event.value, 'value', item)"
                        @clear="updateParam(null, 'value', item)"
                      ></v-select>
                      <Input v-else class="!p-1 !h-8" :modelValue="getSelectedItem(item).value" @update:modelValue="updateParam($event, 'value', item)" />
                    </td>
                  </tr>
                </table>
              </div>
              <div class="w-full border-t border-solid border-gray-200 p-2 flex justify-between">
                <PillButton outlined :text="$t('close')" class="text-primary" @click="onCancel(item, index)" />
                <PillButton icon="delete" outlined :text="$t('delete')" class="text-primary" @click="clearItem(item)" />
              </div>
            </div>
          </template>
        </VDropdown>
        <Icon name="plus" class="inline-flex pointer-events-auto text-primary cursor-pointer w-4 h-4 ml-3 -mt-0.5" @click="addItem" />
        <IconButton icon="delete" class="ml-auto text-primary pointer-events-auto" @click="$emit('remove')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import uuidv4 from '@/helpers/uuid';
import { OPERATORS, VARIABLE_TYPE } from '@/constants';
import VSelect from '@/components/ui/VSelectWrapper.vue';

export default {
  name: 'FineTuneInput',
  components: { VSelect },
  props: {
    modelValue: Object,
    show: Boolean,
  },
  emits: ['newline', 'remove', 'error', 'focus', 'update:modelValue'],
  data() {
    return {
      id: uuidv4(),
      focus: false,
      phrase: '',
      selectedList: [],
      expand: false,
      operators: OPERATORS,
    };
  },
  created() {
    if (this.value) {
      this.phrase = this.value.p;

      this.value.d.forEach((item) => {
        this.addSelectedItem({
          type: item.t,
          name: item.n,
          value: item.v,
          filter: !!item.v,
          operator: item.o,
          showPopover: false,
        });
      });
    }
  },
  computed: {
    ...mapGetters(['getActiveDatasourceDataTypes']),
    value() {
      return this.modelValue;
    },
    showPanel() {
      return this.expand && !!this.phrase && ['tra', 'val'].includes(this.value?.t);
    },
    checkAlert() {
      return this.selectedList.some((item) => {
        if (item.type) {
          if (!(item.type in this.getActiveDatasourceDataTypes)) {
            return true;
          }
          if (!(item.name in this.getActiveDatasourceDataTypes[item.type].fields)) {
            return true;
          }
        }
        return false;
      });
    },
    payload() {
      let selectedList = JSON.parse(JSON.stringify(this.selectedList));
      selectedList = selectedList.filter((item) => item.type && item.name);

      return {
        id: this.value.id,
        l: this.value.l,
        t: this.value.t,
        p: this.phrase,
        d: selectedList.map((item) => {
          return {
            n: item.name,
            t: item.type,
            o: item.operator,
            v: item.value,
          };
        }),
      };
    },
  },
  methods: {
    getFieldName(item) {
      if (item.type === 'Date') {
        if (typeof this.getActiveDatasourceDataTypes?.[item.type]?.fields[item.name] === 'string') {
          return this.getActiveDatasourceDataTypes?.[item.type]?.fields[item.name];
        }
        return this.getActiveDatasourceDataTypes?.[item.type]?.fields[item.name].mapping;
      }
      return item.name;
    },
    hasDataType(selectedItem) {
      return selectedItem.name in this.getActiveDatasourceDataTypes && this.getActiveDatasourceDataTypes?.[selectedItem.name]?.fields;
    },
    filterDataTypeFields(selectedItem) {
      return Object.keys(this.getActiveDatasourceDataTypes?.[selectedItem.name]?.fields).map((i) => ({ label: i, value: i }));
    },
    getDataTypes() {
      return Object.keys(this.getActiveDatasourceDataTypes)
        .filter((i) => this.getActiveDatasourceDataTypes?.[i]?.entityType !== VARIABLE_TYPE.SYSTEM || i === 'Date' || i === 'Quantifier')
        .map((i) => ({ label: i, value: i }));
    },
    getSelectedItem(item) {
      return this.selectedList.find((s) => s.type === item.type && s.name === item.name) || {};
    },
    onFilterInput($event, item) {
      if ($event) {
        this.updateParam($event, 'filter', item);
      } else {
        this.updateParams(item, { filter: $event, operator: null, value: null });
      }
    },
    onDataTypeSelected($event, item) {
      this.updateParam($event.value, 'type', item);
      this.updateParam(null, 'name', item);
    },
    onPropertySelected($event, item) {
      this.updateParam($event.value, 'name', item);
    },
    updateParam(value, param, item) {
      const selected = this.selectedList.find((s) => s.id === item.id);
      if (selected) {
        selected[param] = value;
      }
      this.emitPayload();
    },
    updateParams(item, params) {
      const selected = this.selectedList.find((s) => s.id === item.id);
      if (selected) {
        Object.keys(params).forEach((param) => {
          selected[param] = params[param];
        });
      }
      this.emitPayload();
    },
    formatPhrase(phrase) {
      phrase = phrase.replace(/\s+/g, ' ');
      phrase = phrase.replace(/\.+/g, '');
      phrase = phrase.replace(/[^\p{L}0-9'\-\s]/gu, '');
      phrase = phrase.toLocaleLowerCase();
      return phrase;
    },
    onPhraseFocus() {
      this.expand = true;
      this.focus = true;
      this.$emit('focus');
    },
    onPhraseBlur() {
      this.focus = false;
    },
    onInputPaste(e) {
      this.phrase = e.clipboardData.getData('Text');
      this.onPhraseInput();
      e.preventDefault();
    },
    onPhraseInput() {
      const phrases = this.phrase
        .split(/(?:\r\n|\r|\n)/g)
        .map((i) => this.formatPhrase(i))
        .filter((i) => i);
      if (phrases.length > 1) {
        this.phrase = phrases.shift();
        this.$emit('newline', phrases);
      } else {
        this.phrase = this.formatPhrase(this.phrase).replace(/(?:\r\n|\r|\n)/g, '');
      }
      this.emitPayload();
    },
    emitPayload() {
      this.$emit('update:modelValue', this.payload);
    },
    getBgColor(dataType) {
      return this.getActiveDatasourceDataTypes[dataType]?.colour || '';
    },
    onPopoverApplyHide(item) {
      item.showPopover = false;
    },
    onPopoverApplyShow(item) {
      this.closeAllPopover();
      item.showPopover = true;
    },
    closeAllPopover() {
      this.selectedList.forEach((i) => {
        i.showPopover = false;
      });
    },
    dataTypeFilter(list) {
      return list.filter((item) => !this.selectedList.map((s) => s.name).includes(item.value));
    },
    getDataTypeFields(dataType) {
      if (!dataType) {
        return [];
      }
      // dataType = dataType.charAt(0).toUpperCase() + dataType.slice(1);

      // Label fix for Date DataType
      if (dataType === 'Date') {
        if (this.getActiveDatasourceDataTypes?.[dataType]?.fields) {
          return Object.keys(this.getActiveDatasourceDataTypes[dataType].fields).map((key) => {
            return {
              label: this.getActiveDatasourceDataTypes[dataType].fields[key],
              value: key,
            };
          });
        }
      }
      if (this.getActiveDatasourceDataTypes?.[dataType]?.fields) {
        return Object.keys(this.getActiveDatasourceDataTypes[dataType].fields)
          .map((key) => {
            return {
              label: key,
              value: key,
            };
          })
          .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
      return [];
    },
    removeEmptyItems() {
      for (let i = this.selectedList.length - 1; i >= 0; i--) {
        if (!this.selectedList[i].type || !this.selectedList[i].name) {
          this.selectedList.splice(i, 1);
        }
      }
    },
    clearItem(item) {
      this.selectedList = this.selectedList.filter((s) => s.id !== item.id);
      this.emitPayload();
    },
    expandItem() {
      this.expand = !this.expand;
    },
    addItem() {
      const emptyItem = this.selectedList.find((selected) => !selected.type && !selected.name);
      if (emptyItem) {
        emptyItem.showPopover = true;
      } else {
        this.addSelectedItem({
          type: null,
          name: null,
          value: null,
          operator: null,
          showPopover: true,
        });
      }
    },
    addSelectedItem({ type = null, showPopover = false, name = null, value = null, operator = null, filter = false }) {
      if (!this.selectedList.some((selected) => selected.name === name && selected.type === type)) {
        this.selectedList.push({
          id: uuidv4(),
          type,
          name,
          value,
          operator,
          filter,
          showPopover,
        });
      } else {
        // eslint-disable-next-line
        console.error(
          'overlap',
          this.selectedList.find((selected) => selected.name === name && selected.type === type),
          this.phrase,
        );
      }
    },
    onCancel(item, index) {
      item.showPopover = false;
      this.$refs?.[`popover-${index}`]?.[0]?.hide();
      this.removeEmptyItems();
    },
  },
  mounted() {},
  beforeUnmount() {},
  watch: {
    checkAlert() {
      this.$emit('error', this.checkAlert);
    },
    showPanel() {
      if (!this.showPanel) {
        this.closeAllPopover();
      }
    },
    show() {
      if (!this.show) {
        this.closeAllPopover();
      }
    },
  },
};
</script>
