<template>
  <Modal
    sheetbelowsm
    :title="$t('settings.title')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    close-button
    container-class="sm:min-w-700 max-w-900"
    :primary-button-loading="loading"
    @close="onCancel"
    @secondary="onCancel"
    @primary="onConfirm"
  >
    <div class="px-10 py-5">
      <div class="py-4">
        <div class="text-15 font-600 mb-3">{{ $t('conversations.transition') }}</div>
        <div>{{ $t('conversations.switch_to_step') }}</div>
        <v-select class="mt-2" v-model="transitionValue" :options="steps" appendToBody :searchable="false" :clearable="false" :reduce="(option) => option.value"></v-select>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FlowConfigModal',
  props: {
    onSave: Function,
  },
  data() {
    return {
      transitionValue: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    steps() {
      return this.selectedIntentDefinition.steps.map((s) => ({
        value: s.id,
        label: s.name,
      }));
    },
  },
  created() {
    const { transition = '' } = this.selectedIntentDefinition;
    this.transitionValue = transition;
  },
  methods: {
    async onConfirm() {
      if (this.onSave) {
        this.loading = true;
        const res = await this.onSave({
          transition: this.transitionValue,
        });
        this.loading = false;

        if (res) {
          this.$emit('close');
        }
      }
    },
    onCancel() {
      this.$emit('close');
    },
  },
};
</script>
