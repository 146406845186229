<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.email_templates.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div v-for="(emailTemplate, index) in emailTemplates" :key="`${index}-${emailTemplate.name}`">
          <ListCard class="flex-row mt-3">
            <div class="mr-4 truncate">{{ emailTemplate.name }}</div>
            <IconButton icon="edit" class="ml-auto text-primary" @click="onEditClicked(emailTemplate)"></IconButton>
            <IconButton icon="delete" class="sm:ml-1 text-primary" @click="onDeleteClicked(emailTemplate)"></IconButton>
          </ListCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateEmailTemplateModal from '@/components/no-code/CreateEmailTemplateModal';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import { apiDeleteEmailTemplate } from '@/helpers/api';

export default {
  name: 'EmailTemplates',
  computed: {
    ...mapState(['projectData']),
    projectId() {
      return this.$route.params.id;
    },
    emailTemplates() {
      const emailTemplates = this.projectData?.[this.projectId]?.email_templates || {};
      return Object.values(emailTemplates);
    },
  },
  methods: {
    ...mapActions(['fetchProjectData', 'showToastMessage']),
    onCreateClicked() {
      this.$showModal(CreateEmailTemplateModal, { projectId: this.projectId });
    },
    onEditClicked(emailTemplate) {
      this.$showModal(CreateEmailTemplateModal, { projectId: this.projectId, editData: emailTemplate });
    },
    onDeleteClicked(emailTemplate) {
      const modal = this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.email_template.remove_template', { templateName: emailTemplate.name }),
        info: null,
        onConfirm: async () => {
          await this.onDeleteConfirmClicked(emailTemplate, modal);
        },
      });
    },
    async onDeleteConfirmClicked(item, modal) {
      try {
        const response = await apiDeleteEmailTemplate({ project_id: this.projectId, name: item.name });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('projects.email_template.removed_successfully'), type: 'success' });
          await this.fetchProjectData(this.projectId);
          modal.close();
          return;
        }
        this.showToastMessage({ title: this.$t('projects.email_template.failed_to_remove'), type: 'error' });
      } catch (e) {
        this.showToastMessage({ title: this.$t('projects.email_template.failed_to_remove'), type: 'error' });
      }
    },
  },
  watch: {
    projectId: {
      handler() {
        this.fetchProjectData(this.$route.params.id);
      },
      immediate: true,
    },
  },
};
</script>
