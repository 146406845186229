<template>
  <ValidationObserver v-slot="{ errors, validate }">
    <Modal
      sheetbelowsm
      :title="mode === 'Edit' ? $t('datastore.edit_model') : $t('datastore.add_model')"
      :primary-button="$t('save')"
      :secondary-button="$t('cancel')"
      :primary-button-disabled="loading"
      :primary-button-loading="loading"
      close-button
      @primary="onSaveClicked(validate)"
      @close="onCancelClicked"
      @secondary="onCancelClicked"
    >
      <div class="sm:w-600 px-10">
        <div class="w-full flex flex-col justify-start mt-5">
          <FormInput v-model="modelId" name="model_id" :rules="['required']" :error="errors && errors.model_id" class="w-full" :readonly="mode === 'Edit'" :label="$t('datastore.model_id')" />
        </div>
        <div class="w-full flex flex-col justify-start mt-3">
          <FormInput v-model="name" name="name" :rules="['required']" :error="errors && errors.name" class="w-full" :label="$t('datastore.model_name')" />
        </div>
        <div class="w-full flex flex-col justify-start mt-3">
          <FormSelect
            v-model="org"
            :options="organisationList"
            multiple
            append-to-body
            name="organisations"
            :rules="['required']"
            :error="errors && errors.org"
            class="w-full"
            :label="$t('organisation.organisation')"
          />
        </div>
      </div>
    </Modal>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiPostDataSourceModel, apiPutDataSourceModel } from '@/helpers/api';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';

export default {
  name: 'AddEditDatasourceModel',
  props: {
    model: Object,
  },
  components: { ValidationObserver },
  data() {
    return {
      mode: 'Add',
      name: '',
      org: [],
      modelId: '',
      loading: false,
    };
  },
  emits: ['save', 'update', 'close'],
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'organisations']),
    organisationList() {
      return this.organisations.filter((item) => !this.org.some((i) => i.value === item.value));
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked(validate) {
      const valid = await validate();
      if (valid.valid) {
        this.loading = true;
        if (this.mode === 'Add') {
          const response = await apiPostDataSourceModel({
            type: this.designTimeActiveDatasourceType,
            model_id: this.modelId,
            name: this.name,
            org: this.org.map((o) => o.value),
          });
          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('datastore.model_added_successfully'), type: 'success' });
            this.$emit('save', response.data);
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: this.$t('datastore.failed_to_add_model'), type: 'error' });
          this.loading = false;
          return;
        }
        if (this.mode === 'Edit') {
          const response = await apiPutDataSourceModel({
            name: this.name,
            type: this.designTimeActiveDatasourceType,
            model_id: this.modelId,
            org: this.org.map((o) => o.value),
          });
          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('datastore.model_updated_successfully'), type: 'success' });
            this.$emit('update', response.data);
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: this.$t('projects.failed_to_update_model'), type: 'error' });
          this.loading = false;
        }
      }
    },
  },
  created() {
    if (this.model) {
      this.mode = 'Edit';
      this.name = this.model?.name;
      this.modelId = this.model?.model_id;
      const org = this.model?.org || [];
      this.org = this.organisationList.filter((o) => org.includes(o.value));
    } else {
      this.mode = 'Add';
      this.name = '';
    }
  },
};
</script>
