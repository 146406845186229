<template>
  <Modal
    sheetbelowsm
    :title="title"
    container-class="w-full max-w-600"
    :primary-button-disabled="!newCollectionName || loading"
    :primary-button-loading="loading"
    :secondary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    :primary-button="$t('submit')"
    @primary="onSubmit"
    @secondary="$emit('close', false)"
  >
    <div>
      <div class="w-full flex flex-col justify-start my-3 px-10 pt-7">
        <RadioButton v-model="type" value="exist" name="collectionType" :label="$t('collections.select_collection_card')" />
        <RadioButton v-model="type" value="create" name="collectionType" :label="$t('collections.create_collection_card')" class="mt-3" />

        <div class="mt-5 mb-10">
          <template v-if="type !== 'create'">
            <div class="w-full max-w-300 mb-3">
              <label class="block text-13 mb-1">{{ $t('collections.name') }}:</label>
              <v-select
                v-model="newCollectionName"
                :placeholder="$t('collections.select_collection')"
                :options="collectionOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                appendToBody
              ></v-select>
            </div>
          </template>
          <div v-else class="flex flex-wrap sm:flex-nowrap">
            <div class="w-full max-w-300 sm:mr-5 mb-3">
              <label class="block text-13 mb-1">{{ $t('collections.name') }}:</label>
              <Input v-model="newCollectionName" class="w-full max-w-300" />
            </div>
            <div class="w-full max-w-300 flex flex-col justify-start mb-3">
              <label class="block text-13 mb-1">{{ $t('collections.digest') }}</label>
              <v-select v-model="digest" appendToBody :options="digestItems" :reduce="(option) => option.value" :readonly="loading" class="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { COLLECTION_DIGEST } from '@/constants';

export default {
  name: 'SelectCollectionCardModal',
  props: {
    title: String,
    selectedQuestion: Object,
    queryDataResult: Object,
    collectionId: String,
    questionId: String,
  },
  data() {
    return {
      loading: false,
      type: 'exist',
      newCollectionName: '',
      digestItems: [
        { label: this.$t(`collections.${COLLECTION_DIGEST.daily}`), value: COLLECTION_DIGEST.daily },
        { label: this.$t(`collections.${COLLECTION_DIGEST.weekly}`), value: COLLECTION_DIGEST.weekly },
        { label: this.$t(`collections.${COLLECTION_DIGEST.monthly}`), value: COLLECTION_DIGEST.monthly },
      ],
      digest: null,
    };
  },
  computed: {
    ...mapState(['collections']),
    collectionOptions() {
      return this.collections
        .filter((item) => item.id !== this.collectionId)
        .map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
    },
  },
  methods: {
    ...mapActions(['moveCollectionQuestion', 'createCollection', 'showToastMessage']),
    async onSubmit() {
      this.loading = true;
      let res;
      let newCollectionId;
      if (this.type !== 'exist') {
        res = await this.createCollection({ name: this.newCollectionName, digest: this.digest });

        if (!res) {
          this.loading = false;
          return;
        }
        newCollectionId = res.id;
      } else {
        newCollectionId = this.newCollectionName;
      }

      if (this.collectionId) {
        res = await this.moveCollectionQuestion({ collection_id: this.collectionId, new_collection_id: newCollectionId, id: this.selectedQuestion.id });

        if (res === 409) {
          this.showToastMessage({ title: this.$t('collections.card_exist_in_collection'), type: 'error' });
        } else if (res) {
          this.showToastMessage({ message: this.$t('collections.card_moved_successfully'), type: 'success' });
        } else {
          this.showToastMessage({ title: this.$t('collections.failed_to_move_card'), type: 'error' });
        }
      }

      this.$emit('close', true);
      this.loading = false;
    },
  },
  watch: {
    type() {
      this.newCollectionName = '';
    },
  },
};
</script>
