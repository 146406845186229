<template>
  <div class="flex border-b border-solid border-purple-200 overflow-hidden" :data-direction="direction">
    <div v-for="(tab, index) in items" :key="index" class="px-4 font-500 mx-3 relative cursor-pointer" :class="{ 'py-5': !dense, 'py-2': dense }" @click="onClick(tab, index)">
      <span>{{ tab }}</span>
      <transition name="slide-fade-tab">
        <div v-if="tab === modelValue" class="absolute bottom-0 left-0 h-1 right-0 bg-primary rounded-t-4 overflow-hidden"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    modelValue: String,
    dense: Boolean,
  },
  data() {
    return {
      direction: 'left',
    };
  },
  methods: {
    onClick(tab, index) {
      const oldIndex = this.items.indexOf(this.modelValue);
      if (oldIndex > index) {
        this.direction = 'left';
      } else {
        this.direction = 'right';
      }
      this.$nextTick().then(() => {
        this.$emit('update:modelValue', tab);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.slide-fade-tab-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-tab-leave-active {
  transition: all 0.1s ease-in;
}

.slide-fade-tab-enter-from {
  transform: translateY(0px);
}
.slide-fade-tab-leave-to {
  transform: translateX(100%);
  //opacity: 0;
}

.slide-fade-tab-enter-to {
  transform: translateY(0);
}
.slide-fade-tab-leave-from {
  transform: translateY(-8px);
  opacity: 1;
}

[data-direction='left'] {
  .slide-fade-tab-leave-to {
    transform: translateX(-100%);
    //opacity: 0;
  }
}
</style>
