<template>
  <div class="grid">
    <svg id="flow-line" class="col-start-1 col-end-2 row-start-1 row-end-2" width="100%" height="100%">
      <g v-for="transition in validTransitions" :key="`${transition.from}-${transition.id}-${transition.to}`" :id="`flow-line-${transition.id}`">
        <path></path>
      </g>
      <g v-for="transition in validTransitions" :key="`arrow-${transition.from}-${transition.id}-${transition.to}`" :id="`flow-arrow-${transition.id}`"></g>
      <use :xlink:href="`#flow-line-${hoverId}`" />
      <use :xlink:href="`#flow-arrow-${hoverId}`" />
    </svg>
  </div>
</template>

<script>
import PathFinder from '@/helpers/path-finder';
import { drawArrow, drawCircle, drawLine } from '@/helpers/graph';
import { getTransitions } from '@/helpers/flow';
/* global d3 */

export default {
  name: 'FlowLines',
  props: {
    steps: Array,
    stepsCols: Array,
  },
  data() {
    return {
      hoverId: '',
      vertexList: {},
    };
  },
  mounted() {
    this.drawFlow();
  },
  computed: {
    transitions() {
      const transitions = [];

      this.steps.forEach((step) => {
        if (step.name === 'Start') {
          if (step.transition) {
            transitions.push({ to: step.transition, from: '1', id: 'start-transition', payload: { type: 'step', stepId: step.id } });
          }
        } else {
          getTransitions(step.evaluate, transitions, step.id);
        }
      });
      return transitions.filter((t) => t.payload.type === 'step');
    },
    validTransitions() {
      return this.transitions.filter((t) => this.steps.some((s) => s.id === t.to));
    },
  },
  methods: {
    setVertexList() {
      this.stepsCols.forEach((columnSteps) => {
        columnSteps.forEach((step) => {
          const leftTopPoint = this.getStepCornerPoint(step.id, 'leftTop');
          const leftBottomPoint = this.getStepCornerPoint(step.id, 'leftBottom');
          const rightTopPoint = this.getStepCornerPoint(step.id, 'rightTop');
          const rightBottomPoint = this.getStepCornerPoint(step.id, 'rightBottom');

          this.setVertexItem(leftTopPoint.x, leftTopPoint.y, step.column, step.order, {});
          this.setVertexItem(leftBottomPoint.x, leftBottomPoint.y, step.column, step.order, {});
          this.setVertexItem(rightTopPoint.x, rightTopPoint.y, step.column + 1, step.order, {});
          this.setVertexItem(rightBottomPoint.x, rightBottomPoint.y, step.column + 1, step.order, {});
        });
      });
    },
    getBoundingClientRect(stepElement) {
      const wrapper = document.getElementById('flow-designer-wrapper');
      const wrapperRect = wrapper?.getBoundingClientRect();
      const sideBarOffset = Number.isInteger(wrapperRect.left) ? wrapperRect.left : 104;
      const windowScrollX = document.getElementById('flow-designer').scrollLeft - sideBarOffset;
      const windowScrollY = window.scrollY;

      const rect = stepElement.getBoundingClientRect();

      // add window scroll position to get the offset position
      const left = rect.left + windowScrollX;
      const top = rect.top + windowScrollY;
      const right = rect.right + windowScrollX;
      const bottom = rect.bottom + windowScrollY;

      // polyfill missing 'x' and 'y' rect properties not returned
      // from getBoundingClientRect() by older browsers
      const x = rect.x === undefined ? left : rect.x + windowScrollX;
      const y = rect.y === undefined ? top : rect.y + windowScrollY;

      // width and height are the same
      const { width, height } = rect;

      return { left, top, right, bottom, x, y, width, height };
    },
    getStepCornerPoint(stepId, corner) {
      const stepRect = this.getBoundingClientRect(this.getStepElement(stepId));

      const offsetX = 16;
      const offsetY = 12;

      if (corner === 'leftTop') {
        return this.normalizePoint(stepRect.left - offsetX, stepRect.top - offsetY);
      }
      if (corner === 'leftBottom') {
        return this.normalizePoint(stepRect.left - offsetX, stepRect.top + stepRect.height + offsetY);
      }
      if (corner === 'rightTop') {
        return this.normalizePoint(stepRect.left + stepRect.width + offsetX, stepRect.top - offsetY);
      }
      return this.normalizePoint(stepRect.left + stepRect.width + offsetX, stepRect.top + stepRect.height + offsetY);
    },
    setVertexItem(x, y, column, order, edges = {}) {
      if (!(`${x}_${y}` in this.vertexList)) {
        this.vertexList[`${x}_${y}`] = { column, x, y, order, edges };
      }
    },
    setNeighbours(vertexItem) {
      this.setNeighbour(vertexItem, 'left');
      this.setNeighbour(vertexItem, 'right');
      this.setNeighbour(vertexItem, 'top');
      this.setNeighbour(vertexItem, 'bottom');
    },
    setNeighbour(vertexItem, side) {
      if (side === 'left') {
        const neighbour = Object.values(this.vertexList).find((v) => v.column === vertexItem.column - 1 && vertexItem.y === v.y);
        if (neighbour) {
          this.vertexList[`${vertexItem.x}_${vertexItem.y}`].edges[`${neighbour.x}_${neighbour.y}`] = vertexItem.x - neighbour.x;
        }
      }
      if (side === 'right') {
        const neighbour = Object.values(this.vertexList).find((v) => v.column === vertexItem.column + 1 && vertexItem.y === v.y);
        if (neighbour) {
          this.vertexList[`${vertexItem.x}_${vertexItem.y}`].edges[`${neighbour.x}_${neighbour.y}`] = neighbour.x - vertexItem.x;
        }
      }
      if (side === 'top') {
        const closestY = this.getClosestNumber(
          Object.values(this.vertexList)
            .filter((v) => v.column === vertexItem.column && vertexItem.x === v.x && vertexItem.y > v.y)
            .map((v) => v.y),
          vertexItem.y,
        );
        const neighbour = Object.values(this.vertexList).find((v) => v.column === vertexItem.column && vertexItem.x === v.x && v.y === closestY);
        if (neighbour) {
          this.vertexList[`${vertexItem.x}_${vertexItem.y}`].edges[`${neighbour.x}_${neighbour.y}`] = vertexItem.y - neighbour.y;
        }
      }
      if (side === 'bottom') {
        const closestY = this.getClosestNumber(
          Object.values(this.vertexList)
            .filter((v) => v.column === vertexItem.column && vertexItem.x === v.x && vertexItem.y < v.y)
            .map((v) => v.y),
          vertexItem.y,
        );
        const neighbour = Object.values(this.vertexList).find((v) => v.column === vertexItem.column && vertexItem.x === v.x && v.y === closestY);
        if (neighbour) {
          this.vertexList[`${vertexItem.x}_${vertexItem.y}`].edges[`${neighbour.x}_${neighbour.y}`] = neighbour.y - vertexItem.y;
        }
      }
    },
    normalizePoint(x, y) {
      x = parseInt(x, 10);
      y = parseInt(y, 10);
      // Container padding top value 60
      y -= 60;
      return {
        x: x % 2 === 0 ? x : x + 1,
        y: y % 2 === 0 ? y : y + 1,
      };
    },
    getStepElement(stepId) {
      return document.getElementById(`step-${stepId}`);
    },
    drawFlow() {
      this.transitions.forEach((transition) => {
        this.vertexList = {};
        this.setVertexList();

        // Set neighbours
        Object.keys(this.vertexList).forEach((key) => {
          const vertexItem = this.vertexList[key];
          this.setNeighbours(vertexItem);
        });

        const startStep = this.steps.find((s) => s.id === transition.from);
        const endStep = this.steps.find((s) => s.id === transition.to);
        this.drawPath(startStep, endStep, transition.id);
      });
    },
    drawPath(startStep, endStep, transitionId) {
      const fromStepId = startStep?.id;
      const toStepId = endStep?.id;

      if (!fromStepId || !toStepId) {
        return;
      }

      const fromStepElmRect = this.getBoundingClientRect(this.getStepElement(fromStepId));
      const fromElmRect = this.getBoundingClientRect(document.getElementById(transitionId));

      const toElmRect = this.getBoundingClientRect(this.getStepElement(toStepId));

      const startPoint = this.normalizePoint(fromStepElmRect.left + fromStepElmRect.width, fromElmRect.top + fromElmRect.height / 2);
      const endPoint = this.normalizePoint(toElmRect.left, toElmRect.top + 17);

      // eslint-disable-next-line
      const directionX = startPoint.x < endPoint.x ? 'right' : startPoint.x > endPoint.x ? 'left' : 'same';
      // const directionY = startPoint.y < endPoint.y ? 'down' : startPoint.y > endPoint.y ? 'up' : 'same';

      let points = [];

      // start
      points.push([startPoint.x, startPoint.y]);

      let firstVertex;
      const lastVertex = this.getStepCornerPoint(toStepId, 'leftTop');

      if (directionX === 'left') {
        // go to right
        const { x, y } = startPoint;
        points.push([x + 16, y]);

        // go to bottom
        const secondVertex = this.getStepCornerPoint(fromStepId, 'rightBottom');
        points.push([secondVertex.x, secondVertex.y]);

        // go to left
        firstVertex = this.getStepCornerPoint(fromStepId, 'leftBottom');
      } else if (directionX === 'right') {
        // go to right
        // eslint-disable-next-line
        let { x, y } = startPoint;
        x += 16;
        this.setVertexItem(x, y, startStep.column + 1, startStep.order);
        this.setNeighbours(this.vertexList[`${x}_${y}`]);
        firstVertex = { x, y };
      }

      // Calculate the shortest path
      const pathFinder = new PathFinder();
      Object.keys(this.vertexList).forEach((key) => {
        pathFinder.addVertex(key, this.vertexList[key].edges);
      });
      const shortestPath = pathFinder.shortestPath(`${firstVertex.x}_${firstVertex.y}`, `${lastVertex.x}_${lastVertex.y}`);
      points = [...points, ...shortestPath.map((p) => [parseInt(p.split('_')[0], 10), parseInt(p.split('_')[1], 10)])];

      // if last vertex placing above endpoint, remove redundant point so as not to overpath
      if (points[points.length - 2][1] > points[points.length - 1][1] && points[points.length - 1][1] < endPoint.y && points[points.length - 1][0] === endPoint.x - 16) {
        points.pop();
        points.push([endPoint.x - 16, endPoint.y]);
        points.push([endPoint.x, endPoint.y]);
      }

      // Add last two points
      points.push([lastVertex.x, lastVertex.y + 30]);
      points.push([lastVertex.x + 20, lastVertex.y + 30]);

      const line = drawLine(`#flow-line g#flow-line-${transitionId}`, points);
      line
        .on('mouseover', () => {
          this.hoverId = transitionId;
          d3.select(`#flow-line g#flow-arrow-${this.hoverId}`).selectAll('path').attr('fill', 'orange');
          d3.select(`#flow-line g#flow-line-${this.hoverId}`).selectAll('circle').attr('fill', 'orange');
        })
        .on('mouseout', () => {
          d3.select(`#flow-line g#flow-arrow-${this.hoverId}`).selectAll('path').attr('fill', '#41a0fc');
          d3.select(`#flow-line g#flow-line-${this.hoverId}`).selectAll('circle').attr('fill', '#41a0fc');
          this.hoverId = null;
        });
      drawCircle(`#flow-line g#flow-line-${transitionId}`, startPoint.x + 1, startPoint.y);
      drawArrow(`#flow-line g#flow-arrow-${transitionId}`, points[points.length - 1][0] - 4, points[points.length - 1][1]);
    },
    getClosestNumber(array, goal) {
      if (!array.length) return null;
      return array.reduce((prev, curr) => {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
      });
    },
  },
  watch: {
    validTransitions: {
      handler() {
        this.$nextTick().then(() => {
          this.drawFlow();
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
svg {
}
svg path {
  fill: none;
}
svg path:hover {
  stroke: orange !important;
  z-index: 10;
}

svg use {
  pointer-events: none;
}
svg circle {
  fill: white;
  stroke: #aaa;
  cursor: move;
}
</style>
