<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('publishing.title') }}</div>
        </div>

        <div class="ml-auto flex flex-row justify-center items-center">
          <Spinner v-show="releasesLoading || publishLoading" class="w-5 mr-3" />
          <PillButton class="ml-auto" :text="polling ? $t('publishing.publish_in_progress') : $t('publishing.publish')" :disabled="polling" primary @click="showPublishModal = true" />
          <PillButton v-if="['Queued', 'Running', 'Validating Files'].includes(lastVersionStatus)" class="ml-2" :text="$t('cancel')" error @click="cancelRelease" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div class="sm:mt-2 justify-items-center">
          <CollapseCard
            v-for="(release, index) in releases"
            :key="release.Version"
            :value="false"
            :showIcon="false"
            :scroll="false"
            ref="collapseCard"
            class="shadow-card mb-4"
            @change="onCollapseCardChange(release, $event, index)"
          >
            <template #header>
              <div class="flex w-full flex-col lg:flex-row justify-between items-start lg:items-center">
                <div class="flex justify-between items-center w-full lg:w-auto">
                  <span class="font-500 whitespace-nowrap mr-2">{{ $t('publishing.version_x', { version: release.Version }) }}</span>
                  <Badge class="inline lg:hidden" :text="release.Status" v-bind="{ [getStatusBadgeType(release.Status)]: true }" />
                </div>
                <div class="flex items-center flex-wrap max-lg:w-full">
                  <span class="mr-5 flex-grow ml-auto text-12">{{ release.UserEmail }}</span>
                  <div class="flex flex-shrink-0 items-center justify-between max-sm:w-full">
                    <span class="mr-5 text-12">{{ dayjs(parseInt(release.Date)).format('MMM DD, YYYY HH:mm') }}</span>
                    <Badge class="hidden lg:inline" :text="getStatusText(release.Status)" v-bind="{ [getStatusBadgeType(release.Status)]: true }" />
                    <IconButton v-if="release.FineTuneJobId" icon="expand_more" small></IconButton>
                  </div>
                </div>
              </div>
            </template>

            <template #content>
              <div v-if="release.FineTuneJobId" class="px-4 max-h-40 overflow-auto mb-3">
                <div v-if="releaseEvents[release.FineTuneJobId] && releaseEvents[release.FineTuneJobId].loading" class="flex flex-col font-700 items-center justify-center px-20 my-3">
                  <div class="text-12">{{ $t('publishing.fetching_logs') }}</div>
                  <Icon name="loading_dots" class="w-14 mt-2 text-primary" />
                </div>
                <div v-if="releaseEvents[release.FineTuneJobId] && releaseEvents[release.FineTuneJobId].data">
                  <div v-for="item in releaseEvents[release.FineTuneJobId].data" :key="item.id" class="flex text-12 min-h-[23px] items-center">
                    <div class="w-1 h-1 bg-gray-700 rounded-full mr-3"></div>
                    <div class="mr-2">{{ dayjs(item.created_at * 1000).format('HH:mm') }}</div>
                    <div class="mr-2">{{ item.message }}</div>
                  </div>
                </div>
              </div>
            </template>
          </CollapseCard>
        </div>
      </div>
    </div>

    <Modal
      v-if="showPublishModal"
      :title="$t('confirm')"
      :primary-button="$t('publishing.publish')"
      :secondary-button="$t('cancel')"
      :primary-button-loading="publishLoading"
      :secondary-button-disabled="publishLoading"
      close-button
      @primary="onPublishClick"
      @close="showPublishModal = false"
      @secondary="showPublishModal = false"
    >
      <div class="w-500">
        <div class="flex flex-col items-start justify-start mt-5 px-10">
          <div v-html="$t('publishing.new_publish_question', { nextVersion })"></div>
          <div class="flex items-center mt-3">
            <span class="mr-2">{{ $t('publishing.train_ai_model') }}</span> <Toggle v-model="trainModel" />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import { apiGetReleaseEvents, apiGetReleases, apiPostRelease, apiPostReleaseCancel } from '@/helpers/api';

export default {
  name: 'Publish',
  compatConfig: {
    MODE: 3,
    COMPILER_V_BIND_OBJECT_ORDER: false,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      }
    });
  },
  data() {
    return {
      releases: [],
      collapseCardState: {},
      showPublishModal: false,
      trainModel: false,
      polling: false,
      pollingInterval: null,
      publishLoading: false,
      releasesLoading: false,
      releaseCancelling: false,
      releaseEvents: {},
      releaseEventPollingId: null,
      releaseEventPollingInterval: null,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'org']),
    nextVersion() {
      const versions = this.releases.map((r) => parseInt(r.Version, 10));
      return Number.isInteger(Math.max(...versions)) ? Math.max(...versions) + 1 : 1;
    },
    lastVersionStatus() {
      return this.releases?.[0]?.Status;
    },
    orgNameModelId() {
      return `${this.org}_${this.designTimeActiveDatasourceModelId}`;
    },
  },
  created() {
    this.pollingInterval = setInterval(() => {
      if (this.polling) {
        this.fetchReleases();
      }
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.pollingInterval);
    clearInterval(this.releaseEventPollingInterval);
  },
  methods: {
    ...mapActions(['showToastMessage']),
    dayjs,
    startEventPolling(id) {
      if (this.releaseEventPollingInterval) {
        clearInterval(this.releaseEventPollingInterval);
      }
      this.releaseEventPollingId = id;
      this.getReleaseEvents(id);
      this.releaseEventPollingInterval = setInterval(() => {
        this.getReleaseEvents(id);
      }, 3000);
    },
    onCollapseCardChange(release, open, index) {
      this.collapseCardState[release.Date] = !!open;
      if (!open) {
        clearInterval(this.releaseEventPollingInterval);
        this.releaseEventPollingId = null;
      }
      if (open && index === 0 && ['Queued', 'Running', 'Validating Files'].includes(this.lastVersionStatus)) {
        this.startEventPolling(release.FineTuneJobId);
      } else if (open && !this.releaseEvents?.[release.FineTuneJobId]?.data) {
        this.getReleaseEvents(release.FineTuneJobId);
      }
    },
    getStatusText(status) {
      return this.$t(`publishing.status.${status}`);
    },
    getStatusBadgeType(status) {
      if (status === 'Failed') {
        return 'error';
      }
      if (['Queued', 'Running', 'Validating Files'].includes(status)) {
        return 'info';
      }
      if (status === 'Published') {
        return 'success';
      }
      return 'warn';
    },
    onPublishClick() {
      this.publishLoading = true;
      apiPostRelease({ type: this.designTimeActiveDatasourceType, version: this.nextVersion.toString(), trainModel: this.trainModel, model_id: this.designTimeActiveDatasourceModelId })
        .then((response) => {
          this.showPublishModal = false;
          this.trainModel = false;
          this.publishLoading = false;
          if (response.status === 200) {
            this.showToastMessage({ message: this.$t('publishing.version_initiated', { version: response.data.version }), type: 'success' });
            this.fetchReleases();
          } else {
            this.showPublishModal = false;
            this.publishLoading = false;
            this.showToastMessage({ title: response.data.message || this.$t('publishing.publish_failed'), type: 'error' });
          }
        })
        .catch(() => {
          this.showPublishModal = false;
          this.trainModel = false;
          this.publishLoading = false;
          this.showToastMessage({ title: this.$t('publishing.publish_failed'), type: 'error' });
        });
    },
    async getReleaseEvents(id) {
      if (!id) {
        return;
      }
      if (!this.releaseEvents?.[id]?.data) {
        this.releaseEvents[id] = { loading: true, data: null };
      }
      try {
        const response = await apiGetReleaseEvents(id);
        if (response.status === 200) {
          this.releaseEvents[id] = { loading: false, data: response.data.data };
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('publishing.failed_to_get_logs'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('publishing.failed_to_get_logs'), type: 'error' });
      }
    },
    async fetchReleases() {
      try {
        this.releasesLoading = true;
        const response = await apiGetReleases(this.designTimeActiveDatasourceType, this.designTimeActiveDatasourceModelId);
        this.releases = response?.data || [];
        this.releasesLoading = false;
      } catch {
        this.releasesLoading = false;
      }
    },
    async cancelRelease() {
      this.releaseCancelling = true;
      try {
        const { Version, FineTuneJobId } = this.releases?.[0] || {};
        const response = await apiPostReleaseCancel({
          fine_tuning_job_id: FineTuneJobId,
          type: this.designTimeActiveDatasourceType,
          version: Version,
          model_id: this.designTimeActiveDatasourceModelId,
        });
        if (response.status === 200) {
          this.fetchReleases();
          this.showToastMessage({ message: this.$t('publishing.cancelling'), type: 'success' });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('publishing.failed_to_cancel_release'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('publishing.failed_to_cancel_release'), type: 'error' });
      }
      this.releaseCancelling = false;
    },
    clearCollapseCardStates() {
      this.releases.forEach((release) => {
        this.collapseCardState[release.Date] = false;
      });
    },
  },
  watch: {
    orgNameModelId: {
      async handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          await this.fetchReleases();
          this.clearCollapseCardStates();
        }
      },
      immediate: true,
    },
    lastVersionStatus: {
      handler() {
        const values = ['Published', 'Failed', 'Cancelled'];

        if (this.lastVersionStatus && !values.includes(this.lastVersionStatus)) {
          this.polling = true;
        } else {
          this.polling = false;
          clearInterval(this.releaseEventPollingInterval);
        }

        if (['Queued', 'Running', 'Validating Files'].includes(this.lastVersionStatus) && this.releases?.[0]?.FineTuneJobId) {
          if (this.releases?.[0].Date && this.collapseCardState[this.releases[0].Date]) {
            this.startEventPolling(this.releases[0].FineTuneJobId);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
