<template>
  <div class="pr-2 md:pr-10 pl-2">
    <div class="flex items-center pb-4">
      <div class="text-14 mr-auto font-700">{{ total }} Active users</div>
      <PillButton :text="$t('users.add_user')" icon="plus" primary @click="onAddUserClick" />
    </div>
    <div>
      <div v-if="loading">
        <div class="flex-grow flex items-center justify-center h-full">
          <Spinner class="w-8" />
        </div>
      </div>
      <div v-else-if="!users.length" class="w-full text-center text-13 text-gray-600">There is no record found!</div>
      <div v-else class="justify-items-center">
        <ListCard v-for="user in users" :key="user.email" class="mb-4 !p-1 sm:p-3 bg-white">
          <div class="cursor-pointer w-6 h-6 flex-shrink-0 flex-grow-0 mr-3">
            <Avatar size="6" :picture="user.photoUrl" />
          </div>
          <div class="overflow-hidden">
            <div class="truncate">
              <span class="font-600">{{ user.displayName }}</span> {{ user.email }}
            </div>
            <div class="text-12">{{ $t('users.last_login') }} {{ user.last_login ? dayjs(parseInt(user.last_login)).format('MMM DD, YYYY HH:mm') : $t('n_a') }}</div>
          </div>
          <div>
            <Badge v-if="user.disabled" warn text="Disabled" class="ml-1 sm:ml-3" />
          </div>
          <div class="ml-auto flex">
            <IconButton :title="$t('edit')" class="text-primary" icon="edit" @click="onEditClick(user)" />
            <!--            <IconButton v-if="user.disabled" :title="$t('enable')" class="text-gray-500" icon="toggle_off" @click="onDisableClick(user)" />-->
            <!--            <IconButton v-else class="text-primary" :title="$t('disable')" icon="toggle_on" @click="onDisableClick(user)" />-->
            <IconButton class="text-primary" icon="delete" :title="$t('delete')" @click="onDeleteClick(user)" />
          </div>
        </ListCard>
      </div>
      <div v-if="total > 10" class="flex justify-center items-center mt-8">
        <IconButton icon="chevron_left" @click="prev" />
        <span class="px-3">{{ pageNumber + 1 }}</span>
        <IconButton icon="chevron_right" @click="next" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { apiDelDeleteUser, apiGetUserCount, apiPostDisableUser } from '@/helpers/api';
import AddUserModal from '@/components/admin/AddUserModal.vue';
import DeleteModal from '@/components/shared-modals/DeleteModal.vue';
import EditUserModal from '@/components/admin/EditUserModal.vue';

export default {
  name: 'Users',
  data() {
    return {
      pageNumber: 0,
      total: 0,
      loading: false,
    };
  },
  created() {
    apiGetUserCount().then((response) => {
      if (response.status === 200) {
        this.total = response?.data?.count || 0;
      }
    });
    this.getUsers({ page_number: this.pageNumber });
  },
  beforeUnmount() {
    this.SET_USERS([]);
  },
  computed: {
    ...mapState(['users', 'org']),
  },
  methods: {
    ...mapActions(['fetchUsers', 'showToastMessage']),
    ...mapMutations(['SET_USERS']),
    dayjs,
    onEditClick(user) {
      this.$showModal(EditUserModal, { user });
    },
    onDeleteClick(user) {
      this.$showModal(DeleteModal, {
        title: this.$t('projects.iam.confirm_remove_user'),
        subtitle: this.$t('projects.iam.confirm_remove_user_subtitle'),
        onConfirm: () => this.onDeleteConfirm(user),
      });
    },
    async onDeleteConfirm(user) {
      try {
        const response = await apiDelDeleteUser({ tenant_id: user.tenant_id });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('projects.iam.user_deleted_successfully'), type: 'success' });
          this.getUsers({ page_number: this.pageNumber });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.iam.failed_to_delete_user'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('projects.iam.failed_to_delete_user'), type: 'error' });
      }
    },
    onDisableClick(user) {
      this.$showModal(DeleteModal, {
        title: !user.disabled ? this.$t('projects.iam.confirm_disable_user') : this.$t('projects.iam.confirm_enable_user'),
        type: user.disabled ? 'primary' : 'error',
        subtitle: !user.disabled ? this.$t('projects.iam.confirm_disable_user_subtitle') : this.$t('projects.iam.confirm_enable_user_subtitle'),
        onConfirm: () => this.onDisableConfirm(user),
      });
    },
    async onDisableConfirm(user) {
      try {
        const response = await apiPostDisableUser({ tenant_id: user.tenant_id, disabled: !user.disabled });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('projects.iam.user_disabled_successfully'), type: 'success' });
          this.getUsers({ page_number: this.pageNumber });
        } else {
          this.showToastMessage({ title: response?.data?.message || this.$t('projects.iam.failed_to_disable_user'), type: 'error' });
        }
      } catch {
        this.showToastMessage({ title: this.$t('projects.iam.failed_to_disable_user'), type: 'error' });
      }
    },
    onAddUserClick() {
      this.$showModal(AddUserModal, null, {
        add: () => {
          this.getUsers({ page_number: this.pageNumber });
        },
      });
    },
    async next() {
      const response = await this.getUsers({ page_number: this.pageNumber + 1 });
      if (Array.isArray(response) && response.length) {
        this.pageNumber++;
      }
    },
    async prev() {
      if (this.pageNumber === 0) {
        return;
      }
      const response = await this.getUsers({ page_number: this.pageNumber - 1 });
      if (response.length) {
        this.pageNumber--;
      }
    },
    async getUsers(params) {
      this.loading = true;
      const response = await this.fetchUsers(params);
      this.loading = false;
      return response;
    },
  },
  watch: {
    org() {
      this.pageNumber = 0;
      apiGetUserCount().then((response) => {
        if (response.status === 200) {
          this.total = response?.data?.count || 0;
        }
      });
      this.getUsers({ page_number: this.pageNumber });
    },
  },
};
</script>
