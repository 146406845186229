<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.variables.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <ListCard v-for="variableName in variables" :key="variableName" class="flex-row mt-3">
          <div class="text-14 md:text-16 font-600 truncate">{{ variableName }}</div>
          <div class="flex items-center flex-1">
            <span v-if="variableDetails[variableName] && variableDetails[variableName].dataType" class="ml-2 bg-gray-200 rounded-6 px-1 text-12">{{ variableDetails[variableName].dataType }}</span>
            <span v-if="variableDetails[variableName] && variableDetails[variableName].variableType === VARIABLE_TYPE.SYSTEM" class="ml-2 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <span v-if="variableDetails[variableName] && variableDetails[variableName].readonly" class="ml-2 bg-red-200 rounded-6 px-1 text-12">readonly</span>

            <div v-if="variableDetails[variableName] && variableDetails[variableName].variableType !== VARIABLE_TYPE.SYSTEM" class="flex flex-row justify-end items-center ml-auto">
              <IconButton icon="edit" class="sm:mr-1 w-auto text-primary" @click="onEditClicked(variableName)" />
              <IconButton icon="delete" class="text-primary" @click="onDeleteClicked(variableName)" :text="$t('delete')" />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { VARIABLE_TYPE } from '@/constants';
import CreateProjectVariable from '@/components/no-code/CreateProjectVariable';
import DeleteModal from '@/components/shared-modals/DeleteModal';

export default {
  name: 'NoCodeVariables',
  data() {
    return {
      VARIABLE_TYPE,
    };
  },
  computed: {
    ...mapState(['user', 'projectData', 'activeDatasourceType', 'data_sources']),
    variables() {
      if (this.$route.params?.id && Object.keys(this.projectData).length) {
        return Object.keys(this.projectData?.[this.$route.params.id]?.variables).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) || [];
      }
      return [];
    },
    variableDetails() {
      if (this.$route.params?.id) {
        return this.projectData?.[this.$route.params.id]?.variables || {};
      }
      return {};
    },
    projectId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.fetchProjectData(this.projectId);
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectData', 'deleteProjectVariable']),
    onCreateClicked() {
      const modal = this.$showModal(
        CreateProjectVariable,
        {
          projectId: this.projectId,
        },
        {
          save: () => {
            this.onVariableSaved(modal);
          },
        },
      );
    },
    onEditClicked(variableName) {
      this.$showModal(
        CreateProjectVariable,
        {
          projectId: this.projectId,
          variableName,
        },
        {
          save: this.onVariableSaved,
        },
      );
    },
    onVariableSaved() {
      this.fetchProjectData(this.projectId);
    },
    onDeleteClicked(variableName) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.remove_variable', { variableName }),
        info: null,
        onConfirm: async () => {
          await this.deleteProjectVariable({ name: variableName, project_id: this.projectId });
          this.showToastMessage({ message: this.$t('projects.variable_removed_successfully'), type: 'success' });
        },
      });
    },
  },
};
</script>
