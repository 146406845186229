/**
 * Basic priority queue implementation. If a better priority queue is wanted/needed,
 * this code works with the implementation in google's closure library (https://code.google.com/p/closure-library/).
 * Use goog.require('goog.structs.PriorityQueue'); and new goog.structs.PriorityQueue()
 */
function PriorityQueue() {
  this.nodes = [];

  this.enqueue = (priority, key) => {
    this.nodes.push({ key, priority });
    this.sort();
  };
  this.dequeue = () => {
    return this.nodes.shift().key;
  };
  this.sort = () => {
    this.nodes.sort((a, b) => {
      return a.priority - b.priority;
    });
  };
  this.isEmpty = () => {
    return !this.nodes.length;
  };
}

/**
 * Pathfinding starts here
 */
function PathFinder() {
  const INFINITY = 1 / 0;
  this.vertices = {};

  this.addVertex = (name, edges) => {
    this.vertices[name] = edges;
  };

  this.shortestPath = (start, finish) => {
    const nodes = new PriorityQueue();
    const distances = {};
    const previous = {};
    let path = [];
    let smallest;
    let vertex;
    let neighbor;
    let alt;

    // eslint-disable-next-line
    for (vertex in this.vertices) {
      if (vertex === start) {
        distances[vertex] = 0;
        nodes.enqueue(0, vertex);
      } else {
        distances[vertex] = INFINITY;
        nodes.enqueue(INFINITY, vertex);
      }

      previous[vertex] = null;
    }

    while (!nodes.isEmpty()) {
      smallest = nodes.dequeue();

      if (smallest === finish) {
        path = [];

        while (previous[smallest]) {
          path.push(smallest);
          smallest = previous[smallest];
        }

        break;
      }

      if (!smallest || distances[smallest] === INFINITY) {
        // eslint-disable-next-line
        continue;
      }

      // eslint-disable-next-line
      for (neighbor in this.vertices[smallest]) {
        alt = distances[smallest] + this.vertices[smallest][neighbor];

        if (alt < distances[neighbor]) {
          distances[neighbor] = alt;
          previous[neighbor] = smallest;

          nodes.enqueue(alt, neighbor);
        }
      }
    }

    return path.concat([start]).reverse();
  };
}

export default PathFinder;
