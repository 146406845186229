<template>
  <DefaultLayout>
    <div class="top-bar fixed z-10 top-0 right-0 transition-all ease-in duration-150 left-0 lg:left-top-bar" style="background-color: #f5f3fe">
      <div class="w-full pt-3 pb-3 absolute flex items-center z-0 px-6 min-h-16" style="background-color: #f6f4fe">
        <div class="w-full text-17 flex justify-between items-center font-600 text-primary">
          <span class="text-20 mt-1"> {{ $t('navigation.admin') }} </span>
        </div>
        <div class="flex ml-auto items-center">
          <OrganisationSelect class="org-select mr-2 sm:mr-4" outlined />
          <Icon name="faq" class="w-5 h-5 cursor-pointer mr-4" @click="SET_SHOW_SIDE_PANEL(true)" />
          <Icon name="notification" class="w-6 h-6 cursor-pointer" @click="showNotificationPopup = true" v-outside-click="closeNotificationPopup" />

          <Notifications v-if="showNotificationPopup" />
        </div>
      </div>
    </div>
    <div class="pt-10 w-full h-full flex flex-col overflow-hidden">
      <div class="mt-0 flex-shrink-0 w-full flex items-end justify-between px-5 h-12">
        <div class="text-17 flex justify-center items-center font-600 text-primary">
          <span class="text-16">{{ $t('navigation.data_source_management') }}</span>
        </div>
      </div>
      <div v-if="dataSources.length" class="overflow-auto">
        <div class="w-full sm:p-5 grid gap-4 grid-cols-collection-mobile sm:grid-cols-collection">
          <ListCard class="h-20 py-2 bg-white rounded-20 cursor-pointer" v-for="dataSource in dataSources" :key="dataSource.name" @click="goToDataSource(dataSource)">
            <div class="flex w-full h-full items-center">
              <div class="w-11 h-11">
                <img class="h-11" :src="dataSource.icon" />
              </div>
              <div>
                <div class="ml-4 font-500">{{ dataSource.name }}</div>
              </div>
            </div>
          </ListCard>
        </div>
      </div>
      <div class="mt-0 flex-shrink-0 w-full flex items-end justify-between px-5 h-8">
        <div class="text-17 flex justify-center items-center font-600 text-primary">
          <span class="text-16">{{ $t('navigation.user_management') }}</span>
        </div>
      </div>
      <div class="overflow-auto">
        <div class="w-full sm:p-5 grid gap-4 grid-cols-collection-mobile sm:grid-cols-collection">
          <ListCard class="h-20 py-2 bg-white rounded-20 cursor-pointer" @click="$router.push('/iam/user-management')">
            <div class="flex w-full h-full items-center">
              <div class="w-11 h-11 rounded-full border flex justify-center items-center">
                <Icon class="w-8 h-8 text-purple-600" name="manage_accounts" />
              </div>
              <div>
                <div class="ml-4 font-500">{{ $t('navigation.iam_dashboard') }}</div>
              </div>
            </div>
          </ListCard>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import OrganisationSelect from '@/components/datasource/OrganisationSelect.vue';
import Notifications from '@/components/ui/Notifications.vue';

export default {
  name: 'AdminDashboard',
  components: { Notifications, OrganisationSelect },
  data() {
    return {
      showNotificationPopup: false,
    };
  },
  computed: {
    ...mapState(['availableDataSources']),
    dataSourceLength() {
      return Object.keys(this.availableDataSources).length;
    },
    dataSources() {
      return Object.keys(this.availableDataSources).map((ds) => {
        return {
          key: ds,
          icon: this.availableDataSources[ds].logo,
          name: this.availableDataSources[ds].name,
        };
      });
    },
  },
  methods: {
    ...mapMutations(['SET_DESIGN_TIME_ACTIVE_DATASOURCE']),
    ...mapMutations(['SET_SHOW_SIDE_PANEL']),
    goToDataSource(dataSource) {
      this.SET_DESIGN_TIME_ACTIVE_DATASOURCE(dataSource.key);
      this.$router.push({ name: 'flows-admin', params: { type: dataSource.key } });
    },
    closeNotificationPopup() {
      this.showNotificationPopup = false;
    },
  },
};
</script>
