<template>
  <FullScreenModal container-class="w-full" :close-button="false">
    <div class="h-full flex flex-col">
      <div class="border-b border-solid border-gray-200 shadow-sm py-2 min-h-14 justify-center flex">
        <div class="relative flex items-center justify-center w-full">
          <div class="max-sm:hidden pl-6 text-18 font-700">{{ $t('flow.step_editor') }}</div>
          <div class="flex flex-grow justify-end pr-6 items-center">
            <div class="flex justify-center items-center mr-4">
              <template v-if="!editStepName">
                <span class="font-600 mr-1">{{ $t('flow.step_name') }}: </span>
                <span>{{ step.name }}</span>
                <Icon name="edit" class="w-4 h-4 ml-1 cursor-pointer" @click="editStepName = !editStepName" />
              </template>
              <template v-if="editStepName">
                <Input v-model="step.name" class="min-w-300" />
                <Icon name="check" class="w-4 h-4 ml-1 cursor-pointer" @click="editStepName = !editStepName" />
              </template>
            </div>
            <PillButton :disabled="!activeDatasourceAccountId" :text="$t('flow.try_it')" @click="tryIt" />
          </div>
        </div>
      </div>

      <div class="flex flex-col relative overflow-hidden flex-grow">
        <div class="mb-12 flex-grow overflow-auto border-b border-gray-300 border-solid">
          <StepEvaluate ref="step-evaluate" :hidden-evaluate-types="hiddenEvaluateTypes" :evaluateData="step.evaluate" @update="onStepEvaluateUpdated" />
        </div>
        <div class="absolute left-0 bottom-0 px-7 py-2">
          <PillButton :text="$t('flow.delete_step')" error @click="deleteStep" />
        </div>
        <div class="absolute right-0 bottom-0 px-7 py-2">
          <PillButton :text="$t('cancel')" class="mr-3" @click="cancel" />
          <PillButton :text="$t('save')" primary @click="save" />
        </div>
      </div>
    </div>
  </FullScreenModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import StepEvaluate from '@/components/flow/right-side-config-items/StepEvaluate';
import DeleteModal from '@/components/shared-modals/DeleteModal';

export default {
  name: 'StepEditor',
  components: { StepEvaluate },
  props: {
    stepData: {
      type: Object,
    },
    variableSelectorType: {
      type: String,
      default: 'conversation',
    },
  },
  data() {
    return {
      step: null,
      transitionValue: null,
      editStepName: false,
    };
  },
  provide() {
    return {
      variableSelectorType: this.variableSelectorType,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'selectedIntentDefinition', 'activeDatasourceAccountId']),
    hiddenEvaluateTypes() {
      if (this.variableSelectorType === 'no-code') {
        return ['prompt'];
      }
      return ['email'];
    },
    stepId() {
      return this.stepData?.id;
    },
  },
  methods: {
    ...mapMutations(['SET_SELECTED_INTENT_DEFINITION_STEP_ID']),
    tryIt() {
      this.$refs['step-evaluate'].tryIt();
    },
    onStepEvaluateUpdated(payload) {
      this.step.evaluate = payload;
    },
    deleteStep() {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('flow.confirm_remove_step_message'),
        onConfirm: () => {
          this.$emit('delete', this.step.id);
        },
      });
    },
    save() {
      this.$emit('save', this.step);

      // const valid = activeComponentRef?.validate();
      // console.log(valid);
    },
    cancel() {
      this.SET_SELECTED_INTENT_DEFINITION_STEP_ID(null);
    },
  },
  watch: {
    stepId: {
      handler() {
        this.step = JSON.parse(JSON.stringify(this.stepData));
        this.transitionValue = this.selectedIntentDefinition?.transition || '';
      },
      immediate: true,
    },
  },
};
</script>
