<template>
  <Modal
    container-class="!w-[95vw]"
    fullbelowsm
    :title="!editData ? $t('projects.email_template.create') : $t('projects.email_template.edit')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :secondary-button-disabled="loading"
    :primary-button-loading="loading"
    :primary-button-disabled="!name || !html || loading"
    @primary="onSaveClick"
    @secondary="onCancelClick"
  >
    <div class="w-full max-w-full min-h-full md:min-h-600 flex flex-col">
      <div class="w-full min-h-21 flex flex-col justify-start mt-3 py-3 px-10 flex-shrink-0">
        <label class="block text-13 mb-1">{{ $t('projects.email_template.name') }}</label>
        <Input ref="name" :readonly="loading" :disabled="!!editData" v-model="name" class="w-full" />
        <p v-if="nameError" class="text-red-600 text-13 text-left pt-1 italic">{{ nameError }}</p>
      </div>

      <div class="w-full min-h-21 flex flex-col justify-start mt-3 py-3 px-10 flex-shrink-0 flex-grow">
        <label class="block text-13 mb-1">Content</label>
        <div class="w-full relative border flex-grow flex flex-col">
          <div class="flex h-5">
            <div class="text-13 cursor-pointer w-auto px-3 text-center border-r" :class="{ 'border-b': tab !== 'editor' }" @click="tab = 'editor'">{{ $t('projects.email_template.html') }}</div>
            <div class="text-13 cursor-pointer w-auto px-3 text-center border-r" :class="{ 'border-b': tab !== 'preview' }" @click="tab = 'preview'">
              {{ $t('projects.email_template.html_preview') }}
            </div>
            <div class="text-13 cursor-pointer w-auto px-3 text-center border-r" :class="{ 'border-b': tab !== 'text' }" @click="tab = 'text'">{{ $t('projects.email_template.text') }}</div>
            <div class="flex-grow border-b"></div>
            <div v-show="tab === 'editor'" class="text-13 cursor-pointer border-b border-l px-2" @click="formatEditor">{{ $t('format_code') }}</div>
          </div>
          <div class="flex-grow grid w-full">
            <div v-show="tab === 'editor'">
              <MonacoEditor v-model:value="html" :options="options" width="100%" height="100%" class="flex-grow h-full" language="html" @mount="monacoMounted"></MonacoEditor>
            </div>

            <div class="w-full h-full overflow-auto px-1" v-show="tab === 'preview'">
              <iframe width="100%" height="100%" class="flex-grow" :srcdoc="htmlPreviewContent"></iframe>
            </div>
            <div v-show="tab === 'text'" class="p-1 h-full">
              <Textarea :readonly="loading" v-model="text" class="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import MonacoEditor from '@guolao/vue-monaco-editor';
import { mapActions } from 'vuex';
import { apiPostEmailTemplate, apiPutEmailTemplate } from '@/helpers/api';
import { MONACO_EDITOR_DEFAULTS } from '@/constants';

export default {
  name: 'CreateEmailTemplateModal',
  components: { MonacoEditor },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    editData: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      name: '',
      nameError: '',
      text: '',
      textError: '',
      html: '',
      htmlError: '',
      tab: 'editor',
      editor: null,
      options: {
        formatOnType: true,
        formatOnPaste: true,
      },
      resultEditorOptions: MONACO_EDITOR_DEFAULTS,
    };
  },
  created() {
    if (this.editData) {
      const { name, text, html } = this.editData;
      this.name = name;
      this.text = text;
      this.html = html;
    }
  },
  computed: {
    htmlPreviewContent() {
      return this.html;
    },
  },
  mounted() {
    this.$refs.name.$el.focus();
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectData']),
    formatEditor() {
      this.editor.getAction('editor.action.formatDocument').run();
    },
    monacoMounted(editor, monaco) {
      // validation settings
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: false,
      });
      // compiler options
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES6,
        allowNonTsExtensions: true,
      });

      this.editor = editor;
    },
    async onSaveClick() {
      this.loading = true;
      if (this.editData) {
        try {
          const response = await apiPutEmailTemplate({ project_id: this.projectId, name: this.name, text: this.text, html: this.html });
          if (response.status === 200 && response.data) {
            this.fetchProjectData(this.projectId);
            this.showToastMessage({ message: this.$t('projects.email_template.updated_successfully'), type: 'success' });
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: this.$t('projects.email_template.failed_to_update'), type: 'error' });
        } catch (e) {
          this.showToastMessage({ title: this.$t('projects.email_template.failed_to_update'), type: 'error' });
        }
      } else {
        try {
          const response = await apiPostEmailTemplate({ project_id: this.projectId, name: this.name, text: this.text, html: this.html });
          if (response.status === 200 && response.data) {
            this.fetchProjectData(this.projectId);
            this.showToastMessage({ message: this.$t('projects.email_template.added_successfully'), type: 'success' });
            this.$emit('close');
            return;
          }
          this.showToastMessage({ title: this.$t('projects.email_template.failed_to_add'), type: 'error' });
        } catch (e) {
          this.showToastMessage({ title: this.$t('projects.email_template.failed_to_add'), type: 'error' });
        }
      }
      this.loading = false;
      this.$emit('close');
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
