<template>
  <div id="app" class="h-full min-w-320">
    <router-view />
    <Toast :notificationState="toastNotifications" />
    <ModalsContainer />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ModalsContainer } from 'vue-final-modal';
import text2speech from '@/helpers/text2speech';
import idb from '@/helpers/idb';
import Toast from '@/components/ui/Toast';

export default {
  name: 'App',
  components: { ModalsContainer, Toast },
  computed: {
    ...mapState(['toastNotifications', 'activeDatasourceType', 'org', 'data_sources', 'activeDatasourceModelId']),
    orgTypeModelId() {
      return `${this.org}-${this.activeDatasourceType}-${this.activeDatasourceModelId}`;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'bootstrap']),
    ...mapMutations(['SET_ACTIVE_DATASOURCE_ACCOUNT_ID', 'SET_ACTIVE_DATASOURCE_DATASOURCE_ID', 'SET_ACTIVE_DATASOURCE']),
    setVisualViewportHeight() {
      const height = window.visualViewport?.height;
      const r = document.querySelector(':root');
      r.style.setProperty('--vvh', `${height}px`);
    },
  },
  created() {
    this.bootstrap();
    text2speech.stopSpeaking();
  },
  mounted() {
    if ('visualViewport' in window) {
      this.setVisualViewportHeight();
      window.visualViewport.addEventListener('resize', () => {
        this.setVisualViewportHeight();
      });
    }
  },
  watch: {
    org: {
      handler() {
        if (this.org) {
          const datasourceModels = [];
          Object.values(this.data_sources).forEach((v) => {
            Object.values(v).forEach((a) => {
              Object.values(a.accounts)
                .filter((acc) => acc.org === this.org)
                .forEach((acc) => {
                  if (acc?.properties?.MODEL_ID) {
                    datasourceModels.push(`${acc.type}-${acc.properties.MODEL_ID}`);
                  }
                });
            });
          });
          idb.createDatabases(this.org, datasourceModels);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.grecaptcha-badge {
  display: none !important;
}

.monaco-editor .rename-box {
  display: none;
}
</style>
