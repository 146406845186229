<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.conversations') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <v-select
            v-model="flowTypeFilter"
            class="w-[170px] mr-3"
            rounded
            elevated
            :placeholder="$t('flow.type')"
            dense
            alternativeIndicator
            :searchable="false"
            :options="[
              { label: $t('conversation.conversation'), value: 'conversation' },
              { label: $t('collection'), value: 'collection' },
            ]"
            :reduce="(option) => option.value"
          ></v-select>
          <div class="relative">
            <PillButton icon="plus" :text="$t('add_new')" primary @click="onAddClick" />
          </div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <ListCard v-for="(flowItem, index) in getFlows" :key="index" class="flex-row mt-3">
          <div class="text-14 font-500 truncate">{{ flowItem.name }}</div>
          <div class="flex items-center flex-1">
            <span class="ml-2 rounded-6 px-1 text-12" :class="{ 'bg-orange-200': flowItem.flowType === 'collection', 'bg-purple-200': flowItem.flowType !== 'collection' }">
              {{ flowItem.flowType === 'conversation' ? $t('conversation.conversation') : $t('collection') }}
            </span>
            <span v-if="isSystem(flowItem)" class="ml-2 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <div class="flex flex-row justify-end items-center ml-auto">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(flowItem)" />
              <IconButton v-if="!isSystem(flowItem)" class="text-primary" icon="delete" @click="onDeleteClicked(flowItem)" />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
    <Modal v-if="loadingIntentsModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
    <Modal v-if="showDeletingModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('deleting') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiDeleteConversationDefinition } from '@/helpers/api';
import AddEditConversationModal from '@/components/intent-editor/AddEditConversation';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import debounce from '@/helpers/debounce';

export default {
  name: 'Flows',
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      } else if (!vm.$store.state.modelsFetched) {
        await vm.$store.dispatch('fetchModels', { type: to.params.type || vm.$store.state.designTimeActiveDatasourceType });
      } else if (from.fullPath !== '/') {
        vm.fetchData();
      }
    });
  },
  data() {
    return {
      flows: [],
      showDeletingModal: false,
      loadingIntentsModal: false,
      flowTypeFilter: null,
      showAddDropdown: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'org']),
    getFlows() {
      if (this.flowTypeFilter) {
        // eslint-disable-next-line
        return this.flows.filter((f) => f.flowType === this.flowTypeFilter).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }
      // eslint-disable-next-line
      return this.flows.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
    orgNameModelId() {
      return `${this.org}_${this.designTimeActiveDatasourceModelId}`;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchDesignTimeData', 'deleteCollectionQuestion']),
    isSystem(flowItem) {
      if (flowItem.flowType === 'conversation' && flowItem.conversationType === 'system') {
        return true;
      }
      if (flowItem.flowType === 'collection' && flowItem.questionType === 'system') {
        return true;
      }
      return false;
    },
    onAddClick() {
      this.$showModal(
        AddEditConversationModal,
        {},
        {
          saveintent: this.onIntentSaved,
        },
      );
    },
    onEditClicked(flowItem) {
      if (flowItem.flowType === 'conversation') {
        this.$router.push({ name: 'admin-conversation', params: { intent: flowItem.name, type: this.$route.params.type } });
      } else if (flowItem.flowType === 'collection') {
        this.$router.push({ name: 'admin-collection', params: { intent: flowItem.name, type: this.$route.params.type } });
      }
    },
    onIntentSaved(response) {
      if (response) {
        if (!this.flows.some((flow) => flow.name === response.name && flow.flowType === response.flowType)) {
          this.flows.push(response);
        }
      }
    },
    onDeleteClicked(flowItem) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('conversations.confirm_remove_conversation_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: () => {
          this.onDeleteIntentConfirmClicked(flowItem);
        },
      });
    },
    async onDeleteIntentConfirmClicked(flowItem) {
      this.showDeletingModal = true;
      let response;
      if (flowItem.flowType === 'conversation') {
        response = await apiDeleteConversationDefinition({ name: flowItem.name, type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
      }
      if (flowItem.flowType === 'collection') {
        response = await this.deleteCollectionQuestion({ name: flowItem.name, type: this.designTimeActiveDatasourceType });
      }
      if (response.status === 200) {
        this.showToastMessage({ message: this.$t('conversations.removed_successfully'), type: 'success' });
        const index = this.flows.findIndex((i) => i.name === flowItem.name && i.flowType === flowItem.flowType);
        this.flows.splice(index, 1);
        this.getFlowList();
      } else {
        this.showToastMessage({ title: this.$t('conversations.failed_to_remove'), type: 'error' });
      }
      this.showDeletingModal = false;
    },
    async getFlowList() {
      const response = await this.fetchDesignTimeData();
      if (response) {
        const conversations = Object.values(response.conversations);
        conversations.map((conversation) => {
          conversation.flowType = 'conversation';
          return conversation;
        });
        const collections = response.questions;
        collections.map((collection) => {
          collection.flowType = 'collection';
          return collection;
        });
        this.flows = [...conversations, ...collections];
      }
    },
    // eslint-disable-next-line
    fetchData: debounce(async function () {
      this.loadingIntentsModal = true;
      await this.getFlowList();
      this.loadingIntentsModal = false;
    }, 300),
  },
  watch: {
    orgNameModelId: {
      handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
};
</script>
